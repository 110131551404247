import React, { useEffect, useRef, useState } from "react";
import GameStatsHeader from "../Shared/GameStatsHeader";
import { Card } from "react-bootstrap";
import GearShopComponent from "./GearShopComponent";

const GearComponent = () => {
  const [customHeight, setCustomHeight] = useState(0);

  useEffect(() => {
    const headerHeight =
      document.getElementsByClassName("game-header").offsetHeight;
    const containerHeight =
      document.getElementsByClassName("gear-container")[0].parentElement
        .offsetHeight;
    setCustomHeight(containerHeight - headerHeight);
  }, []); // This effect runs only once after the initial render

  return (
    <div className="d-flex flex-column w-100 h-100 bg-main-image">
      <GameStatsHeader />
      <div
        className="gear-container d-flex flex-column flex-grow-1 text-light m-3 overflow-y-auto"
        style={{ height: `${customHeight}px` }}
      >
        <GearShopComponent />
      </div>
    </div>
  );
};

export default GearComponent;
