import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import InningsComponent from "./InningsComponent";
import { useMatch } from "../Contexts/MatchContext";
import MatchOverviewComponent from "./MatchOverviewComponent.js";
import FallOfWicketModal from "../Modals/FallOfWicketModal.js";
import useGameImages from "../Hooks/useGameImages.js";

const ScoreboardComponent = () => {
  const { state, dispatch, pauseMatch } = useMatch();
  const image = useGameImages();
  const [selectedInnings, setSelectedInnings] = useState("innings-1");
  const [showFallOfWicketModal, setShowFallOfWicketModal] = useState();
  const { fallOfWicket } = state.matchInfo;
  const { showFallOfWicketDialog } = state.matchSettings;

  const handleCloseFallOfWicketModal = () => {
    setShowFallOfWicketModal(false);
  };
  const getInningsNumberTitle = (inningsNumber) => {
    switch (inningsNumber) {
      case 1:
        return "1st Innings";
      case 2:
        return "2nd Innings";
      case 3:
        return "3rd Innings";
      default:
        return "4th Innings";
    }
  };

  useEffect(() => {
    // Check if the match state is "READY_TO_START" and switch to the current innings tab
    if (state.matchInfo.matchState === "READY_TO_START") {
      // Construct the tab key based on currentInnings (assume it is zero-based and needs to match "innings-1" format)
      const tabKey = `innings-${state.matchInfo.currentInnings + 1}`;
      setSelectedInnings(tabKey);
    }
  }, [state.matchInfo.currentInnings, state.matchInfo.matchState]);

  useEffect(() => {
    if (fallOfWicket && showFallOfWicketDialog.isChecked) {
      setShowFallOfWicketModal(true);
      pauseMatch();
    }

    dispatch({ type: "RESET_WICKET_ALERT" });
  }, [fallOfWicket, dispatch, showFallOfWicketDialog, pauseMatch]);

  const renderTabs = () => {
    let tabs = [];
    for (
      let inningsNumber = 1;
      inningsNumber <= state.matchInfo.inningsInMatch;
      inningsNumber++
    ) {
      // If the initial batting team is the user then they are 1, otherwise, they are 2

      const isOddInnings = inningsNumber % 2 === 1;
      const battingTeam =
        (isOddInnings && state.matchInfo.initialBattingTeam === "teamA") ||
        (!isOddInnings && state.matchInfo.initialBattingTeam === "teamB")
          ? "teamA"
          : "teamB";
      const bowlingTeam = battingTeam === "teamA" ? "teamB" : "teamA";

      const currentScore =
        state.matchInfo.teams[battingTeam].innings[
          state.matchInfo.teamInningsIndex
        ]?.score;
      const currentWickets =
        state.matchInfo.teams[battingTeam].innings[
          state.matchInfo.teamInningsIndex
        ]?.wickets;

      tabs.push(
        <Tab
          key={`innings-${inningsNumber}`}
          eventKey={`innings-${inningsNumber}`}
          title={`${getInningsNumberTitle(
            inningsNumber
          )} - Score: ${currentScore}/${currentWickets}`}
        >
          <InningsComponent
            inningsNumber={inningsNumber - 1}
            battingTeam={state.matchInfo.teams[battingTeam].players}
            bowlingTeam={state.matchInfo.teams[bowlingTeam].players}
            inningsData={
              state.matchInfo.teams[battingTeam].innings[
                state.matchInfo.teamInningsIndex
              ]
            }
          />
        </Tab>
      );
    }
    return tabs;
  };

  return (
    <>
      <Card
        className="mb-3 bg-cream text-cricket-green match-center-card"
        style={{
          backgroundImage: `url(${image["stadium_image"]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="match-center-overlay ">
          <MatchOverviewComponent />
        </div>
      </Card>

      <Tabs
        activeKey={selectedInnings}
        id="scorecard-tabs"
        className="mb-3 custom-scorecard-tabs"
        onSelect={(k) => setSelectedInnings(k)}
        fill
      >
        {renderTabs()}
      </Tabs>

      <FallOfWicketModal
        show={showFallOfWicketModal}
        handleClose={handleCloseFallOfWicketModal}
      />
    </>
  );
};

export default ScoreboardComponent;
