import React, { useRef } from "react";
import { useGame } from "../../../Contexts/GameContext.js";
import { Button } from "react-bootstrap";
import GameSettingsMenu from "./GameSettingsMenu.js";

const GameStatsHeader = () => {
  const { gameState } = useGame();

  return (
    <div className="d-flex justify-content-end align-items-center text-light py-3 game-header game-header-overlay">
      <div className="d-flex align-items-center px-3">
        <div className="me-5">
          League: <span className="fw-bold ms-3">{gameState.league}</span>
        </div>
        <div className="me-5">
          Bank:{" "}
          <span className="fw-bold ms-3">
            ${gameState.money.toLocaleString()}
          </span>
        </div>
        <div className="d-flex align-items-center">
          <GameSettingsMenu />
        </div>
      </div>
    </div>
  );
};

export default GameStatsHeader;
