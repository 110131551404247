/*
Match states dictate the current state of the match which dictates the available buttons and match flow.

Once the user has started a match, the match state becomes READY_TO_START.

Upon clicking "Start Match", the state then becomes INNINGS_IN_PROGRESS.
The innings will then be in progress until the maximum legal deliveries are completed or 10 wickets are taken.
At the conclusion of the innings, the state moves to END_OF_INNINGS
Upon clicking "Start next innings", the match state moves back to INNINGS_IN_PROGRESS.
The innings will then be in progress until the maximum legal deliveries are completed, 10 wickets are taken, or the chasing team has won the match.
The match state will finally be set to END_OF_MATCH.

Match initialises, button should be Start Match and matchState should be ready to start.
When clicked, button should read innings in progress and be disabled, and match state should be innings in progress
When the end of the innings happens, match state should be end of innings, button should be enabled and say Start next innings
When clicked, button should ready innings in progress and be disabled
When the end of the innings happens, match state should read end of match, button should be enabled and say Start new match
When clicked, the button should read start match, and the match state should be ready to start.
Rinse and repeat.

*/

export const GameStates = {
  NOT_READY: "NOT_READY",
  IN_MENU: "IN_MENU",
  AT_THE_TOSS: "AT_THE_TOSS",
  IN_MATCH: "IN_MATCH",
};

export const MatchStates = {
  AWAITING_NEXT_MATCH: "AWAITING_NEXT_MATCH",
  READY_TO_START: "READY_TO_START",
  INNINGS_IN_PROGRESS: "INNINGS_IN_PROGRESS",
  PAUSED: "PAUSED",
  END_OF_INNINGS: "END_OF_INNINGS",
  END_OF_MATCH: "END_OF_MATCH",
  MATCH_COMPLETED: "MATCH_COMPLETED",
};
