import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useMatch } from "../Contexts/MatchContext";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import useGameImages from "../Hooks/useGameImages.js";
import { getBatsmansStrikeRate } from "../Utils/ScoreUtilities.js";

const FallOfWicketModal = ({ show, handleClose }) => {
  const { state, dispatch } = useMatch();
  const image = useGameImages();
  const [index, setIndex] = useState(0);
  const { batsman, howOut, runs, balls, fours, sixes } =
    state.matchInfo.fallOfWicketDetails;
  const [outBatsman, setOutBatsman] = useState();

  const [availableBatsmen, setAvailableBatsmen] = useState([]);
  const [userIsBatting, setUserIsBatting] = useState(false);

  const handleNext = () => {
    setIndex((prev) => (prev + 1) % availableBatsmen.length);
  };

  const handlePrev = () => {
    setIndex(
      (prev) => (prev - 1 + availableBatsmen.length) % availableBatsmen.length
    );
  };

  const resetModal = () => {
    dispatch({
      type: "PROMOTE_BATSMAN",
      newBatsmanId: availableBatsmen[index].id,
    });

    dispatch({
      type: "PROCESS_END_OF_DELIVERY",
    });

    dispatch({ type: "CHECK_MATCH_STATUS" });

    setIndex(0);
  };

  useEffect(() => {
    const getAvailableBatsmen = () => {
      const battingTeamPlayers =
        state.matchInfo.teams[state.matchInfo.battingTeam].players;
      const { onStrikeBatsmanIndex, offStrikeBatsmanIndex } = state.matchInfo;

      const availablePlayers = battingTeamPlayers.filter(
        (player) =>
          !player.currentMatch[state.matchInfo.teamInningsIndex].howOut.length >
            0 &&
          battingTeamPlayers[onStrikeBatsmanIndex].id !== player.id &&
          battingTeamPlayers[offStrikeBatsmanIndex].id !== player.id
      );

      setAvailableBatsmen(availablePlayers);
      setOutBatsman(battingTeamPlayers[onStrikeBatsmanIndex]);
    };

    getAvailableBatsmen();
    setUserIsBatting(state.matchInfo.battingTeam === "teamA");
  }, [state.matchInfo]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      onExited={resetModal}
      centered
      backdrop="static"
      keyboard={false}
      size={ "lg"}
      className="user-select-none "
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark text-light"
      >
        <Modal.Title>Fall of wicket</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-light d-flex">
        <Row className="d-flex flex-column w-100">
          <Row className="d-flex justify-content-between">
            {userIsBatting && outBatsman && (
              <Col>
                <img
                  className="profile-image rounded"
                  src={image[outBatsman.profileImage]}
                  alt="Out batsman"
                />
              </Col>
            )}
            <Col
              className={`d-flex flex-column justify-content-center align-items-center ${
                userIsBatting ? "col-9" : ""
              }`}
            >
              <Row className="d-flex w-100">
                <Col className="col-4 fw-bold">Batsman</Col>
                <Col className="col-2 text-center fw-bold">Runs</Col>
                <Col className="col-2 text-center fw-bold">Balls</Col>
                <Col className="col-1 text-center fw-bold">4s</Col>
                <Col className="col-1 text-center fw-bold">6s</Col>
                <Col className="col-2 text-center fw-bold">S/R</Col>
              </Row>
              <Row className="mb-3 d-flex w-100">
                <Col className="col-4">{batsman}</Col>
                <Col className="col-2 text-center">{runs}</Col>
                <Col className="col-2 text-center">{balls + 1}</Col>
                <Col className="col-1 text-center">{fours}</Col>
                <Col className="col-1 text-center">{sixes}</Col>
                <Col className="col-2 text-center">{getBatsmansStrikeRate(runs, balls + 1)}</Col>
              </Row>
              <Row className="mt-4 d-flex w-100">
                <Col className="col-12 d-flex justify-content-start">
                  <div className="fw-bold me-3">How out:</div> {howOut}
                </Col>
              </Row>
            </Col>
          </Row>

          {userIsBatting && (
            <Row className="d-flex flex-column w-100">
              <Col className="d-flex justify-content-center">
                <div className="mt-5 mb-3 fw-bold">Next Batsman</div>
              </Col>
              <Col className="d-flex justify-content-center w-100">
                <InputGroup className="justify-content-center">
                  <Button variant="primary" onClick={handlePrev}>
                    <GrPrevious />
                  </Button>
                  <div className="batsman-container">
                    <span
                      className={`batsman-display form-control bg-black text-light border-1 border-black`}
                    >
                      {availableBatsmen &&
                        availableBatsmen.length > 0 &&
                        `${availableBatsmen[index].firstName} ${availableBatsmen[index].lastName}`}
                    </span>
                  </div>
                  <Button variant="primary" onClick={handleNext}>
                    <GrNext />
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="outline-light" onClick={handleClose}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FallOfWicketModal;
