import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import SelectedSquadMemberComponent from "../Shared/SelectedSquadMemberComponent.js";
import FullSquadComponent from "./FullSquadComponent";
import PlayerDescription from "./PlayerDescription";
import { useGame } from "../../../Contexts/GameContext.js";

const SquadComponent = () => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { gameState, dispatchGame } = useGame();

  useEffect(() => {}, [selectedPlayer]);

  return (
    <Row className="d-flex overflow-hidden user-select-none w-100 p-3">
      <Col className="col-3">
        <Card className="overflow-hidden border-0">
          <Card.Body className="p-0 bg-primary text-light text-center">
            <div className="overflow-hidden p-0 m-0">
              <SelectedSquadMemberComponent
                player={selectedPlayer || gameState.squad[0]}
              />
            </div>
            <div>
              <PlayerDescription
                player={selectedPlayer || gameState.squad[0]}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>

      <Col className="col-9 h-100 ">
        <FullSquadComponent
          selectedPlayer={selectedPlayer || gameState.squad[0]}
          setSelectedPlayer={setSelectedPlayer}
        />
      </Col>
    </Row>
  );
};

export default SquadComponent;
