import React from "react";
import { Row, Col } from "react-bootstrap";
import { CiLock } from "react-icons/ci";
import { IoIosCheckmarkCircle } from "react-icons/io";
import useGameImages from "../../../Hooks/useGameImages";
import { useGame } from "../../../Contexts/GameContext";

const SquadMembersComponent = ({ setSelectedPlayer }) => {
  const image = useGameImages();
  const { gameState } = useGame();

  const getPlayerRoleIcon = (role) => {
    switch (role) {
      case "batsman":
        return image["batsmanImage"];
      case "bowler":
        return image["bowlerImage"];
      case "allRounder":
        return image["allRounderImage"];
      case "wicketKeeper":
        return image["wicketKeepingGloves"];
      default:
        break;
    }
  };

  const selectPlayer = (player) => {
    //if (player.enabled) setSelectedPlayer(player);
    setSelectedPlayer(player);
  };

  const renderPlayerCards = (player, index) => {
    return (
      <div
        key={`player-${player.id}`}
        className={`player-card ${!player.enabled ? "disabled" : ""} bg-dark`}
        onClick={() => selectPlayer(player)}
      >
        {player.selected && (
          <div className="player-selected">
            <IoIosCheckmarkCircle className="text-light" size={18} />
          </div>
        )}
        {!player.enabled && (
          <div className="icon-lock">
            <CiLock size={36} /> {/* Adjust size as needed */}
          </div>
        )}
        <div className="profile-img-container user-select-none ">
          <img
            src={image[player.profileImage]}
            alt="Player Profile"
            className="fluid user-select-none "
            draggable="false"
          />
          <img
            src={player.role && getPlayerRoleIcon(player.role)}
            alt="Player Role"
            className="fluid user-select-none "
            draggable="false"
          />
        </div>
        <div className="text-light player-name text-center">
          {player.playerInitial}. {player.lastName}
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex overflow-hidden h-100">
      <Row className="d-flex">
        {gameState.squad &&
          gameState.squad.map((player, index) => {
            return (
              <Col className="col-4" key={player.id}>
                {renderPlayerCards(player, index)}
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default SquadMembersComponent;
