import React, { useState, useRef, useEffect } from "react";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import { IoSettings } from "react-icons/io5";
import { useAuth } from "../../../Contexts/AuthContext";

const GameSettingsMenu = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const { signOut } = useAuth();

  const handleLogout = () => {
    console.log("Handling logout");
    signOut();
  };

  const toggleSettingsMenu = () => {
    setShow(!show);
  };

  useEffect(() => {
    // This function checks if a click is outside of the menu to close it
    const checkIfClickedOutside = (e) => {
      // Ignore right-clicks
      if (e.type === 'mousedown' && e.button !== 2) {
        if (target.current && !target.current.contains(e.target)) {
          setShow(false);
        }
      }
    };
  
    if (show) {
      // Add event listener only when the menu is open
      document.addEventListener("mousedown", checkIfClickedOutside);
    }
  
    return () => {
      // Clean up the event listener when the component unmounts or when the menu is closed
      if (show) {
        document.removeEventListener("mousedown", checkIfClickedOutside);
      }
    };
  }, [show]); // Dependency on 'show' to re-run the effect when it changes
  

  return (
    <Dropdown
      ref={target}
      as={ButtonGroup}
      show={show}
      className="game-settings-icon"
    >
      <Button
        onClick={toggleSettingsMenu}
        variant="transparent"
        className="text-light"
      >
        <IoSettings size={24} />
      </Button>

      <Dropdown.Menu className="bg-dark" align={{ lg: "end" }}>
        <Dropdown.Item className="bg-dark text-light" onClick={handleLogout}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GameSettingsMenu;
