import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { Col, Row, Table } from "react-bootstrap";
import { useMatch } from "../Contexts/MatchContext";
import StackedProgressBar from "./StackedProgressBar";
import { getBatsmansStrikeRate } from "../Utils/ScoreUtilities";
import useGameImages from "../Hooks/useGameImages.js";
import ToastService from "../Utils/ToastService";
import AggressionSelector from "./MatchComponents/AggressionSelector.js";
import { useGame } from "../Contexts/GameContext.js";

const CurrentBatsmanComponent = ({ currentPlayer, handleCardPlayed }) => {
  const { state, dispatch } = useMatch();
  const { playersBaseState } = useGame();
  const [batsmanIsOnStrike, setBatsmanIsOnStrike] = useState(false);
  const image = useGameImages();

  const updatePlayerAggression = (aggression) => {
    dispatch({
      type: "UPDATE_PLAYER_AGGRESSION",
      currentPlayer: currentPlayer,
      aggression: aggression,
      baseStats: playersBaseState,
      playerIsBatting: true,
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: "card", // Define the type of items this component can accept
    drop: (item, monitor) => {
      // Perform an action when an item is dropped
      // For example, update the state or dispatch an action to the context
      const didDrop = monitor.didDrop();

      if (didDrop) return;

      // Check if the player already has the maximum number of cards and return if they do.
      if (currentPlayer.modifiers && currentPlayer.modifiers.length > 2) {
        ToastService.error("Players can only have 3 active cards!");
        return;
      }

      handleCardPlayed(item.id);

      dispatch({
        type: "APPLY_DROPPED_CARD",
        playerId: currentPlayer.id,
        effect: item.effect, // Ensure this matches your card configuration
        cooldown: item.cooldown,
        image: item.image,
        backgroundColor: item.backgroundColor,
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  // Define a dynamic style based on the isOver state to color the background of the current batsman as an indicator
  const dropHoverEffect = {
    transition: isOver ? "padding 0.1s ease-in, box-shadow 0.1s ease-in" : "", // Smooth transition for both properties
    //boxShadow: isOver ? 'inset 0px 4px 8px rgba(0, 34, 20, 0.6)' : 'none', // Apply inset box shadow when hovered
    padding: isOver ? "0px 0.5em" : "",
    borderRadius: isOver ? "8px" : "",
  };

  useEffect(() => {
    setBatsmanIsOnStrike(
      state.matchInfo.teams[state.matchInfo.battingTeam].players[
        state.matchInfo?.onStrikeBatsmanIndex
      ].id === currentPlayer.id
    );
  }, [
    currentPlayer.id,
    state.matchInfo.battingTeam,
    state.matchInfo.onStrikeBatsmanIndex,
    state.matchInfo.teams,
  ]);

  return (
    <Row
      ref={drop}
      style={dropHoverEffect}
      className="d-flex user-select-none bg-cricket-green border-0"
    >
      <Col className="col-3 bg-dark">
        <div className="profile-image-container">
          <img
            className={"profile-image"}
            src={image[currentPlayer.profileImage]}
            alt="Profile"
          />
        </div>

        <div className="position-relative">
          <Row className="d-flex align-items-end tiny-card-container g-0">
            {currentPlayer?.modifiers &&
              currentPlayer.modifiers.map((card, index) => {
                return (
                  <Col key={index} className={`text-light fs-7 col-4`}>
                    <div className="tiny-card-inner-container">
                      <img
                        src={image[card.image]}
                        alt="Modifier Card"
                        className="tiny-card"
                      />
                      <div className="tiny-card-duration d-flex align-items-center justify-content-center w-100 fw-bold fs-6 text-light">
                        <div>{card.duration}</div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </Col>

      <Col className="col-9">
        <div className="d-flex justify-content-center w-100 mt-1">
          <Row className="d-flex flex-column w-100">
            <Col className="mb-1">
              <Table>
                <thead>
                  <tr className="scorecard-table">
                    <th className="col-3">Batsman</th>
                    <th className="col-1">R</th>
                    <th className="col-1">B</th>
                    <th className="col-1">4s</th>
                    <th className="col-1">6s</th>
                    <th className="col-2">S/R</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="scorecard-table">
                    <td>
                      {currentPlayer?.playerInitial}. {currentPlayer?.lastName}{" "}
                      {batsmanIsOnStrike && "*"}
                    </td>
                    <td>
                      {
                        currentPlayer?.currentMatch[
                          state.matchInfo.teamInningsIndex
                        ].runs
                      }
                    </td>
                    <td>
                      {
                        currentPlayer?.currentMatch[
                          state.matchInfo.teamInningsIndex
                        ].ballsFaced
                      }
                    </td>
                    <td>
                      {
                        currentPlayer?.currentMatch[
                          state.matchInfo.teamInningsIndex
                        ].fours
                      }
                    </td>
                    <td>
                      {
                        currentPlayer?.currentMatch[
                          state.matchInfo.teamInningsIndex
                        ].sixes
                      }
                    </td>
                    <td>
                      {getBatsmansStrikeRate(
                        currentPlayer?.currentMatch[
                          state.matchInfo.teamInningsIndex
                        ].runs,
                        currentPlayer?.currentMatch[
                          state.matchInfo.teamInningsIndex
                        ].ballsFaced
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

            <Col className="d-flex justify-content-between mb-3">
              <div className="me-3 w-25">
                <div className="fs-7 mb-1 fw-bold">Pressure</div>
                <div>
                  <StackedProgressBar currentValue={currentPlayer?.pressure} />
                </div>
              </div>

              <div className="me-3 w-25">
                <div className="fs-7 mb-1 fw-bold">Form</div>
                <div>
                  <StackedProgressBar
                    dangerFirst={true}
                    currentValue={currentPlayer?.form}
                  />
                </div>
              </div>

              <div className="me-3 w-25">
                <div className="fs-7 mb-1 fw-bold">Fitness</div>
                <div>
                  <StackedProgressBar
                    dangerFirst={true}
                    currentValue={currentPlayer?.fitnessLevel}
                  />
                </div>
              </div>
            </Col>

            <Col className="d-flex justify-content-between align-items-center w-100 mb-3">
              <AggressionSelector
                title={"Batting Aggression"}
                updatePlayerAggression={updatePlayerAggression}
                currentPlayer={currentPlayer}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default CurrentBatsmanComponent;
