import React, { useEffect, useState } from "react";
import { useMatch } from "../Contexts/MatchContext";
import { Col, Row } from "react-bootstrap";
import {
  getBattingTeamRunRate,
  getCurrentMatchState,
  getRequiredRunRate,
} from "../Utils/ScoreUtilities";
import { useGame } from "../Contexts/GameContext";
import { useAuth } from "../Contexts/AuthContext";

const MatchOverviewComponent = () => {
  const { state } = useMatch();
  const { gameState } = useGame();
  const { currentUser } = useAuth();

  const matchInfo = state.matchInfo;
  const teamInningsIndex = matchInfo.teamInningsIndex;
  const homeTeam =
    matchInfo.initialBattingTeam === "teamA"
      ? matchInfo.teams.teamA
      : matchInfo.teams.teamB;
  const awayTeam =
    matchInfo.initialBattingTeam === "teamA"
      ? matchInfo.teams.teamB
      : matchInfo.teams.teamA;
  const [requiredRunRate, setRequiredRunRate] = useState();

  useEffect(() => {
    setRequiredRunRate(
      getRequiredRunRate(
        awayTeam.innings[teamInningsIndex].score,
        homeTeam.innings[teamInningsIndex].score,
        matchInfo.maximumLegalDeliveries -
          awayTeam.innings[teamInningsIndex].legalDeliveries
      )
    );
  }, [
    awayTeam.innings,
    homeTeam.innings,
    matchInfo.maximumLegalDeliveries,
    teamInningsIndex,
    state.matchInfo.teams.teamB,
  ]);

  return (
    <div className="d-flex flex-column align-items-start justify-content-center h-100 user-select-none ">
      <Row className="d-flex px-3 jsutify-content-between w-100 mb-2">
        <Col className="fw-bold fs-5 col-6">
          <div className="mt-3 fw-bold text-light">
            {gameState.teamName} vs. {state.matchInfo.teams["teamB"].name}
          </div>
        </Col>

        {matchInfo.currentInnings !== 0 && (
          <Col className="d-flex align-items-center justify-content-end fs-6 col-6 mt-3">
            {getCurrentMatchState(currentUser, state.matchInfo).description}
          </Col>
        )}
      </Row>

      <Row className="d-flex px-3 jsutify-content-between w-100 mb-2">
        {matchInfo.currentInnings === 0 ? (
          <Col>
            {"RR: " +
              getBattingTeamRunRate(
                homeTeam.innings[teamInningsIndex].score,
                homeTeam.innings[teamInningsIndex].legalDeliveries
              )}
          </Col>
        ) : (
          <>
            <Col>
              RR:{" "}
              {getBattingTeamRunRate(
                awayTeam.innings[teamInningsIndex].score,
                awayTeam.innings[teamInningsIndex].legalDeliveries
              )}
            </Col>
            <Col
              className={`d-flex justify-content-end fw-bold ${
                requiredRunRate && requiredRunRate < 4
                  ? "text-success"
                  : requiredRunRate < 6
                  ? "text-light"
                  : "text-danger"
              }`}
            >
              RRR: {requiredRunRate}
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default MatchOverviewComponent;
