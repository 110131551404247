import { toast } from "react-toastify";

const options = {
  position: "top-right",
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const ToastService = {
  success(message, config = {}) {
    toast.success(message, { ...options, ...config });
  },
  error(message, config = {}) {
    toast.error(message, { ...options, ...config });
  },
  info(message, config = {}) {
    toast.info(message, { ...options, ...config });
  },
  warning(message, config = {}) {
    toast.warn(message, { ...options, ...config });
  },
};

export default ToastService;
