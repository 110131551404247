import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useGame } from "../../../Contexts/GameContext.js";
import useGameImages from "../../../Hooks/useGameImages.js";
import GameStatsHeader from "../Shared/GameStatsHeader.js";
import { submitFeedback } from "../../../Api/Firebase/firebaseAPI.js";
import NextMatchComponent from "./NextMatchComponent.js";

const HomeComponent = () => {
  const { gameState } = useGame();
  const image = useGameImages();
  const feedbackRef = useRef();
  const [feedback, setFeedback] = useState("");

  const handleSubmitFeedback = async () => {
    const success = await submitFeedback(feedback);
    if (success) {
      setFeedback("");
    }
  };

  const handleChangeFeedback = (e) => {
    setFeedback(e.target.value);
  };

  return (
    <div className="d-flex flex-column w-100 h-100">
      <GameStatsHeader />
      <Row className="d-flex flex-grow-1 w-100 m-0 p-0">
        <Col className="col-6 p-3 h-50">
          <NextMatchComponent />
        </Col>

        <Col className="col-6 p-3">
          <Card className="w-100 h-100 bg-dark text-light rounded d-flex flex-column justify-content-center align-items-center p-5 text-center overflow-y-auto">
            So you think you can take this ragtag bunch of cricketers to
            ultimate glory?
            <div className="fs-3 fw-bold pt-3">Play ball!</div>
          </Card>
        </Col>

        <Col className="col-6 p-3 h-50">
          <Card className="w-100 h-100 bg-dark text-light mb-5 shadow overflow-hidden border-0">
            <Card.Body className="d-flex bg-dark text-light">
              <Row className="d-flex justify-content-betweenh-100 w-100">
                <Col className="col-4 h-50 d-flex flex-column align-items-center">
                  <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="fw-bold mb-3">Matches</div>
                    <div className="fs-2 fw-bold text-primary">
                      {gameState.matches}
                    </div>
                  </div>
                </Col>
                <Col className="text-center col-4 h-50">
                  <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="fw-bold mb-3">Wins</div>
                    <div className="fs-2 fw-bold text-primary">
                      {gameState.wins}
                    </div>
                  </div>
                </Col>
                <Col className="text-center col-4 h-50">
                  <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="fw-bold mb-3">Win %</div>
                    <div className="fs-2 fw-bold text-primary">
                      {gameState.wins === 0 || gameState.matches === 0
                        ? "-"
                        : ((gameState.wins / gameState.matches) * 100).toFixed(
                            0
                          )}
                    </div>
                  </div>
                </Col>
                <Col className="text-center col-4 h-50">
                  <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="fw-bold mb-3">Losses</div>
                    <div className="fs-2 fw-bold text-primary">
                      {gameState.losses}
                    </div>
                  </div>
                </Col>
                <Col className="text-center col-4 h-50">
                  <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="fw-bold mb-3">Loss %</div>
                    <div className="fs-2 fw-bold text-primary">
                      {gameState.losses === 0 || gameState.matches === 0
                        ? "-"
                        : (
                            (gameState.losses / gameState.matches) *
                            100
                          ).toFixed(0)}
                    </div>
                  </div>
                </Col>
                <Col className="text-center col-4 h-50">
                  <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="fw-bold mb-3">Ties</div>
                    <div className="fs-2 fw-bold text-primary">
                      {gameState.ties}
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col className="col-6 h-50 p-3">
          <Card className="w-100 h-100 border-0 bg-dark">
            <Card.Header className="bg-primary text-light text-center fw-bold fs-3">
              FEEDBACK
            </Card.Header>
            <Card.Body className="d-flex flex-column text-light">
              <div className="py-3 fs-6">
                Help improve the game by sharing your thoughts, suggestions, or
                issues.
              </div>
              <div className="flex-grow-1 py-3">
                <Form.Control
                  ref={feedbackRef}
                  as="textarea"
                  className="h-100 no-resize bg-black border-0 text-light"
                  value={feedback}
                  onChange={handleChangeFeedback}
                />
              </div>
              <div className="w-100 d-flex justify-content-end">
                <Button
                  onClick={handleSubmitFeedback}
                  disabled={feedback.length < 3}
                >
                  Send
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HomeComponent;
