import React from "react";

const PlayerDescription = ({ player }) => {
  return (
    <div className="player-description p-3">
      {player.description && player.description}
    </div>
  );
};

export default PlayerDescription;
