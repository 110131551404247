import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useGame } from "../../../Contexts/GameContext";
import useGameImages from "../../../Hooks/useGameImages";

const NextMatchComponent = () => {
  const { gameState } = useGame();
  const image = useGameImages();
  const [homeTeamCaptainImage, setHomeTeamCaptainImage] = useState();

  useEffect(() => {
    const captain = gameState.squad.find((player) => player.captain === true);
    setHomeTeamCaptainImage(captain);
  }, [gameState.squad]);

  return (
    <Card className="w-100 h-100 bg-dark text-light mb-5 shadow border-0 overflow-hidden">
      <Card.Header className="text-center fs-3 bg-primary text-light fw-bold">
        NEXT MATCH
      </Card.Header>
      <Card.Body className="d-flex flex-column bg-dark text-light d-flex align-items-center justify-content-between h-100">
        <Row className="w-100 overflow-hidden">
          <Col className="col-2">
            <div className="profile-image-container">
              <img
                src={
                  homeTeamCaptainImage &&
                  image[homeTeamCaptainImage.profileImage]
                }
                alt="Captain"
                className="profile-image rounded"
              />
            </div>
          </Col>
          <Col className="col-10 d-flex align-items-center">
            <div className="fs-4 fw-bold">{gameState.teamName}</div>
          </Col>
        </Row>

        <Row className="">
          <Col className="fw-bold d-flex align-items-center col-12 fs-1">
            VS.
          </Col>
        </Row>

        <Row className="w-100 overflow-hidden">
          <Col className="col-10 d-flex justify-content-end align-items-center">
            <div className="fs-4 fw-bold me-3">
              {gameState.nextOpposition.teamName}
            </div>
          </Col>
          <Col className="col-2">
            <div className="profile-image-container">
              <img
                src={image[gameState.nextOpposition.profileImage]}
                alt="Captain"
                className="profile-image rounded"
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NextMatchComponent;
