import React from "react";
import { useLoading } from "../../../Contexts/LoadingContext";
import { Col, Row, Spinner } from "react-bootstrap"; // or any spinner component you prefer
import "../../../Styles/Loading.scss";

const LoadingIndicator = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <Row className="loading-overlay p-0 m-0">
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <Spinner animation="border" role="status" />
        <div className="fw-bold text-light my-3">
          Hang tight! We're making the final touches to your squad.
        </div>
      </Col>
    </Row>
  );
};

export default LoadingIndicator;
