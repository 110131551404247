// GameContext.js

import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { GameStates } from "../Constants/Constants";

import {
  fetchAndCombineSquadData,
  fetchOppositionPlayers,
  fetchRandomOppositionTeam,
  updateNextOpposition,
  updatePlayerProgressBatch,
  updatePlayerSelection,
  updateUserMoney,
} from "../Api/Firebase/firebaseAPI";
import { useAuth } from "../Contexts/AuthContext";
import { useLoading } from "./LoadingContext";
import ToastService from "../Utils/ToastService";
import { OppositionTeams } from "../DataLists/OppositionTeams";

// Initial state of the game
const initialState = {
  gameState: GameStates.NOT_READY,
  teamName: "",
  nextOpposition: {},
  league: "Club",
  money: 0,
  matches: 0,
  wins: 0,
  losses: 0,
  draws: 0,
  ties: 0,
  squad: [],
  gearbag: [],
};

// Create the context
const GameContext = createContext(initialState);

// Reducer function to handle state changes
const gameReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE_SQUAD":
      const sortedPlayers = action.payload.squad.sort((a, b) => a.id - b.id);
      return {
        ...state,
        squad: sortedPlayers,
      };
    case "INITIALIZE_USER_DETAILS":
      return {
        ...state,
        money: action.payload.money, // Update money from payload
        teamName: action.payload.teamName, // Update teamName from payload
        league: action.payload.league, // Update league from payload
        matches: action.payload.matches, // Update matches from payload
        wins: action.payload.wins, // Update wins from payload
        losses: action.payload.losses, // Update losses from payload
        draws: action.payload.draws, // Update draws from payload
        ties: action.payload.ties, // Update ties from payload
      };
    case "UPDATE_USER_RESULTS":
      // TODO: Update user results
      /**
       * Used to update the matches, wins, score etc
       */
      break;
    case "UPDATE_GAME_STATE":
      const updatedSquad = [];
      const actionSquadIds = new Set(action.squad.map((player) => player.id));

      // First, go through the current squad to update or retain existing players
      state.squad.forEach((player) => {
        const actionPlayer = action.squad.find((ap) => ap.id === player.id);
        if (actionPlayer) {
          // If player exists in action.squad, replace with the new player data
          updatedSquad.push(actionPlayer);
        } else {
          // If player does not exist in action.squad, retain the old player data
          updatedSquad.push(player);
        }
      });

      // Then, add new players from action.squad who are not in the current squad
      action.squad.forEach((player) => {
        if (!actionSquadIds.has(player.id)) {
          updatedSquad.push(player);
        }
      });

      return {
        ...state,
        gameState: action.newGameState,
        squad: updatedSquad,
      };
    case "UPDATE_PLAYER_STATS":
      return {
        ...state,
        squad: state.squad.map((player) => {
          // Find an update for this player, if it exists
          const update = action.updatedPlayerStats.find(
            (upd) => upd.id === player.id
          );
          // If an update exists, merge it with the player's data, otherwise return the original player
          return update ? { ...player, ...update } : player;
        }),
      };
    case "UPDATE_GAME_RESULTS":
      return {
        ...state,
        matches: action.newResults.matches,
        wins: action.newResults.wins,
        losses: action.newResults.losses,
        draws: action.newResults.draws,
        ties: action.newResults.ties,
      };
    case "TOGGLE_PLAYER_SELECTION":
      console.log("Action: ", action);
      updatePlayerSelection(
        action.userId,
        action.player.id,
        !action.player.selected
      );

      return {
        ...state,
        squad: state.squad.map((player) =>
          player.id === action.player.id
            ? { ...player, selected: !player.selected }
            : player
        ),
      };
    case "INCREASE_USER_MONEY":
      return {
        ...state,
        money: state.money + action.money,
      };
    case "ADD_GEAR_TO_USER":
      // TODO: Add gear to user
      /**
       * Used to add gear to the users gearbag
       */
      break;
    case "LEVEL_UP":
      return { ...state, level: state.level + 1 };
    case "SET_NEXT_OPPOSITION":
      return {
        ...state,
        nextOpposition: action.payload,
      };
    case "UPDATE_TEAM_NAME":
      return {
        ...state,
        teamName: action.payload,
      };
    case "RESET_GAME":
      return {
        ...initialState, // Reset to the initial state
      };
    case "ENABLE_GAME":
      return {
        ...state,
        gameState: GameStates.IN_MENU,
      };
    default:
      return state;
  }
};

export const GameProvider = ({ children }) => {
  const [gameState, gameDispatch] = useReducer(gameReducer, initialState);
  const { currentUser } = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const [playersBaseState, setPlayersBaseState] = useState();

  const saveEndGameData = async (updatedSquadDetails) => {
    const squadIndexMap = new Map(
      gameState.squad.map((player, index) => [player.id, index])
    );
    const mergedSquad = [...gameState.squad];

    updatedSquadDetails.forEach((updatedPlayer) => {
      if (squadIndexMap.has(updatedPlayer.id)) {
        // Update existing player details in the squad
        mergedSquad[squadIndexMap.get(updatedPlayer.id)] = updatedPlayer;
      } else {
        // Add new player to the squad if needed (not likely needed but just in case)
        mergedSquad.push(updatedPlayer);
      }
    });

    if (currentUser && mergedSquad.length) {
      try {
        await updatePlayerProgressBatch(currentUser.uid, mergedSquad);
        console.log("Squad updated successfully");
      } catch (error) {
        console.error("Failed to update players at game end:", error);
      }
    }

    setNextOpposition();
  };

  /**
   *
   * @param {object} nextOpposition
   *
   * When an object is passed in, that opposition will be set otherwise, it will find a new opposition
   */
  const setNextOpposition = async (nextOpposition) => {
    if (nextOpposition) {
      gameDispatch({
        type: "SET_NEXT_OPPOSITION",
        payload: nextOpposition,
      });
    } else {
      console.log("No initial load so determining the next team");

      // Fetch the next opposition team details
      const randomTeam = await fetchRandomOppositionTeam(
        currentUser.uid,
        "Club"
      );

      if (!randomTeam) {
        console.error(
          "NEXTTEAM: No available teams after fetching from Firebase"
        );
        return;
      }

      // Combine team details with players
      const nextOppositionDetails = {
        ...randomTeam,
      };

      // Log the combined opposition team details
      console.log("Next Opposition Details:", nextOppositionDetails);

      gameDispatch({
        type: "SET_NEXT_OPPOSITION",
        payload: nextOppositionDetails,
      });

      updateNextOpposition(currentUser.uid, nextOppositionDetails);
    }
  };

  const saveNewMoneyTotal = async (money) => {
    if (currentUser && money > 0) {
      try {
        await updateUserMoney(currentUser.uid, money);
      } catch (error) {
        console.log("Failed to update users money.");
      }
    }
  };

  useEffect(() => {
    if (!currentUser) {
      gameDispatch({ type: "RESET_GAME" });
    } else {
      showLoading();
      const userId = currentUser.uid;
      fetchAndCombineSquadData(userId)
        .then((data) => {
          gameDispatch({
            type: "INITIALIZE_SQUAD",
            payload: { squad: data.combinedData },
          });

          gameDispatch({
            type: "INITIALIZE_USER_DETAILS",
            payload: {
              money: data.userData.money,
              teamName: data.userData.teamName,
              nextOpposition: data.userData.nextOpposition,
              league: data.userData.league,
              matches: data.userData.matches,
              wins: data.userData.wins,
              losses: data.userData.losses,
              draws: data.userData.draws,
              ties: data.userData.ties,
            },
          });

          setPlayersBaseState(data.combinedData); // Sets the base state of the player.
          setNextOpposition(data.userData.nextOpposition);

          hideLoading();
          gameDispatch({ type: "ENABLE_GAME" });
        })
        .catch((error) => {
          console.error("Critical error, unable to get player data", error);
          hideLoading();
        });
    }
  }, [currentUser]);

  const togglePlayerSelection = async (player, event) => {
    // Check to see how many players are selected. If there are 11, then Toast notify and don't set player.
    // If there are fewer than 11 players selected then enable player.selected and update the game state.
    const selectedPlayerCount = gameState.squad.filter(
      (squadMember) => squadMember.selected
    ).length;

    if (player.selected || (!player.selected && selectedPlayerCount < 11)) {
      // If the player is selected, we can always deselect them
      gameDispatch({
        type: "TOGGLE_PLAYER_SELECTION",
        player: player,
        userId: currentUser.uid,
      });
    } else {
      event.preventDefault();
      ToastService.warning("Too many players, need to deselect a player first");
    }
  };

  const startNewMatch = () => {
    gameDispatch({
      type: "UPDATE_GAME_STATE",
      newGameState: GameStates.IN_MATCH,
      squad: gameState.squad,
    });
  };

  const saveGameResults = (newResults) => {
    gameDispatch({
      type: "UPDATE_GAME_RESULTS",
      newResults: newResults,
    });
  };

  // Value to be passed to the context
  const value = {
    gameState,
    playersBaseState,
    gameDispatch,
    togglePlayerSelection,
    startNewMatch,
    saveEndGameData,
    saveNewMoneyTotal,
    saveGameResults,
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

// Custom hook to use the game context
export const useGame = () => {
  return useContext(GameContext);
};
