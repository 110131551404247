import React, { useEffect, useState } from "react";
import { signUp, signIn } from "../Api/Firebase/auth";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { sendPasswordReset } from "../Api/Firebase/firebaseAPI";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const handleSignUp = async () => {
    setLoading(true);
    const response = await signUp(email, password);
    if (response) {
      setError(response);
    }
    setLoading(false);
  };

  const handleSignIn = async () => {
    setLoading(true);
    const response = await signIn(email, password);
    if (response) {
      setError(response);
    }
    setLoading(false);
  };

  const handlePasswordReset = async () => {
    setLoading(true);
    const response = await sendPasswordReset(email);
    if (response.success) {
      setError("Please check your email.");
    } else {
      setError("Error. Unable to send password reset email.");
    }
    setLoading(false);
  };

  useEffect(() => {
    setError("");
  }, [forgotPassword]);

  return (
    <Row className="h-100 m-0 p-0 bg-black d-flex bg-main-image">
      <Col className="d-flex justify-content-center align-items-center h-100 flex-grow-1">
        <div className="login-image h-50 w-25 fs-1 text-light d-flex justify-content-center align-items-end text-center fw-bolder rounded-start" />
        <Card className="bg-dark border-0 px-5 h-50 rounded-end">
          {forgotPassword ? (
            <Card.Body className="d-flex flex-column justify-content-between align-items-center">
              <div className="text-light fs-1 fw-bolder mb-5">
                THE MAD CRICKETER
              </div>
              <FormGroup className="w-100">
                <Form.Label className="text-light">Email</Form.Label>

                <Form.Control
                  name="cricketer-email"
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                />
              </FormGroup>

              <div className="login-error text-light">
                {loading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  <span className="fs-7">{error}</span>
                )}
              </div>

              <div className="d-flex justify-content-between w-100 mt-5">
                <Button
                  variant="primary"
                  className="w-100 me-1"
                  onClick={handlePasswordReset}
                  disabled={loading}
                >
                  Send Password Reset Email
                </Button>
              </div>
              <div
                className="text-light fs-7 py-2 forgot-password"
                onClick={() => setForgotPassword(false)}
              >
                Back to sign in
              </div>
            </Card.Body>
          ) : (
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <div className="text-light fs-1 fw-bolder mb-5">
                THE MAD CRICKETER
              </div>
              <FormGroup className="mb-5 w-100">
                <Form.Label className="text-light">Email</Form.Label>

                <Form.Control
                  name="cricketer-email"
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                />
              </FormGroup>

              <FormGroup className="mb-3 w-100">
                <Form.Label className="text-light">Password</Form.Label>
                <Form.Control
                  name="cricketer-password"
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError("");
                  }}
                />
              </FormGroup>

              <div className="login-error text-light">
                {loading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  <span className="fs-7">{error}</span>
                )}
              </div>

              <div className="d-flex justify-content-between w-100 mt-5">
                <Button
                  variant="warning"
                  className="w-50 me-1"
                  onClick={handleSignUp}
                  disabled={loading}
                >
                  Sign Up
                </Button>
                <Button
                  variant="primary"
                  className="w-50 ms-1"
                  onClick={handleSignIn}
                  disabled={loading}
                >
                  Login
                </Button>
              </div>
              <div
                className="text-light fs-7 py-2 forgot-password"
                onClick={() => setForgotPassword(true)}
              >
                Forgot password?
              </div>
            </Card.Body>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default LoginForm;
