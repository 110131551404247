/**
 * MATCH ENGINE IS USED FOR ALL IN-MATCH ACTIONS
 */

import { clamp } from "../Utils/Utils";

export const calculateDeliveryOutcome = (
  currentBatsman,
  currentBowler,
  battingTeam,
  state,
  applyModifier
) => {
  // Used to adjust the players probabilities
  const adjustProbabilities = (batsman, bowler, probabilities) => {
    // DISABLING FOR TESTING
    // if (batsman.pressure > 80) {
    //   probabilities.wicket += 0.05; // Increase chance of wicket under pressure
    //   probabilities.one -= 0.05; // Decrease change of a one
    // } else if (batsman.pressure > 50) {
    //   probabilities.wicket += 0.02; // Increase the change of a wicket
    //   probabilities.one -= 0.02; // Decrease the change of a one
    // } else if (batsman.pressure <= 50) {
    //   probabilities.wicket -= 0.01;
    //   probabilities.one += 0.05;
    // }

    // Aggression

    // Normalize probabilities to ensure they sum to 1
    return normalizeProbabilities(probabilities);
  };

  // Used to detremine the intial delivery outcome
  const determineOutcome = (probabilities) => {
    let result = Math.random();
    let cumulativeProbability = 0;
    const outcomeMapping = {
      zero: 0,
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      six: 6,
      wicket: "wicket",
      wide: "wide",
      bye: "bye",
      legBye: "leg bye",
      noBall: "no ball",
    };

    for (const outcome in probabilities) {
      cumulativeProbability += probabilities[outcome];
      if (result < cumulativeProbability) {
        return outcomeMapping[outcome];
      }
    }
    return 0; // If no outcome is matched, return a default value (should not happen if probabilities sum to 1)
  };

  const normalizeProbabilities = (probabilities) => {
    let total = Object.values(probabilities).reduce((acc, val) => acc + val, 0);
    for (const key in probabilities) {
      probabilities[key] /= total;
    }

    return probabilities;
  };

  // Combines the thresholds
  const getWeightedThresholds = (currentBatsman, currentBowler) => {
    const battingThresholds = currentBatsman.battingThresholds;
    const bowlingThresholds = currentBowler.bowlingThresholds;
    const batsmanAggressionValue = currentBatsman.battingAggression;
    const bowlerAggressionValue = currentBowler.bowlingAggression;

    const calculateAggressionMultiplier = (aggression) => {
      return 1 + (aggression - 50) / 100;
    };

    const calculateHalfImpactAggressionMultiplier = (aggression) => {
      return 1 + (aggression - 50) / 150;
    };

    const batsmanAggression = calculateAggressionMultiplier(
      batsmanAggressionValue
    );
    const bowlerAggression = calculateAggressionMultiplier(
      bowlerAggressionValue
    );

    const bowlerWicketAggression = calculateHalfImpactAggressionMultiplier(
      bowlerAggressionValue
    );

    console.log("Bowler wicket aggression: ", bowlerWicketAggression);

    const applyAggressionModifiers = (
      thresholds,
      aggression,
      bowlerWicketAggression,
      isPlayerBowler
    ) => {
      return {
        zero: thresholds.zero / (aggression * 2),
        one: thresholds.one * aggression,
        two: thresholds.two * aggression,
        three: thresholds.three,
        four: thresholds.four * aggression,
        six: thresholds.six * aggression,
        wicket: thresholds.wicket * bowlerWicketAggression,
        wide: isPlayerBowler ? thresholds.wide * aggression : thresholds.wide,
        noBall: isPlayerBowler
          ? thresholds.noBall * aggression
          : thresholds.noBall,
        bye: isPlayerBowler ? thresholds.bye * aggression : thresholds.bye,
        legBye: isPlayerBowler
          ? thresholds.legBye * aggression
          : thresholds.legBye,
      };
    };

    // Adjusted batting and bowling thresholds
    const adjustedBattingThresholds = applyAggressionModifiers(
      battingThresholds,
      batsmanAggression,
      bowlerWicketAggression,
      false
    );

    const adjustedBowlingThresholds = applyAggressionModifiers(
      bowlingThresholds,
      bowlerAggression,
      bowlerWicketAggression,
      true
    );

    console.log(
      "Weighting: Adjusted Batting Threshold: ",
      adjustedBattingThresholds
    );
    console.log(
      "Weighting: Adjusted Bowling Threshold: ",
      adjustedBowlingThresholds
    );

    const combinedThresholds = {};

    Object.keys(adjustedBattingThresholds).forEach((key) => {
      combinedThresholds[key] =
        (adjustedBattingThresholds[key] || 0) +
        (adjustedBowlingThresholds[key] || 0);
    });

    const totalSkill = currentBatsman.battingSkill + currentBowler.bowlingSkill;
    const normalizedBattingSkill =
      (currentBatsman.battingSkill / totalSkill) * 100;
    const normalizedBowlingSkill =
      (currentBowler.bowlingSkill / totalSkill) * 100;

    const weightedThresholds = {};

    Object.keys(combinedThresholds).forEach((key) => {
      if (["zero", "wicket", "wide", "noBall", "bye", "legBye"].includes(key)) {
        // Weight towards bowler
        weightedThresholds[key] =
          (combinedThresholds[key] * normalizedBowlingSkill) / 100;
      } else {
        // Weight towards batsman
        weightedThresholds[key] =
          (combinedThresholds[key] * normalizedBattingSkill) / 100;
      }
    });

    console.log(
      "Weighting: Current batsman aggression:",
      currentBatsman.battingAggression
    );
    console.log(
      "Weighting: Current bowler aggression:",
      currentBatsman.bowlingAggression
    );
    console.log(
      "Weighting: Current batsman skill:",
      currentBatsman.battingSkill
    );
    console.log(
      "Weighting: Current bowler skill:",
      currentBatsman.bowlingSkill
    );

    console.log("Weighting: Weigthed thresholds: ", weightedThresholds);

    return weightedThresholds;
  };

  let deliveryOutcome;
  let baseProbabilities;

  baseProbabilities = getWeightedThresholds(currentBatsman, currentBowler);

  const adjustedProbabilities = adjustProbabilities(
    currentBatsman,
    currentBowler,
    baseProbabilities
  );

  // Logging the adjusted probabilities
  Object.keys(adjustedProbabilities).forEach((key) => {
    console.log("Key: ", key, "Value:", adjustedProbabilities[key]);
  });

  // Determine the outcome based on the adjusted probabilities
  deliveryOutcome = determineOutcome(adjustedProbabilities);

  // Modify the delivery if required.
  let playerModifiers;

  if (battingTeam === "teamA") {
    playerModifiers =
      state.matchInfo.teams[state.matchInfo.battingTeam].players[
        state.matchInfo.onStrikeBatsmanIndex
      ].modifiers;
  } else {
    playerModifiers =
      state.matchInfo.teams[state.matchInfo.bowlingTeam].players[
        state.matchInfo.currentBowlerIndex
      ].modifiers;
  }

  const batsman =
    state.matchInfo.teams[state.matchInfo.battingTeam].players[
      state.matchInfo.onStrikeBatsmanIndex
    ];
  const bowler =
    state.matchInfo.teams[state.matchInfo.bowlingTeam].players[
      state.matchInfo.currentBowlerIndex
    ];

  const { modifiedDeliveryOutcome, modifiers, updatedTeamModifiers } =
    applyModifier(
      {
        lastName: batsman.lastName,
        pressure: batsman.pressure,
        form: batsman.form,
        fitnessLevel: batsman.fitnessLevel,
        skill: batsman.battingSkill,
      },
      {
        lastName: bowler.lastName,
        pressure: bowler.pressure,
        form: bowler.form,
        fitnessLevel: bowler.fitnessLevel,
        skill: bowler.bowlingSkill,
      },
      playerModifiers,
      deliveryOutcome,
      state.matchInfo.teamModifiers
    );

  return { modifiedDeliveryOutcome, modifiers, updatedTeamModifiers };
};
