import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useGameImages from "../../../Hooks/useGameImages";
import { useGame } from "../../../Contexts/GameContext";

const SelectedSquadMemberComponent = ({ player }) => {
  const image = useGameImages();
  const [selectedPlayer, setSelectedPlayer] = useState(player);
  const { gameState } = useGame();

  useEffect(() => {
    setSelectedPlayer(
      gameState.squad.find((careerPlayer) => player.id === careerPlayer.id)
    );
  }, [gameState.squad, player]);

  return (
    <Row className="d-flex flex-column overflow-hidden user-select-none m-0">
      <Col className="text-light">
        <div className="mt-3">
          {selectedPlayer.firstName} {selectedPlayer.lastName}
        </div>
        <div className="fs-7 mb-3 fw-bold">
          {selectedPlayer.role.toUpperCase()}
        </div>
      </Col>
      <Col className="bg-dark p-0 max-height-100">
        {player && (
          <>
            {" "}
            <div className="selected-player-image-container">
              <img
                src={image[selectedPlayer.profileImage]}
                alt="Player Profile"
                className="fluid"
              />
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default SelectedSquadMemberComponent;
