import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Menu from "../Components/GameComponents/Menu";
import SquadComponent from "../Components/GameComponents/Squad/SquadComponent";
import HomeComponent from "../Components/GameComponents/Home/HomeComponent";
import GroundComponent from "../Components/GameComponents/Ground/GroundComponent";
import TrainingComponent from "../Components/GameComponents/Training/TrainingComponent";
import GearComponent from "../Components/GameComponents/Gear/GearComponent";
import GameStatsHeader from "../Components/GameComponents/Shared/GameStatsHeader";
import { useGame } from "../Contexts/GameContext";
import GameConfig from "../Components/GameComponents/GameConfig/GameConfig";
import PlayersComponent from "../Components/GameComponents/Players/PlayersComponent";

const GamePage = () => {
  const [menuItemSelected, setMenuItemSelected] = useState("Home");
  const { gameState } = useGame();

  useEffect(() => {}, [menuItemSelected]);

  return (
    <Row className="d-flex h-100 overflow-hidden p-0 m-0">
      {gameState.teamName === "Unnamed" ? (
        <Col className="d-flex justify-content-center align-items-center bg-main-image">
          <GameConfig />
        </Col>
      ) : (
        <>
          <Col className="d-flex flex-column col-2">
            <Menu
              menuItemSelected={menuItemSelected}
              setMenuItemSelected={setMenuItemSelected}
            />
          </Col>
          {menuItemSelected === "Home" && (
            <Col className="bg-dark text-light col-10 h-100 overflow-hidden p-0 bg-main-image">
              <HomeComponent />
            </Col>
          )}
          {menuItemSelected === "Squad" && (
            <Col className="col-10 h-100 overflow-hidden d-flex flex-column flex-grow-1 p-0 bg-main-image">
              <GameStatsHeader />
              <SquadComponent />
            </Col>
          )}
          {menuItemSelected === "Ground" && (
            <Col className="bg-dark text-light col-10 h-100 overflow-hidden p-0 bg-main-image">
              <GroundComponent />
            </Col>
          )}
          {menuItemSelected === "Players" && (
            <Col className="bg-dark text-light col-10 h-100 overflow-hidden p-0 bg-main-image">
              <PlayersComponent />
            </Col>
          )}
          {menuItemSelected === "Training" && (
            <Col className="bg-dark text-light col-10 h-100 overflow-hidden p-0 bg-main-image">
              <TrainingComponent />
            </Col>
          )}
          {menuItemSelected === "Shop" && (
            <Col className="bg-dark text-light col-10 h-100 overflow-hidden p-0 bg-main-image">
              <GearComponent />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default GamePage;
