import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useGame } from "../Contexts/GameContext";
import { useMatch } from "../Contexts/MatchContext";

const TossModal = ({ show, handleClose }) => {
  const { gameState, startNewMatch } = useGame();
  const { dispatch } = useMatch();
  const [canStartMatch, setCanStartMatch] = useState(false);
  const [tossState, setTossState] = useState("READY_FOR_TOSS");
  const [tossResult, setTossResult] = useState("");

  const startMatch = () => {
    startNewMatch();
  };

  const call = (tossCall) => {
    const tossOutcomeValue = Math.random() * 100;
    let tossOutcome;

    if (tossOutcomeValue <= 50) {
      // Coin is heads
      tossOutcome = "heads";
    } else {
      // Coin is tails
      tossOutcome = "tails";
    }

    if (tossCall === tossOutcome) {
      // Choose bat or bowl
      setTossState("PLAYERS_CHOICE");
    } else {
      // NPC chooses
      const npcChoiceValue = Math.random() * 100;

      if (npcChoiceValue <= 50) {
        // set the npc team to bat
        choose(gameState.nextOpposition.teamName, "bat", false);
      } else {
        // set the npc team to bowl
        choose(gameState.nextOpposition.teamName, "bowl", false);
      }
    }
  };

  const choose = (team, choice, isUsersChoice = true) => {
    if (choice === "bat") {
      // Set the match state so that the winning team is batting
      if (isUsersChoice) {
        dispatch({
          type: "SET_FIRST_INNINGS_BATTING_TEAM",
          userIsBatting: true,
        });
      } else {
        dispatch({
          type: "SET_FIRST_INNINGS_BATTING_TEAM",
          userIsBatting: false,
        });
      }
    } else {
      // Set the match state so that the winning team is bowling
      if (isUsersChoice) {
        dispatch({
          type: "SET_FIRST_INNINGS_BATTING_TEAM",
          userIsBatting: false,
        });
      } else {
        dispatch({
          type: "SET_FIRST_INNINGS_BATTING_TEAM",
          userIsBatting: true,
        });
      }
    }

    setTossResult(`${team} has chosen to ${choice}`);
    setTossState(null);
    setCanStartMatch(true);
  };

  useEffect(() => {}, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      keyboard={false}
      className="user-select-none "
      size="md"
    >
      <Modal.Header className="bg-dark text-light">
        <Modal.Title>The Toss</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-light">
        <Row className="my-5">
          <Col className="d-flex justify-content-around">
            {tossState === "READY_FOR_TOSS" ? (
              <>
                <Button onClick={() => call("heads")}>Heads</Button>
                <Button onClick={() => call("tails")}>Tails</Button>
              </>
            ) : tossState === "PLAYERS_CHOICE" ? (
              <>
                <Button onClick={() => choose(gameState.teamName, "bat")}>
                  Bat
                </Button>
                <Button onClick={() => choose(gameState.teamName, "bowl")}>
                  Bowl
                </Button>
              </>
            ) : (
              <>{tossResult}</>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button
          variant="outline-light"
          onClick={startMatch}
          disabled={!canStartMatch}
        >
          Let's Go!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TossModal;
