import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useGame } from "../../../Contexts/GameContext";
import { updateTeamName } from "../../../Api/Firebase/firebaseAPI";
import { useAuth } from "../../../Contexts/AuthContext";

const GameConfig = () => {
  const { currentUser } = useAuth();
  const [teamName, setTeamName] = useState("");
  const { gameState, gameDispatch } = useGame();

  const handleUpdateTeamName = () => {
    updateTeamName(currentUser.uid, teamName);

    gameDispatch({
      type: "UPDATE_TEAM_NAME",
      payload: teamName,
    });
  };

  return (
    <Card className="bg-dark text-light m-3 p-3">
      <Card.Header className="text-center fs-3 fw-bold">TEAM NAME</Card.Header>
      <Card.Body>
        <Row className="d-flex flex-column">
          <Col className="py-3">
            <div className="py-3">
              Hey Coach, I can't remember your team name?
            </div>
            <Form.Control
              placeholder="e.g. Wellington Muppets"
              onChange={(e) => setTeamName(e.target.value)}
              value={teamName}
            />
            <div className="tiny-text">6 - 24 characters</div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Button
          disabled={teamName.length < 6 || teamName.length > 24}
          onClick={handleUpdateTeamName}
        >
          Let's Go!
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default GameConfig;
