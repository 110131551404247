import React, { useState, useEffect } from "react";
import { useMatch } from "../Contexts/MatchContext";
import { Button, ButtonGroup } from "react-bootstrap";
import { MatchStates } from "../Constants/Constants";
import { IoMdOptions } from "react-icons/io";
import MatchOptionsModal from "../Modals/MatchOptionsModal";
import { applyModifier } from "../DataLists/CardEffectHandler";
import { LiaExchangeAltSolid } from "react-icons/lia";
import ChangeBowlerModal from "../Modals/ChangeBowlerModal";
import {
  getPressureModifierForRuns,
  getFormModifierForRuns,
  getFitnessLevelModifierForRuns,
} from "../Utils/PlayerModifiers.js";
import { useGame } from "../Contexts/GameContext.js";
import { GameStates } from "../Constants/Constants";
import EndOfMatchModal from "../Modals/EndOfMatchModal.js";
import { calculateDeliveryOutcome } from "../Engine/MatchEngine.js";

const PlayMatchButton = () => {
  const { state, dispatch, pauseMatch, resumeMatch } = useMatch();
  const { gameState, gameDispatch } = useGame();
  const [deliveryDelay, setDeliveryDelay] = useState(500);
  const [showMatchOptionsModal, setShowMatchOptionsModal] = useState(false);
  const [showChangeBowlerModal, setShowChangeBowlerModal] = useState(false);
  const [showEndOfMatchModal, setShowEndOfMatchModal] = useState(false);

  const { canProcessDelivery } = state.matchInfo;

  const handleShowMatchOptionsModal = () => {
    pauseMatch();

    setShowMatchOptionsModal(true);
  };
  const handleCloseMatchOptionsModal = () => setShowMatchOptionsModal(false);

  const handleShowEndOfMatchModal = () => {
    setShowEndOfMatchModal(true);
  };

  const handleCloseEndOfMatchModal = () => setShowEndOfMatchModal(false);

  const handleShowChangeBowlerModal = () => {
    pauseMatch();

    setShowChangeBowlerModal(true);
  };
  const handleCloseChangeBowlerModal = () => setShowChangeBowlerModal(false);

  let updatedMatchStats;

  const {
    battingTeam,
    bowlingTeam,
    teamInningsIndex,
    onStrikeBatsmanIndex,
    matchState,
    maximumLegalDeliveries,
    currentInnings,
    inningsInMatch,
    currentBowlerIndex,
    otherBowlerIndex,
  } = state.matchInfo;

  const [finalMatchStats, setFinalMatchStats] = useState();

  const battingTeamData = state.matchInfo.teams[battingTeam];
  const bowlingTeamData = state.matchInfo.teams[bowlingTeam];

  const handleDelivery = () => {
    if (matchState === MatchStates.PAUSED) {
      return;
    }

    const hasDeliveriesRemaining =
      battingTeamData.innings[teamInningsIndex].legalDeliveries <
      maximumLegalDeliveries;
    const hasWicketsRemaining =
      battingTeamData.innings[teamInningsIndex].wickets < 10;
    const matchIsInProgress = matchState === MatchStates.INNINGS_IN_PROGRESS;

    if (
      hasDeliveriesRemaining &&
      canProcessDelivery &&
      hasWicketsRemaining &&
      matchIsInProgress
    ) {
      // This check determines if we are in an innings and should bowl another delivery
      const currentBatsman = battingTeamData.players[onStrikeBatsmanIndex];
      const currentBowler = bowlingTeamData.players[currentBowlerIndex];

      const { modifiedDeliveryOutcome, modifiers, updatedTeamModifiers } =
        calculateDeliveryOutcome(
          currentBatsman,
          currentBowler,
          battingTeam,
          state,
          applyModifier
        );

      dispatch({
        type: "PROCESS_DELIVERY",
        batsmanId: currentBatsman.id,
        bowlerId: currentBowler.id,
        runs: modifiedDeliveryOutcome,
        modifiers: modifiers,
        updatedTeamModifiers: updatedTeamModifiers,
      });

      if (
        modifiedDeliveryOutcome !== "wicket" ||
        !state.matchSettings.showFallOfWicketDialog.isChecked
      ) {
        dispatch({ type: "PROCESS_END_OF_DELIVERY" });
        dispatch({ type: "CHECK_MATCH_STATUS" });
      }

      dispatch({
        type: "UPDATE_MATCH_FORM",
        playerId: currentBatsman.id,
        pressureAdjustment: getPressureModifierForRuns(modifiedDeliveryOutcome),
        formAdjustment: getFormModifierForRuns(
          modifiedDeliveryOutcome,
          currentBatsman.currentMatch[teamInningsIndex].runs
        ),
        fitnessLevelAdjustment: getFitnessLevelModifierForRuns(
          modifiedDeliveryOutcome
        ),
      });
    } else if (
      !canProcessDelivery ||
      !hasDeliveriesRemaining ||
      !hasWicketsRemaining
    ) {
      // This check determines if we are at the end of the match
      if (currentInnings === inningsInMatch - 1) {
        dispatch({
          type: "UPDATE_MATCH_STATE",
          newMatchState: MatchStates.END_OF_MATCH,
          canProcessDelivery: false,
        });
      }

      dispatch({ type: "CHECK_MATCH_STATUS" });
    } else {
      console.log("END OF MATCH OCCURRED");
    }
  };

  const handleSpeedChange = () => {
    if (deliveryDelay === 125) {
      setDeliveryDelay(500);
      return;
    }

    if (deliveryDelay === 250) {
      setDeliveryDelay(125);
      return;
    }

    if (deliveryDelay === 500) {
      setDeliveryDelay(250);
      return;
    }
  };

  const handlePlay = () => {
    switch (matchState) {
      case MatchStates.INNINGS_IN_PROGRESS:
        pauseMatch();
        break;
      case MatchStates.PAUSED:
        resumeMatch();
        break;
      case MatchStates.READY_TO_START:
        dispatch({
          type: "UPDATE_MATCH_STATE",
          newMatchState: MatchStates.INNINGS_IN_PROGRESS,
          canProcessDelivery: true,
        });
        resumeMatch();
        break;
      case MatchStates.AWAITING_NEXT_MATCH:
        dispatch({
          type: "UPDATE_MATCH_STATE",
          newMatchState: MatchStates.INNINGS_IN_PROGRESS,
          canProcessDelivery: true,
        });
        resumeMatch();
        break;
      case MatchStates.END_OF_INNINGS:
        if (currentInnings === inningsInMatch - 1) {
          dispatch({
            type: "UPDATE_MATCH_STATE",
            newMatchState: MatchStates.READY_TO_START,
            canProcessDelivery: false,
          });
        } else {
          dispatch({
            type: "UPDATE_MATCH_STATE",
            newMatchState: MatchStates.END_OF_MATCH,
          });
        }
        break;
      case MatchStates.END_OF_MATCH:
        updatedMatchStats = state.matchInfo.teams["teamA"].players.map(
          (matchPlayer) => {
            let currentPlayer = gameState.squad.find(
              (gamePlayer) => matchPlayer.id === gamePlayer.id
            );

            if (!currentPlayer) {
              // Possibly clone matchPlayer if modifications are needed
              return { ...matchPlayer };
            }

            const { career, currentMatch } = matchPlayer;

            // Creating a new object to avoid direct mutation
            return {
              ...currentPlayer,
              currentMatch: [...currentMatch],
              career: {
                ...career,
                matches: career.matches + 1,
                innings:
                  matchPlayer.currentMatch[0].ballsFaced > 0
                    ? career.innings + 1
                    : career.innings,
                notOuts:
                  matchPlayer.currentMatch[0].ballsFaced > 0 &&
                  matchPlayer.currentMatch[0].howOut.length === 0
                    ? (career.notOuts += 1)
                    : career.notOuts,
                runs: career.runs + matchPlayer.currentMatch[0].runs,
                ballsFaced:
                  career.ballsFaced + matchPlayer.currentMatch[0].ballsFaced,
                fours: career.fours + matchPlayer.currentMatch[0].fours,
                sixes: career.sixes + matchPlayer.currentMatch[0].sixes,
                fifties:
                  matchPlayer.currentMatch[0].runs < 100 &&
                  matchPlayer.currentMatch[0].runs >= 50
                    ? (career.fifties += 1)
                    : career.fifties,
                hundreds:
                  matchPlayer.currentMatch[0].runs >= 100
                    ? (career.hundreds += 1)
                    : career.hundreds,
                highScore:
                  matchPlayer.currentMatch[0].runs > career.highScore
                    ? matchPlayer.currentMatch[0].runs
                    : career.highScore,
                overs:
                  matchPlayer.currentMatch[0].overs > 0
                    ? career.overs + matchPlayer.currentMatch[0].overs
                    : career.overs,
                maidens:
                  matchPlayer.currentMatch[0].maidens > 0
                    ? career.maidens + matchPlayer.currentMatch[0].maidens
                    : career.maidens,
                runsAgainst:
                  career.runsAgainst + matchPlayer.currentMatch[0].runsAgainst,
                wickets: career.wickets + matchPlayer.currentMatch[0].wickets,
                fiveWicketBags:
                  matchPlayer.currentMatch[0].wickets >= 5
                    ? career.fiveWicketBags + 1
                    : career.fiveWicketBags,
                tenWicketBags:
                  matchPlayer.currentMatch[0].wickets >= 10
                    ? career.tenWicketBags + 1
                    : career.tenWicketBags,
              },
            };
          }
        );

        setFinalMatchStats(updatedMatchStats);

        setShowEndOfMatchModal(true);
        break;
      case MatchStates.MATCH_COMPLETED:
        gameDispatch({
          type: "UPDATE_PLAYER_STATS",
          updatedPlayerStats: finalMatchStats,
        });

        dispatch({
          type: "START_NEW_MATCH",
          canProcessDelivery: false,
          players: finalMatchStats,
        });

        gameDispatch({
          type: "UPDATE_GAME_STATE",
          newGameState: GameStates.IN_MENU,
          squad: finalMatchStats,
        });

        break;
      default:
        // Handle other states or errors
        console.debug("Unhandled match state:", matchState);
        pauseMatch();
    }
  };

  useEffect(() => {
    let timeout;
    if (canProcessDelivery) {
      timeout = setTimeout(handleDelivery, deliveryDelay);
    }
    return () => clearTimeout(timeout);
  }, [
    matchState,
    canProcessDelivery,
    battingTeamData.players,
    deliveryDelay,
    state.matchSettings,
  ]);

  return (
    <>
      <ButtonGroup className="w-100">
        <Button
          variant="success"
          onClick={handleShowMatchOptionsModal}
          className="match-options-button fs-4 bg-cricket-green border-0"
        >
          <IoMdOptions />
        </Button>
        {state.matchInfo.bowlingTeam === "teamA" && (
          <Button
            variant="warning"
            onClick={handleShowChangeBowlerModal}
            className="change-bowler-button fs-4"
          >
            <LiaExchangeAltSolid />
          </Button>
        )}
        <Button
          variant="primary"
          onClick={handlePlay}
          className={`play-match-button ${
            matchState === MatchStates.PAUSED ||
            matchState === MatchStates.END_OF_INNINGS ||
            matchState === MatchStates.END_OF_MATCH
              ? "bg-danger border-0"
              : ""
          }`}
        >
          {matchState === MatchStates.AWAITING_NEXT_MATCH
            ? "Start Match"
            : matchState === MatchStates.READY_TO_START
            ? "Start"
            : matchState === MatchStates.END_OF_INNINGS
            ? "Go to next innings"
            : matchState === MatchStates.END_OF_MATCH
            ? "End of match"
            : matchState === MatchStates.MATCH_COMPLETED
            ? "Home"
            : matchState === MatchStates.INNINGS_IN_PROGRESS
            ? "Pause"
            : matchState === MatchStates.PAUSED
            ? "Resume"
            : "Unknown Match State"}
        </Button>

        <Button
          variant="success"
          onClick={handleSpeedChange}
          className="speed-change-button fs-4"
        >
          {deliveryDelay === 125 ? ">>>" : deliveryDelay === 250 ? ">>" : ">"}
        </Button>
      </ButtonGroup>

      <MatchOptionsModal
        show={showMatchOptionsModal}
        handleClose={handleCloseMatchOptionsModal}
      />

      <ChangeBowlerModal
        show={showChangeBowlerModal}
        handleClose={handleCloseChangeBowlerModal}
      />

      <EndOfMatchModal
        key={"match-completed"}
        show={showEndOfMatchModal}
        handleClose={handleCloseEndOfMatchModal}
        finalMatchStats={finalMatchStats}
      />
    </>
  );
};

export default PlayMatchButton;
