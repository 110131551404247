// firebaseService.js
import { db, auth } from "./firebase-config"; // Ensure this path is correct
import {
  collection,
  getDoc,
  getDocs,
  doc,
  writeBatch,
  updateDoc,
  setDoc,
  addDoc,
  where,
  query,
} from "firebase/firestore";
import ToastService from "../../Utils/ToastService";
import { sendPasswordResetEmail } from "firebase/auth";

/* These functions are used for collecting the user data and combining it into a squad object */
const fetchUserData = async (userId) => {
  const userRef = doc(db, `users/${userId}`);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    console.error("No such user!");
    return null; // Or handle this scenario appropriately
  }

  const userData = userSnap.data();
  const userDetails = {
    money: userData.money,
    league: userData.league,
    teamName: userData.teamName,
    nextOpposition: userData.nextOpposition,
    matches: userData.matches || 0,
    wins: userData.wins || 0,
    losses: userData.losses || 0,
    draws: userData.draws || 0,
    ties: userData.ties || 0,
  };

  return userDetails;
};

// Fetch base player data
const fetchBasePlayerData = async () => {
  const basePlayerCollection = collection(db, "basePlayerDetail");
  const snapshot = await getDocs(basePlayerCollection);
  return snapshot.docs.map((doc) => ({
    docId: doc.id, // Preserve the Firebase document ID if needed
    ...doc.data(),
  }));
};

// Fetch player progress data
const fetchPlayerProgressData = async (userId) => {
  const progressCollection = collection(
    db,
    `users/${userId}/userPlayersProgress`
  );
  const snapshot = await getDocs(progressCollection);

  return snapshot.docs.map((doc) => ({
    docId: doc.id, // Preserve the Firebase document ID if needed
    ...doc.data(),
  }));
};

// Fetch the data from Firebase and combine it.
// TODO: Note sure I want to use the retry approach, probably better to have a setup complete field
const fetchAndCombineSquadData = async (userId, attempts = 0) => {
  const maxAttempts = 5;
  const delay = 3000; // Delay 3 seconds between retries

  const userData = await fetchUserData(userId);
  const basePlayers = await fetchBasePlayerData();
  const playerProgress = await fetchPlayerProgressData(userId);

  const combinedData = basePlayers.map((basePlayer) => {
    const dynamicData =
      playerProgress.find((p) => p.id === basePlayer.id) || {};
    return { ...basePlayer, ...dynamicData };
  });

  // Check if all basePlayers have corresponding playerProgress
  const isDataComplete = combinedData.every((player) =>
    player.hasOwnProperty("age")
  ); // replace 'progressSpecificProperty' with a key from playerProgress that should exist

  if (!isDataComplete && attempts < maxAttempts) {
    console.log(`Data not complete, retrying... Attempt ${attempts + 1}`);
    await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
    return fetchAndCombineSquadData(userId, attempts + 1);
  }

  if (!isDataComplete) {
    throw new Error("Data initialization is incomplete after maximum retries.");
  }

  return { combinedData, userData };
};

// Accept user details of money, league, and teaMName and udpates it
const updateUserMoney = async (userId, money) => {
  const userRef = doc(db, `users/${userId}`);

  try {
    // Update the money field directly
    await updateDoc(userRef, { money });
  } catch (error) {
    console.error("Error updating user money:", error);
    throw error; // Rethrow or handle as needed
  }
};

const updateGameResults = async (userId, results) => {
  const userRef = doc(db, `users/${userId}`);

  try {
    // Ensure results object contains the exact keys as in the Firestore document
    await updateDoc(userRef, {
      matches: results.matches,
      wins: results.wins,
      losses: results.losses,
      draws: results.draws,
      ties: results.ties,
    });
  } catch (error) {
    console.error("Error updating game results:", error);
    throw error; // Rethrow or handle as needed
  }
};

const updateNextOpposition = async (userId, nextOpposition) => {
  const userRef = doc(db, `users/${userId}`);

  try {
    // Ensure results object contains the exact keys as in the Firestore document
    await updateDoc(userRef, {
      nextOpposition: nextOpposition,
    });
  } catch (error) {
    console.error("Error updating next opposition:", error);
    throw error; // Rethrow or handle as needed
  }
};

const updateTeamName = async (userId, teamName) => {
  const userRef = doc(db, `users/${userId}`);

  try {
    // Ensure results object contains the exact keys as in the Firestore document
    await updateDoc(userRef, {
      teamName: teamName,
    });
  } catch (error) {
    console.error("Error updating team name:", error);
    throw error; // Rethrow or handle as needed
  }
};

// Updates the player selection value
const updatePlayerSelection = async (userId, playerFieldId, selected) => {
  try {
    const progressCollectionPath = `users/${userId}/userPlayersProgress`;
    const q = query(
      collection(db, progressCollectionPath),
      where("id", "==", playerFieldId)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error(`No player found with id: ${playerFieldId}`);
      return;
    }

    // Assuming there is only one document per playerFieldId
    const playerDoc = querySnapshot.docs[0];
    const playerRef = playerDoc.ref;

    console.log("playerRef path:", playerRef.path);

    // Update the selected field of the player document
    await updateDoc(playerRef, { selected });

    console.log(`Player ${playerFieldId} selection updated to ${selected}`);
  } catch (error) {
    console.error(`Error updating player ${playerFieldId} selection:`, error);
  }
};

// Accepts a list of players and updates their progress
const updatePlayerProgressBatch = async (userId, players) => {
  const batch = writeBatch(db); // Creating a batch instance
  const progressCollectionPath = `users/${userId}/userPlayersProgress`;

  players.forEach((player) => {
    const playerRef = doc(db, progressCollectionPath, player.docId); // Assumes docId is stored in player object

    const updatedData = {
      level: player.level,
      enabled: player.enabled,
      selected: player.selected,
      age: player.age,
      battingSkill: player.battingSkill,
      bowlingSkill: player.bowlingSkill,
      fieldingSkill: player.fieldingSkill,
      pressure: player.pressure,
      career: player.career,
      //currentMatch: player.currentMatch,
      injuryStatus: player.injuryStatus,
      form: player.form,
      fitnessLevel: player.fitnessLevel,
      modifiers: player.modifiers,
      battingThresholds: player.battingThresholds,
      bowlingThresholds: player.bowlingThresholds,
    };

    batch.update(playerRef, updatedData); // Adding the update operation to the batch
  });

  try {
    await batch.commit(); // Committing the batch
    console.log("All players updated successfully");
  } catch (error) {
    console.error("Error updating players:", error);
  }
};

/**
 * Submits feedback from a logged-in user to the 'feedback' collection in Firestore.
 * @param {string} feedbackText - The feedback text submitted by the user.
 */
const submitFeedback = async (feedbackText) => {
  if (!auth.currentUser) {
    console.error("User not logged in");
    return;
  }

  const feedbackDoc = doc(collection(db, "feedback")); // Creating a new document reference in the 'feedback' collection
  const feedbackData = {
    user: auth.currentUser.uid, // User ID from Firebase Authentication
    email: auth.currentUser.email,
    feedback: feedbackText,
    createdAt: new Date(), // Firestore will automatically convert this to a Timestamp
    responseRequired: true,
    responded: false, // default value at the time of creation
  };

  try {
    await setDoc(feedbackDoc, feedbackData);
    console.log("Feedback submitted successfully");
    ToastService.success("Feedback sent, GC!");
    return true;
  } catch (error) {
    console.error("Error submitting feedback:", error);
    ToastService.error("Oops, there was a problem sending your feedback.");
    return false;
  }
};

const logError = async (error, type, email) => {
  try {
    await addDoc(collection(db, "errors"), {
      type,
      email,
      errorCode: error.code,
      errorMessage: error.message,
      timestamp: new Date(),
    });
  } catch (loggingError) {
    console.error("Error logging error to Firestore:", loggingError);
  }
};

// Function to send a password reset email
export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true, message: "Password reset email sent." };
  } catch (error) {
    console.error("Error sending password reset email:", error);
    return { error: true, message: error.message };
  }
};

// Fetches a random next team to play
const fetchRandomOppositionTeam = async (currentUserId, currentLeague) => {
  try {
    // Fetch all users in the same league
    const usersQuery = query(
      collection(db, "users"),
      where("league", "==", currentLeague)
    );
    const usersSnapshot = await getDocs(usersQuery);
    const users = usersSnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter((user) => user.id !== currentUserId);

    if (users.length === 0) {
      console.error("No available users in the same league.");
      return null;
    }

    // Select a random user
    const randomUser = users[Math.floor(Math.random() * users.length)];

    // Create a team object similar to the OppositionTeams structure
    const randomTeam = {
      teamId: randomUser.id, // Using user ID as team ID for uniqueness
      teamName: randomUser.teamName,
      profileImage: "australia_captain", // Assuming the user object has a profileImage field
    };

    return randomTeam;
  } catch (error) {
    console.error("Error fetching random opposition team:", error);
    return null;
  }
};

// Fetches the players from the next opposition
const fetchOppositionPlayers = async (currentUserId, currentLeague) => {
  try {
    // Fetch all users in the same league
    const usersQuery = query(
      collection(db, "users"),
      where("league", "==", currentLeague)
    );
    const usersSnapshot = await getDocs(usersQuery);
    const users = usersSnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter((user) => user.id !== currentUserId);

    if (users.length === 0) {
      console.error("No available users in the same league.");
      return null;
    }

    // Log the users fetched
    console.log("Filtered users in the same league:", users);

    // Select a random user
    const randomUserId = users[Math.floor(Math.random() * users.length)].id;

    // Fetch the base player details
    const basePlayersQuery = collection(db, "basePlayerDetail");
    const basePlayersSnapshot = await getDocs(basePlayersQuery);
    const basePlayers = basePlayersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch player progress data for the random user
    const playerProgressQuery = collection(
      db,
      `users/${randomUserId}/userPlayersProgress`
    );
    const playerProgressSnapshot = await getDocs(playerProgressQuery);
    const playerProgress = playerProgressSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Log the fetched data
    console.log("Base Players:", basePlayers);
    console.log("Player Progress:", playerProgress);

    // Combine base details with progress
    const oppositionTeam = basePlayers.map((player) => {
      const progress = playerProgress.find(
        (progress) => progress.id === player.id
      );
      return progress ? { ...player, ...progress } : player;
    });

    // Log the combined opposition team
    console.log("Opposition Team:", oppositionTeam);

    return oppositionTeam;
  } catch (error) {
    console.error("Error fetching random opposition team:", error);
    return null;
  }
};

export {
  fetchAndCombineSquadData,
  updatePlayerSelection,
  updatePlayerProgressBatch,
  updateUserMoney,
  submitFeedback,
  updateGameResults, // Updates matches, wins, losses etc
  updateTeamName,
  updateNextOpposition,
  logError,
  fetchRandomOppositionTeam,
  fetchOppositionPlayers,
};
