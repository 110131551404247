import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import SquadMembersComponent from "../../GameComponents/Shared/SquadMembersComponent.js";
import SelectedSquadMemberComponent from "../Shared/SelectedSquadMemberComponent.js";
import PlayerDescription from "../Squad/PlayerDescription.js";
import { useGame } from "../../../Contexts/GameContext.js";
import GameStatsHeader from "../Shared/GameStatsHeader.js";

const TrainingComponent = () => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { gameState, gameDispatch } = useGame();

  useEffect(() => {}, [selectedPlayer]);

  return (
    <div className="d-flex flex-column w-100 h-100 bg-main-image">
      <GameStatsHeader />
      <div className="flex-grow-1 d-flex justify-content-center align-items-center text-light m-3 position-relative">
        <div className="light-overlay"></div>
        <Card className="w-50 h-50 bg-dark text-light mb-5 shadow">
          <Card.Header className="text-center fs-3 bg-dark text-light fw-bold">
            TRAINING
          </Card.Header>
          <Card.Body className="d-flex flex-column justify-content-center align-items-center">
            <div>Train. It makes you better.</div>
            <div className="fs-2 fw-bold">COMING SOON</div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default TrainingComponent;
