import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { GameProvider } from "./Contexts/GameContext";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./Contexts/AuthContext";
import { LoadingProvider } from "./Contexts/LoadingContext";
import LoadingIndicator from "./Components/GameComponents/Shared/LoadingIndicator";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <LoadingProvider>
      <GameProvider>
        <LoadingIndicator />
        <App />
        <ToastContainer />
      </GameProvider>
    </LoadingProvider>
  </AuthProvider>
);
