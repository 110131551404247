import React, { useEffect, useState } from "react";
import DraggableCard from "./DraggableCard";
import PlayMatchButton from "./PlayMatchButton";
import CurrentBatsmanComponent from "./CurrentBatsmanComponent";
import { useMatch } from "../Contexts/MatchContext";
import cardConfig from "../DataLists/CardConfig";
import { Row, Col, Card } from "react-bootstrap";
import CurrentBowlerComponent from "./CurrentBowlerComponent.";

const MatchOptionsComponent = () => {
  const { state } = useMatch();

  const currentTeam = state?.matchInfo?.teams[state.matchInfo.battingTeam];
  const playersTeam = state?.matchInfo?.teams["teamA"];
  const currentBatsman =
    playersTeam?.players[state.matchInfo.firstBatsmanIndex];
  const otherBatsman = playersTeam?.players[state.matchInfo.secondBatsmanIndex];
  const currentBowler = playersTeam?.players[state.matchInfo.firstBowlerIndex];
  const otherBowler = playersTeam?.players[state.matchInfo.secondBowlerIndex];

  const [deck, setDeck] = useState([]);
  const [playedCards, setPlayedCards] = useState([]);

  const handleCardPlayed = (cardId) => {
    setPlayedCards((prev) => [...prev, cardId]);
  };

  useEffect(() => {
    // Initialize the deck with 4 randomly selected cards
    const initialDeck = getRandomCards(4);
    setDeck(initialDeck);
    setPlayedCards([]);
  }, [state.matchInfo.currentInnings]);

  // Check for cards in cooldown and replace them after each delivery
  useEffect(() => {
    const checkAndReplaceCards = () => {
      setDeck((prevDeck) => {
        let replacementsNeeded = []; // Store indices for replacement

        const newDeck = prevDeck.map((card, index) => {
          const isActive = state.matchInfo.teamModifiers.some(
            (modifier) => modifier.effect.type === card.effect.type
          );

          // Check if the card was played, its cooldown has expired and it's no longer active
          if (playedCards.includes(card.id) && !isActive) {
            replacementsNeeded.push(index);
            return card; // Temporarily return the card itself
          }

          return card;
        });

        // Replace the necessary cards
        replacementsNeeded.forEach((index) => {
          const excludeIds = newDeck.map((c) => c.id); // Ensure no duplicates
          const [newCard] = getRandomCards(1, excludeIds);
          if (newCard) {
            newDeck[index] = newCard; // Replace with new card
            setPlayedCards((current) =>
              current.filter((id) => id !== newDeck[index].id)
            ); // Remove from playedCards
          }
        });

        return newDeck;
      });
    };

    checkAndReplaceCards();
  }, [state.matchInfo.deliveries, state.matchInfo.teamModifiers]); // Dependencies to re-run effect

  const getRandomCards = (count, excludeIds = []) => {
    const availableCards = cardConfig.filter(
      (card) =>
        !excludeIds.includes(card.id) &&
        (state.matchInfo.battingTeam === "teamA"
          ? card.validFor === "BATSMAN"
          : card.validFor === "BOWLER")
    );

    const randomCards = [];
    for (let i = 0; i < count && availableCards.length > 0; i++) {
      const randomIndex = Math.floor(Math.random() * availableCards.length);
      randomCards.push(availableCards.splice(randomIndex, 1)[0]);
    }

    return randomCards;
  };

  return (
    <Row className="d-flex flex-column justify-content-start align-items-between flex-grow-1 h-100 user-select-none">
      {state.matchInfo.battingTeam === "teamA" ? (
        <Col className="mb-3">
          <div className="text-light fw-bold mb-1">Batsmen</div>
          <Card className="bg-cricket-green mb-3 d-flex overflow-hidden">
            <CurrentBatsmanComponent
              currentPlayer={currentBatsman}
              handleCardPlayed={handleCardPlayed}
            />
          </Card>

          <Card className="bg-cricket-green overflow-hidden">
            <CurrentBatsmanComponent
              currentPlayer={otherBatsman}
              handleCardPlayed={handleCardPlayed}
            />
          </Card>
        </Col>
      ) : (
        <Col className="mb-3">
          <div className="text-light fw-bold mb-1">Bowlers</div>

          <Card className="bg-cricket-green mb-3 overflow-hidden">
            <CurrentBowlerComponent
              currentPlayer={currentBowler}
              handleCardPlayed={handleCardPlayed}
            />
          </Card>

          <Card className="bg-cricket-green overflow-hidden">
            <CurrentBowlerComponent
              currentPlayer={otherBowler}
              handleCardPlayed={handleCardPlayed}
            />
          </Card>
        </Col>
      )}

      {/* <Col className="mb-3">
        <div className="text-light fw-bold mb-1">Cards</div>
        <Card className="bg-cricket-green">
          <Row className="m-0 pb-1">
            {cardConfig.map((card) => {
              // Check if the current batting team is 'teamA'
              if (
                state.matchInfo.battingTeam === "teamA" &&
                card.validFor === "BATSMAN"
              ) {
                return (
                  <Col key={card.id} className="col-3 mt-2">
                    <DraggableCard
                      card={card}
                      teamModifiers={state.matchInfo.teamModifiers}
                    />
                  </Col>
                );
              }
              // Check for the other condition when the batting team is not 'teamA'
              else if (
                state.matchInfo.battingTeam !== "teamA" &&
                card.validFor === "BOWLER"
              ) {
                return (
                  <Col key={card.id} className="col-3 mt-2">
                    <DraggableCard
                      card={card}
                      teamModifiers={state.matchInfo.teamModifiers}
                    />
                  </Col>
                );
              }
              // Return null if none of the conditions are met
              return null;
            })}
          </Row>
        </Card>
      </Col> */}

      <Col className="mb-3">
        <div className="text-light fw-bold mb-1">Cards</div>
        <Card className="bg-cricket-green">
          <Row className="m-0 pb-1">
            {deck.map((card) => (
              <Col key={card.id} className="col-3 mt-2">
                <DraggableCard
                  card={card}
                  teamModifiers={state.matchInfo.teamModifiers}
                />
              </Col>
            ))}
          </Row>
        </Card>
      </Col>

      <Col className="d-flex align-items-end pb-3">
        <PlayMatchButton />
      </Col>
    </Row>
  );
};

export default MatchOptionsComponent;
