import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useGame } from "../Contexts/GameContext";
import useGameImages from "../Hooks/useGameImages";

const PurchaseGearModal = ({ show, handleClose, item }) => {
  const { gameState, gameDispatch, saveNewMoneyTotal } = useGame();
  const image = useGameImages();

  const handleConfirmPurchase = () => {
    const newTotalMoney = gameState.money - item.price;

    gameDispatch({
      type: "INCREASE_USER_MONEY",
      money: -item.price,
    });

    gameDispatch({
      type: "APPLY_GEAR_TO_USER",
      item: item,
    });

    saveNewMoneyTotal(newTotalMoney);

    handleClose();
  };

  useEffect(() => {}, [item]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="user-select-none "
      size="md"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark text-light border-1"
      >
        <Modal.Title className="text-light">{item && item.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark p-0 border-0">
        <div className="w-100 text-light text-center p-3 fs-5">
          {item.description}
        </div>
        <div>
          <img className="w-100" src={image[item.image]} alt={item.name} />
        </div>
        <div className="d-flex justify-content-around my-3 fw-bold text-light">
          <div>Duration: {item.duration}</div>
          <div>Price: ${item.price}</div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark d-flex justify-content-between">
        <div className="d-flex col-3">
          <Button className="w-100" variant="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
        <div className="d-flex col-3">
          <Button
            className="w-100"
            variant="success"
            onClick={handleConfirmPurchase}
          >
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PurchaseGearModal;
