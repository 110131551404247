// cardsConfig.js
const cardConfig = [
  {
    id: "releasePressureCard",
    validFor: "BATSMAN",
    text: "Pressure off",
    subtext: "Reduce pressure on batsman by 25",
    effect: { type: "REDUCE_PRESSURE", amount: -25 },
    cooldown: 45, // This value is decremented to determine removal / availability
    maxCooldown: 45, // used to calculate percentages in card overlay. Both values should be the same in initial state
    className: "",
    image: "card_pressure_off",
  },
  {
    id: "thatsNotOutCard",
    validFor: "BATSMAN",
    text: "That's not out",
    subtext: "Batsman is invincible for 2 overs",
    effect: { type: "INVINCIBILITY", duration: 12 },
    cooldown: 120,
    maxCooldown: 120,
    className: "cautious-card",
    backgroundColor: "cautious-mini-card",
    image: "card_not_out",
  },
  {
    id: "spankItCard",
    validFor: "BATSMAN",
    text: "Spank it",
    subtext: "More runs, but higher chance of getting out",
    effect: { type: "SPANK_IT", duration: 6 },
    cooldown: 120,
    maxCooldown: 120,
    className: "aggressive-card",
    backgroundColor: "aggressive-mini-card",
    image: "card_spank_it",
  },
  {
    id: "quickSinglesCard",
    validFor: "BATSMAN",
    text: "Quick singles",
    subtext: "50% of dots become singles for 2 overs",
    effect: { type: "QUICK_SINGLES", duration: 12 },
    cooldown: 90,
    maxCooldown: 90,
    className: "consolidate-card",
    backgroundColor: "consolidate-mini-card",
    image: "card_quick_singles",
  },
  {
    id: "slipperyBallsCard",
    validFor: "BATSMAN",
    text: "Slippery when wet",
    subtext: "75% of wickets get fumbled for 2 overs",
    effect: { type: "SLIPPERY_BALLS", duration: 12 },
    cooldown: 220,
    maxCooldown: 220,
    className: "cautious-card",
    backgroundColor: "cautious-mini-card",
    image: "card_slippery_balls",
  },
  {
    id: "boundryTimeCard",
    validFor: "BATSMAN",
    text: "Boundry time",
    subtext: "More boundries but more risk for 1 over",
    effect: { type: "BOUNDRY_TIME", duration: 6 },
    cooldown: 175,
    maxCooldown: 175,
    className: "aggressive-card",
    backgroundColor: "aggressive-mini-card",
    image: "card_boundry_time",
  },
  {
    id: "donBradmanCard",
    validFor: "BATSMAN",
    text: "I'm Don Bradman",
    subtext: "Highly unlikely to get out for 2 overs",
    effect: { type: "DON_BRADMAN", duration: 12 },
    cooldown: 120,
    maxCooldown: 120,
    className: "cautious-card",
    backgroundColor: "cautious-mini-card",
    image: "card_im_don_bradman",
  },
  {
    id: "needARunnerCard",
    validFor: "BATSMAN",
    text: "Call in a runner",
    subtext: "Usain Bolt will run for 2 overs",
    effect: { type: "NEED_A_RUNNER", duration: 12 },
    cooldown: 110,
    maxCooldown: 110,
    className: "consolidate-card",
    backgroundColor: "consolidate-mini-card",
    image: "card_call_in_a_runner",
  },
  {
    id: "pickTheSeamCard",
    validFor: "BOWLER",
    text: "Pick the seam",
    subtext: "Ball reverse swings for 1 over",
    effect: { type: "PICK_THE_SEAM", duration: 6 },
    cooldown: 120,
    maxCooldown: 120,
    className: "aggressive-card",
    backgroundColor: "aggressive-mini-card",
    image: "card_pick_the_seam",
  },
  {
    id: "sandpaperCard",
    validFor: "BOWLER",
    text: "Got any sandpaper Dave?",
    subtext: "The ball becomes almost unplayable for 1 over",
    effect: { type: "SANDPAPER", duration: 6 },
    cooldown: 140,
    maxCooldown: 140,
    className: "aggressive-card",
    backgroundColor: "aggressive-mini-card",
    image: "card_sandpaper",
  },
  {
    id: "gavinLarsenCard",
    validFor: "BOWLER",
    text: "Mr. Gavin Larsen",
    subtext: "Runs are hard to come by for 2 overs",
    effect: { type: "GAVIN_LARSEN", duration: 12 },
    cooldown: 150,
    maxCooldown: 150,
    className: "cautious-card",
    backgroundColor: "cautious-mini-card",
    image: "card_gavin_larsen",
  },
  {
    id: "underarmCard",
    validFor: "BOWLER",
    text: "Chappelli",
    subtext: "It's legal to roll it right?",
    effect: { type: "UNDERARM", duration: 6 },
    cooldown: 120,
    maxCooldown: 120,
    className: "cautious-card",
    backgroundColor: "cautious-mini-card",
    image: "card_chapelli",
  },
  {
    id: "rawalpindiCard",
    validFor: "BOWLER",
    text: "The Rawalpindi Express",
    subtext: "Buy a wicket, 50% chance, but lose your match fee",
    effect: { type: "RAWALPINDI", duration: 1 },
    cooldown: 300,
    maxCooldown: 300,
    className: "aggressive-card",
    backgroundColor: "aggressive-mini-card",
    image: "card_rawalpindi",
  },
  {
    id: "bodylineCard",
    validFor: "BOWLER",
    text: "Bodyline",
    subtext: "Increase the pressure",
    effect: { type: "BODYLINE", duration: 6 },
    cooldown: 140,
    maxCooldown: 140,
    className: "consolidate-card",
    backgroundColor: "consolidate-mini-card",
    image: "card_bodyline",
  },
  {
    id: "ballOfTheCenturyCard",
    validFor: "BOWLER",
    text: "Ball of the century",
    subtext: "75% chance of a wicket next ball",
    effect: { type: "BALL_OF_THE_CENTURY", duration: 1 },
    cooldown: 300,
    maxCooldown: 300,
    className: "aggressive-card",
    backgroundColor: "aggressive-mini-card",
    image: "card_ball_of_the_century",
  },
  {
    id: "spotFixingCard",
    validFor: "BOWLER",
    text: "Spot fixing",
    subtext: "Throw in a few no balls and get paid for them",
    effect: { type: "SPOT_FIXING", duration: 12 },
    cooldown: 125,
    maxCooldown: 125,
    className: "consolidate-card",
    backgroundColor: "consolidate-mini-card",
    image: "card_spot_fixing",
  },
];

export default cardConfig;
