// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut as firebaseSignOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore

const firebaseConfig = {
  apiKey: "AIzaSyDD9haAOuMnqIi5v-GuDMSgNZWRGbsPhDk",
  authDomain: "cricket-ed3bf.firebaseapp.com",
  databaseURL:
    "https://cricket-ed3bf-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cricket-ed3bf",
  storageBucket: "cricket-ed3bf.appspot.com",
  messagingSenderId: "794650261140",
  appId: "1:794650261140:web:bd66904eb6cbe0c0492681",
  measurementId: "G-XJG2RYV2TQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); // Initialize Firebase Analytics
const auth = getAuth(app); // Initialize Firebase Authentication and get a reference to the service
const db = getFirestore(app); // Initialize Firestore

export { auth, firebaseSignOut, db, analytics };
//export { auth, firebaseSignOut, db };
