import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useMatch } from "../Contexts/MatchContext";

const MatchOptionsModal = ({ show, handleClose }) => {
  const { state, dispatch } = useMatch();
  const [matchSettings, setMatchSettings] = useState();

  // Handle change in checkbox state
  const handleSettingsChange = (id) => {
    const updatedSettings = {
      ...matchSettings,
      [id]: {
        ...matchSettings[id],
        isChecked: !matchSettings[id].isChecked,
      },
    };
    setMatchSettings(updatedSettings);
    dispatch({
      type: "UPDATE_MATCH_SETTINGS",
      matchSettings: updatedSettings,
    });
  };

  useEffect(() => {
    setMatchSettings(state.matchSettings);
  }, [state.matchSettings]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="user-select-none "
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark text-light"
      >
        <Modal.Title>Match Options</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-light">
        {/**
         * Pause on wicket
         * Pause at end of over
         * Pause every ball
         * Show fall of wicket dialog
         */}

        <Form>
          {matchSettings &&
            Object.entries(matchSettings).map(([id, setting]) => (
              <Form.Group key={id} className="m-3">
                <Form.Check
                  type="checkbox"
                  id={`checkbox-${id}`}
                  label={setting.label}
                  checked={setting.isChecked}
                  onChange={() => handleSettingsChange(id)}
                  className="fs-6 custom-checkbox py-1"
                />
              </Form.Group>
            ))}
        </Form>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="outline-light" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MatchOptionsModal;
