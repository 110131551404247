import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import useGameImages from "../../../Hooks/useGameImages";
import "../../../Styles/Gear.scss";
import { useGame } from "../../../Contexts/GameContext";
import PurchaseGearModal from "../../../Modals/PurchaseGearModal";

const GearItemComponent = ({ item }) => {
  const image = useGameImages();
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  const closePurchaseModal = () => setShowPurchaseModal(false);

  const { gameState, gameDispatch } = useGame();

  return (
    <>
      <Card className={`gear-item-card h-100 pt-3 ${item.class}`}>
        <Card.Header className="text-center fw-bold">{item.name}</Card.Header>
        <Card.Body className="bg-dark text-light h-100 p-0">
          <div className="d-flex flex-column text-center bg-dark text-light">
            <div className="overflow-hidden">
              <img
                className="gear-image"
                src={image[item.image]}
                alt={item.name}
              />
            </div>
            <div className="px-3 py-3">{item.description}</div>
          </div>
        </Card.Body>
        <Card.Footer className="pt-3 d-flex justify-content-between">
          <div>{item.duration}</div>
          <Button
            variant="outline-light"
            size="sm"
            disabled={gameState.money < item.price}
            onClick={() => setShowPurchaseModal(true)}
          >
            ${item.price.toLocaleString()}
          </Button>
        </Card.Footer>
      </Card>

      <PurchaseGearModal
        show={showPurchaseModal}
        handleClose={closePurchaseModal}
        item={item}
      />
    </>
  );
};

export default GearItemComponent;
