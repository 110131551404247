import React from "react";
import { Col, Row } from "react-bootstrap";
import { GearConfig } from "../../../DataLists/GearConfig";
import GearItemComponent from "./GearItemComponent";

const GearShopComponent = () => {
  return (
    <div className="w-100 h-100 ">
      <div className="text-dark p-3 rounded h-100 position-relative">
        <div className="light-overlay"></div>
        <Row className="d-flex flex-column h-100 overflow-y-auto">
          <Col className="d-flex flex-column flex-grow-1">
            <Row>
              {GearConfig.filter((item) => item.enabled).map((item) => (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                  key={item.id}
                  className="mb-3 p-3"
                >
                  <GearItemComponent item={item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GearShopComponent;
