import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ProgressBar } from "react-bootstrap";
import { useMatch } from "../../Contexts/MatchContext";

const AggressionSelector = ({
  title,
  currentPlayer,
  updatePlayerAggression,
}) => {
  const { state } = useMatch();
  const [aggression, setAggression] = useState(); // Initial aggression level
  const maxAggression = 100; // Define the maximum level of aggression

  const handleDecrease = () => {
    const newAggression = Math.max(aggression - 10, 0);

    setAggression(newAggression);
    updatePlayerAggression(newAggression);
  };

  const handleIncrease = () => {
    const newAggression = Math.min(aggression + 10, maxAggression);

    setAggression(newAggression);
    updatePlayerAggression(newAggression);
  };

  useEffect(() => {
    if (currentPlayer) {
      if (state.matchInfo.battingTeam === "teamA") {
        setAggression(currentPlayer.battingAggression);
      } else {
        setAggression(currentPlayer.bowlingAggression);
      }
    }
  }, [currentPlayer, state.matchInfo.battingTeam]);

  return (
    <div>
      <div className="mb-1 fs-7 fw-bold">{title}</div>
      <ButtonGroup>
        <Button
          variant="primary"
          className="small-btn"
          onClick={handleDecrease}
        >
          -
        </Button>
        <div className="w-100">
          <ProgressBar
            now={aggression}
            max={maxAggression}
            variant={`${
              aggression < 65
                ? "success"
                : aggression < 85
                ? "warning"
                : "danger"
            }`}
            className="aggression-bar square"
          />
        </div>
        <Button
          variant="primary"
          className="small-btn"
          onClick={handleIncrease}
        >
          +
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default AggressionSelector;
