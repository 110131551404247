import { clamp } from "../Utils/Utils";

// Universal modifier application function
export const applyModifierToPlayer = (player, action) => {
  // Return the updated player object with new modifiers
  return {
    ...player,
    modifiers: [
      ...player.modifiers,
      {
        type: action.effect.type,
        duration: action.effect.duration,
        backgroundColor: action.backgroundColor,
        image: action.image,
      },
    ],
  };
};

export const applyPressureAdjustment = (player, card) => {
  return {
    ...player,
    pressure: clamp(player.pressure + card.effect.amount, 0, 100),
  };
};

// Utility functions for decrementing modifier duration
export const decrementModifiersDuration = (modifier) => {
  if (modifier.duration > 0) {
    return { ...modifier, duration: modifier.duration - 1 };
  }
  return modifier;
};

export const decrementTeamModifier = (modifier) => {
  if (modifier.cooldown > 0) {
    return { ...modifier, cooldown: modifier.cooldown - 1 };
  }
  return modifier;
};

export const applyModifier = (
  batter,
  bowler,
  modifiers,
  runs,
  teamModifiers
) => {
  let modifiedDeliveryOutcome = runs;

  //const batterPerformanceScore = batter.skill;
  const batterPerformanceScore =
    batter.form + batter.battingSkill + batter.fitnessLevel - batter.pressure;
  const bowlerPerformanceScore =
    bowler.form + bowler.bowlingSkill + bowler.fitnessLevel - bowler.pressure;
  //const bowlerPerformanceScore = bowler.skill;
  const sensitivityFactor = 1;

  // Batting
  let hasInvincibility = false;
  let hasQuickSingles = false;
  let hasSpankIt = false;
  let hasSlipperyBalls = false;
  let hasBoundryTime = false;
  let hasDonBradman = false;
  let hasNeedARunner = false;

  // Bowling
  let hasPickTheSeam = false;
  let hasSandpaper = false;
  let hasGavinLarsen = false;
  let hasUnderarm = false;
  let hasBodyline = false;
  let hasRawalpindi = false;
  let hasBallOfTheCentury = false;
  let hasSpotFixing = false;

  // First pass: check for presence of modifiers
  for (const modifier of modifiers) {
    if (modifier.type === "INVINCIBILITY" && modifier.duration > 0) {
      hasInvincibility = true;
    }
    if (modifier.type === "QUICK_SINGLES" && modifier.duration > 0) {
      hasQuickSingles = true;
    }

    if (modifier.type === "SPANK_IT" && modifier.duration > 0) {
      hasSpankIt = true;
    }

    if (modifier.type === "SLIPPERY_BALLS" && modifier.duration > 0) {
      hasSlipperyBalls = true;
    }

    if (modifier.type === "DON_BRADMAN" && modifier.duration > 0) {
      hasDonBradman = true;
    }

    if (modifier.type === "NEED_A_RUNNER" && modifier.duration > 0) {
      hasNeedARunner = true;
    }

    if (modifier.type === "BOUNDRY_TIME" && modifier.duration > 0) {
      hasBoundryTime = true;
    }

    // Bowlers
    if (modifier.type === "PICK_THE_SEAM" && modifier.duration > 0) {
      hasPickTheSeam = true;
    }

    if (modifier.type === "SANDPAPER" && modifier.duration > 0) {
      hasSandpaper = true;
    }
    if (modifier.type === "GAVIN_LARSEN" && modifier.duration > 0) {
      hasGavinLarsen = true;
    }
    if (modifier.type === "UNDERARM" && modifier.duration > 0) {
      hasUnderarm = true;
    }

    if (modifier.type === "RAWALPINDI" && modifier.duration > 0) {
      hasRawalpindi = true;
    }
    if (modifier.type === "BODYLINE" && modifier.duration > 0) {
      hasBodyline = true;
    }
    if (modifier.type === "BALL_OF_THE_CENTURY" && modifier.duration > 0) {
      hasBallOfTheCentury = true;
    }
    if (modifier.type === "SPOT_FIXING" && modifier.duration > 0) {
      hasSpotFixing = true;
    }
  }

  let updatedTeamModifiers = teamModifiers
    .map((modifier) => {
      let updatedTeamModifier = decrementTeamModifier(modifier);
      return updatedTeamModifier;
    })
    .filter((modifier) => {
      return modifier.cooldown > 0;
    });

  // Second pass: decrement durations and apply logic based on the checks
  let updatedModifiers = modifiers
    .map((modifier) => {
      if (modifier.duration > 0) {
        let updatedModifier = decrementModifiersDuration(modifier);

        return updatedModifier;
      }
      return modifier;
    })
    .filter((modifier) => modifier.duration > 0);

  /**
   * Once we've detemrined what modifers the user has we walk through them in order and modify as required
   *
   */
  // BATTING MODIFIERS
  const getModifiedThreshold = (baseline) => {
    const modifiedThreshold = clamp(
      baseline +
        (batterPerformanceScore - bowlerPerformanceScore) * sensitivityFactor,
      5,
      95
    );

    return modifiedThreshold;
  };

  if (hasQuickSingles && modifiedDeliveryOutcome === 0) {
    const tempRandom = Math.random() * 100;
    const modifiedThreshold = getModifiedThreshold(75);

    if (tempRandom < modifiedThreshold) {
      modifiedDeliveryOutcome = 1;
    }
  }

  if (hasNeedARunner && modifiedDeliveryOutcome >= 1) {
    switch (modifiedDeliveryOutcome) {
      case 1:
        modifiedDeliveryOutcome = 2;
        break;
      case 2:
        modifiedDeliveryOutcome = 3;
        break;
      default:
        modifiedDeliveryOutcome = 1;
        break;
    }
  }

  if (hasSpankIt) {
    if (modifiedDeliveryOutcome >= 0) {
      const tempRandom = Math.random() * 100;
      const modifiedThreshold = getModifiedThreshold(95);

      if (modifiedDeliveryOutcome === 0 || modifiedDeliveryOutcome === 1) {
        // leave it as a 0 or one because we were giving too many runs
      } else if (tempRandom < modifiedThreshold - tempRandom) {
        modifiedDeliveryOutcome = 1;
      } else if (tempRandom < modifiedThreshold - tempRandom * 0.8) {
        modifiedDeliveryOutcome = 2;
      } else if (tempRandom < modifiedThreshold - tempRandom * 0.6) {
        modifiedDeliveryOutcome = 3;
      } else if (tempRandom < modifiedThreshold - tempRandom * 0.4) {
        modifiedDeliveryOutcome = 4;
      } else if (tempRandom < modifiedThreshold - tempRandom * 0.2) {
        modifiedDeliveryOutcome = 6;
      } else {
        modifiedDeliveryOutcome = "wicket";
      }
    } else {
      modifiedDeliveryOutcome = "wicket";
    }
  }

  if (hasBoundryTime) {
    // TODO: Not handling if this is already a 6, should probably ensure it stays a 6
    if (modifiedDeliveryOutcome >= 0) {
      const tempRandom = Math.random() * 100;
      const modifiedThreshold = getModifiedThreshold(95);

      if (modifiedDeliveryOutcome === 0 || modifiedDeliveryOutcome === 1) {
        // do nothing to reduce excessive scoring that was occurring.
      } else if (tempRandom < modifiedThreshold - tempRandom) {
        modifiedDeliveryOutcome = 3;
      } else if (tempRandom < modifiedThreshold - tempRandom * 0.5) {
        modifiedDeliveryOutcome = 4;
      } else if (tempRandom < modifiedThreshold - tempRandom * 0.3) {
        modifiedDeliveryOutcome = 6;
      }
    } else {
      modifiedDeliveryOutcome = "wicket";
    }
  }

  if (hasDonBradman && modifiedDeliveryOutcome === "wicket") {
    // 5% change of actually getting out if you got out on the original delivery, otherwise, its a single
    const tempRandom = Math.random() * 100;
    if (tempRandom < 95) {
      modifiedDeliveryOutcome = 1;
    }
  }

  if (hasSlipperyBalls && modifiedDeliveryOutcome === "wicket") {
    // TODO: need to only apply this to catches.
    const tempRandom = Math.random() * 100;
    if (tempRandom < 75) {
      modifiedDeliveryOutcome = 0;
    }
  }

  if (hasInvincibility && modifiedDeliveryOutcome === "wicket") {
    modifiedDeliveryOutcome = 0; // Set to 0 if invincibility and wicket
  }

  // BOWLING MODIFIERS
  if (hasPickTheSeam && runs === 0) {
    // 1/4 of dots become wickets
    const newDeliveryOutcome = Math.floor(Math.random() * 121);

    if (newDeliveryOutcome > 90) {
      modifiedDeliveryOutcome = "wicket";
    }
  }

  if (hasSandpaper && modifiedDeliveryOutcome <= 6) {
    // Runs scored are halved if runs are scored
    modifiedDeliveryOutcome = modifiedDeliveryOutcome / 2;
    modifiedDeliveryOutcome = modifiedDeliveryOutcome.toFixed(0);
  } else if (
    hasSandpaper &&
    (modifiedDeliveryOutcome === "wide" || modifiedDeliveryOutcome === "bye")
  ) {
    // Other deliveries become wickets otherwise.
    modifiedDeliveryOutcome = "wicket";
  }

  if (hasGavinLarsen && modifiedDeliveryOutcome <= 6) {
    // Runs scored are halved
    modifiedDeliveryOutcome = modifiedDeliveryOutcome / 3;
    modifiedDeliveryOutcome = modifiedDeliveryOutcome.toFixed(0);
  }

  if (hasUnderarm && modifiedDeliveryOutcome <= 4) {
    // Can't score off a roller
    modifiedDeliveryOutcome = 0;
  }

  if (hasRawalpindi) {
    const tempRandom = Math.random() * 120;
    if (tempRandom < 50) {
      modifiedDeliveryOutcome = "wicket";
    }
  }

  if (hasBallOfTheCentury) {
    const tempRandom = Math.random() * 100;
    if (tempRandom < 75) {
      modifiedDeliveryOutcome = "wicket";
    }
  }

  if (hasBodyline && modifiedDeliveryOutcome !== "wicket") {
    const newDeliveryOutcome = Math.random() * 100;

    if (newDeliveryOutcome > 90) {
      modifiedDeliveryOutcome = "wicket";
    }
  }

  if (hasSpotFixing) {
    const tempRandom = Math.random() * 100;

    if (modifiedDeliveryOutcome === 0 && tempRandom < 66) {
      modifiedDeliveryOutcome = "no ball";
    }
  }

  return {
    modifiedDeliveryOutcome: modifiedDeliveryOutcome,
    modifiers: updatedModifiers,
    updatedTeamModifiers: updatedTeamModifiers,
  };
};

// Function to select the appropriate effect handler
export const getEffectHandler = (effectType) => {
  const specificHandlers = {
    REDUCE_PRESSURE: applyPressureAdjustment,
    // Add other special cases here as needed
  };

  return specificHandlers[effectType] || applyModifierToPlayer;
};

export default getEffectHandler;
