export const getPressureModifierForRuns = (runs) => {
  if (runs === 0) {
    return 0.5;
  }

  if (runs >= 1 && runs <= 3) {
    return -1;
  }

  if (runs === 4 || runs === 6) {
    return -4;
  }

  return 0;
};

export const getFormModifierForRuns = (runs, currentScore) => {
  let scoreMultiplier = 1.5 + currentScore / 100;

  if (runs === 0) {
    return -0.5;
  }

  if (runs >= 1 && runs <= 3) {
    return 1 * scoreMultiplier;
  }

  if (runs === 4 || runs === 6) {
    return 2 * scoreMultiplier;
  }

  return 0;
};

export const getFitnessLevelModifierForRuns = (runs) => {
  if (runs === 0) {
    return 0.5;
  }

  if (runs === 1) {
    return -1;
  }

  if (runs === 2) {
    return -2;
  }

  if (runs === 3) {
    return -3;
  }

  if (runs === 4 || runs === 6) {
    return 1;
  }

  return 0;
};
