import { auth } from "./firebase-config.js";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { logError } from "./firebaseAPI.js";

// Sign up new users
export const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
  } catch (error) {
    await logError(error, "signup", email);

    if (error.message === "Firebase: Error (auth/invalid-email).")
      return "Invalid email address";

    return "Sign up error. Please try again.";
  }
};

// Sign in existing users
export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
  } catch (error) {
    await logError(error, "login", email);
    return "Login Error: Check your credentials and try again.";
  }
};
