import { clamp } from "./Utils";

export const convertDeliveriesToOversBowled = (deliveries) => {
  const overs = Math.floor(deliveries / 6); // Calculate the number of overs
  const balls = deliveries % 6; // Calculate the remaining balls

  return `${overs}.${balls}`;
};

export const getBattingTeamRunRate = (runs, deliveries) => {
  // Convert deliveries into overs, since 1 over = 6 deliveries
  if (deliveries === 0) {
    return 0; // Avoid division by zero
  }
  const overs = deliveries / 6;
  const runrate = clamp(runs / overs, 0, 1000);

  return runrate.toFixed(2);
};

export const getBatsmansStrikeRate = (runs, deliveries) => {
  if (deliveries === 0 || runs === 0) {
    return 0;
  }

  const strikerate = (runs / deliveries) * 100;

  return strikerate.toFixed(2);
};

export const getBowlersEconomyRate = (runs, deliveries) => {
  if (runs === 0 || deliveries === 0) {
    return 0;
  }

  const overs = deliveries / 6;
  const economyRate = clamp(runs / overs, 0, 1000);

  return economyRate.toFixed(2);
};

export const getRequiredRunRate = (
  currentScore,
  target,
  deliveriesRemaining
) => {
  const oversRemaining = deliveriesRemaining / 6;
  const runsNeeded = target + 1 - currentScore;
  const requiredRunRate = runsNeeded / oversRemaining;

  if (deliveriesRemaining === 0) {
    return runsNeeded;
  }

  return clamp(requiredRunRate.toFixed(2), 0, 1000); // Rounds the run rate to two decimal places.
};

export const getRecentDeliveryStyling = (delivery) => {
  switch (delivery) {
    case ".":
      // Neutral grey for minimal emphasis
      return { backgroundColor: "#d9d9d9", color: "black" };
    case "1":
    case "2":
    case "3":
      return { backgroundColor: "#004225", color: "white" }; // Forest Green
    case "4":
      return { backgroundColor: "#0000ff", color: "white" }; // Royal Blue
    case "6":
      // Bright magenta for maximum emphasis
      return { backgroundColor: "#ff00ff", color: "white" }; // Magenta
    case "x":
      // Red for danger
      return { backgroundColor: "#dc3545", color: "white" }; // Red
    case "w":
    case "b":
    case "lb":
      // Orange for warning
      return { backgroundColor: "#ffa500", color: "white" }; // Orange
    default:
      // Default case if none above match
      return { backgroundColor: "#f8f9fa", color: "black" };
  }
};

export const getHowOut = (bowlerId, bowlingTeam) => {
  const bowler = bowlingTeam.find((player) => player.id === bowlerId);
  const dismissalMethods = [
    { type: "Bowled", weight: 30 },
    { type: "Caught", weight: 45 },
    { type: "LBW", weight: 20 },
    { type: "Run Out", weight: 5 },
    {
      type: "Stumped",
      weight:
        bowler.bowl === "LS" ||
        bowler.bowl === "LM" ||
        bowler.bowl === "SLA" ||
        bowler.bowl === "OB"
          ? 5
          : 0,
    },
    { type: "Caught and Bowled", weight: 3 },
    { type: "Hit Wicket", weight: 0.1 },
  ];

  // Function to make a weighted choice about dismissal type
  function weightedChoice(items) {
    const total = items.reduce((acc, item) => acc + item.weight, 0);
    const threshold = Math.random() * total;
    let sum = 0;
    for (const item of items) {
      sum += item.weight;
      if (sum >= threshold) {
        return item.type;
      }
    }
    return null; // should not be reached
  }

  const dismissalType = weightedChoice(dismissalMethods);
  let involvedPlayer = null;

  if (["Caught", "Stumped", "Run Out"].includes(dismissalType)) {
    if (dismissalType === "Caught") {
      const chances = bowlingTeam.map((p) => (p.wicketKeeper ? 3 : 1));
      involvedPlayer = bowlingTeam[weightedIndex(chances)];
    } else if (dismissalType === "Stumped") {
      involvedPlayer = bowlingTeam.find((p) => p.wicketKeeper);
    } else if (dismissalType === "Run Out") {
      const fielders = bowlingTeam.filter((p) => !p.wicketKeeper);
      involvedPlayer = fielders[Math.floor(Math.random() * fielders.length)];
    }
  }

  if (dismissalType === "Caught and Bowled") {
    involvedPlayer = bowler;
  }

  function formatPlayerName(player) {
    return `${player.playerInitial}. ${player.lastName}`;
  }

  function weightedIndex(weights) {
    const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
    let random = Math.random() * totalWeight;
    for (let i = 0; i < weights.length; i++) {
      if ((random -= weights[i]) < 0) {
        return i;
      }
    }
    return -1; // should not happen
  }

  switch (dismissalType) {
    case "Caught":
      return `Caught ${formatPlayerName(
        involvedPlayer
      )} Bowled ${formatPlayerName(bowler)}`;
    case "Run Out":
      return `Run Out (${formatPlayerName(involvedPlayer)})`;
    case "Stumped":
      return `Stumped ${formatPlayerName(
        involvedPlayer
      )} Bowled ${formatPlayerName(bowler)}`;
    case "Caught and Bowled":
      return `Caught & Bowled ${formatPlayerName(bowler)}`;
    default:
      return `${dismissalType} ${formatPlayerName(bowler)}`;
  }
};

// TODO: Improve bowler logic
export const getNextBowler = (
  bowlingTeam,
  currentBowlerIndex,
  otherBowlerIndex,
  state
) => {
  const match = state.matchInfo;
  const oversInMatch = match.maximumLegalDeliveries / 6;
  const otherBowlersStats =
    bowlingTeam[otherBowlerIndex].currentMatch[match.teamInningsIndex];
  const maxOversPerBowler = Math.floor(oversInMatch / 5);

  // If the other bowler has bowled less than half their avaialble overs then we need to bowl them again
  if (otherBowlersStats.overs / 6 < maxOversPerBowler / 2) {
    return otherBowlerIndex;
  }

  // Get a list of the primary bowlers in the team, which have either opener or change as their bowlPosition
  let primaryBowlers = bowlingTeam.filter(
    (player, index) =>
      ["opener", "change"].includes(player.bowlPosition) &&
      index !== currentBowlerIndex
  );

  // Get a list of candidates that can bowl, these are the primary bowlers excluding the currentBowler and any bowlers who have bowled out
  let candidates = primaryBowlers.filter((player) => {
    const bowlersOversBowled = Math.floor(
      player.currentMatch[match.teamInningsIndex].overs / 6
    );
    return (
      player.id !== bowlingTeam[currentBowlerIndex].id &&
      player.id !== bowlingTeam[otherBowlerIndex].id &&
      bowlersOversBowled < maxOversPerBowler
    );
  });

  /**
   * Now we can check if each remaining bowler has bowled 5 overs, and if so, we can return the otherBowler
   */
  const allBowlersHaveBowledHalfOfTheirAvailableOvers = candidates.every(
    (player) => {
      return player.currentMatch[match.teamInningsIndex].overs / 6 >= 5;
    }
  );

  if (
    allBowlersHaveBowledHalfOfTheirAvailableOvers &&
    otherBowlersStats.overs / 6 < maxOversPerBowler
  ) {
    return otherBowlerIndex;
  }

  // If there are no available candidates with opener, or change bowlPosition, then add in the emergency bowlers.
  if (candidates.length === 0) {
    candidates = bowlingTeam.filter(
      (player) => player.bowlPosition === "emergency"
    );
  }

  // If candidates is still 0 then try force any bowler
  if (candidates.length === 0) {
    candidates = bowlingTeam;
  }

  // Sort the candidates by least overs if they are the same, otherwise, sort by bowlingSkill
  candidates.sort((a, b) => {
    const oversDiff =
      a.currentMatch[match.teamInningsIndex].overs -
      b.currentMatch[match.teamInningsIndex].overs;
    if (oversDiff !== 0) {
      return oversDiff;
    }
    return b.bowlingSkill - a.bowlingSkill;
  });

  let returnBowlerIndex = bowlingTeam.findIndex(
    (player) => player.id === candidates[0].id
  );

  return returnBowlerIndex;
};

export const getCurrentMatchState = (currentUser, matchInfo) => {
  let returnDescription = "";
  let returnWinningTeam = "";
  let winningTeamId = "";
  let matchResult = "";

  const homeTeam = matchInfo.teams.teamA;
  const awayTeam = matchInfo.teams.teamB;
  const teamInningsIndex = matchInfo.teamInningsIndex;

  const firstInningsScore = homeTeam.innings[teamInningsIndex].score;
  const secondInningsScore = awayTeam.innings[teamInningsIndex].score;
  const oversCompleted =
    matchInfo.maximumLegalDeliveries <=
    awayTeam.innings[teamInningsIndex].legalDeliveries;
  const chaseSuccessful = secondInningsScore > firstInningsScore;
  const runDifference = firstInningsScore - secondInningsScore;
  const secondInningsWicketsRemaining =
    10 - awayTeam.innings[teamInningsIndex].wickets;

  if (oversCompleted) {
    if (!chaseSuccessful) {
      returnDescription = `${homeTeam.name} won by ${runDifference} ${
        runDifference === 1 ? "run" : "runs"
      }`;
      returnWinningTeam = homeTeam.name;
      winningTeamId = currentUser.uid;
      matchResult = "Win";
    } else if (runDifference === 0) {
      returnDescription = `The matrch was a tie`;
      matchResult = "Tie";
    }
  } else if (chaseSuccessful) {
    returnDescription = `${
      awayTeam.name
    } won by ${secondInningsWicketsRemaining} ${
      secondInningsWicketsRemaining === 1 ? "wicket" : "wickets"
    }`;

    returnWinningTeam = awayTeam.name;
    matchResult = "Loss";
  } else if (secondInningsWicketsRemaining === 0) {
    returnDescription = `${homeTeam.name} won by ${runDifference} ${
      runDifference === 1 ? "run" : "runs"
    }`;
    returnWinningTeam = homeTeam.name;
    winningTeamId = currentUser.uid;
    matchResult = "Win";
  } else {
    returnDescription = `${awayTeam.name} need ${
      homeTeam.innings[teamInningsIndex].score +
      1 -
      awayTeam.innings[teamInningsIndex].score
    } runs from ${
      matchInfo.maximumLegalDeliveries -
      awayTeam.innings[teamInningsIndex].legalDeliveries
    } ${
      awayTeam.innings[teamInningsIndex].legalDeliveries === 1
        ? "delivery"
        : "deliveries"
    }`;
  }

  return {
    description: returnDescription,
    winningTeamName: returnWinningTeam,
    winningTeamId: winningTeamId,
    matchResult: matchResult,
  };
};

export const getMatchOutcome = (gameState, matchInfo, currentUser) => {
  const { matches, wins, losses, draws, ties } = gameState;
  let returnObject = {
    matches: matches,
    wins: wins,
    losses: losses,
    draws: draws,
    ties: ties,
  };
  const { matchResult } = getCurrentMatchState(currentUser, matchInfo);

  returnObject.matches += 1;

  switch (matchResult) {
    case "Win":
      returnObject.wins += 1;
      break;
    case "Loss":
      returnObject.losses += 1;
      break;
    case "Draw":
      returnObject.draws += 1;
      break;
    case "Tie":
      returnObject.ties += 1;
      break;
    default:
      break;
  }

  return returnObject;
};

export const getBattingAggression = (battingThresholds) => {
  const { four, six } = battingThresholds;
  const aggressionScore = four + six;

  return aggressionScore.toFixed(4); // Rounding to two decimal places
};

export const getBattingDefence = (battingThresholds) => {
  const { wicket } = battingThresholds;

  const defence = 0.1 - wicket;

  return defence.toFixed(4); // Rounding to two decimal places to make it more readable
};

export const getBattingAccumulation = (battingThresholds) => {
  const { one, two, three } = battingThresholds;

  const accumulation = one + two + three;

  return accumulation.toFixed(4);
};

export const getBowlingAggression = (bowlingThresholds) => {
  const { one, two, three, four, six, wicket, zero } = bowlingThresholds;

  // Assign a higher weight to wickets to emphasize their importance.
  // For example, you might consider each wicket as equivalent to scoring multiple runs.
  const wicketWeight = 3; // You can adjust this weight based on how much you want to emphasize wickets.

  // Calculate aggressive outcomes with weighted wickets.
  const aggressiveOutcomes = four + six + wicket * wicketWeight;

  // Total outcomes including zeros (defensive)
  const totalOutcomes = aggressiveOutcomes + one + two + three + zero;

  // Normalize aggression as a percentage of total possible outcomes
  const normalizedAggression = (aggressiveOutcomes / totalOutcomes) * 100;

  return normalizedAggression.toFixed(2); // Rounding to two decimal places
};

export const getBowlingDefence = (bowlingThresholds) => {
  const { zero } = bowlingThresholds;

  return zero;
};

export const getBowlingAccuracy = (bowlingThresholds) => {
  const { zero, wide, noBall } = bowlingThresholds;

  const accuracy = zero - wide * 10 - noBall * 10;

  return accuracy;
};

export const getBowlingWicketRating = (bowlingThresholds) => {
  const { wicket } = bowlingThresholds;

  return wicket.toFixed(2);
};
