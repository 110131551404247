import { useDrag } from "react-dnd";
import "../Styles/DraggableCards.scss";
import useGameImages from "../Hooks/useGameImages";
import { useEffect, useRef, useState } from "react";
import { useMatch } from "../Contexts/MatchContext";

const DraggableCard = ({ card, teamModifiers }) => {
  const image = useGameImages();
  const { isGamePaused, pauseMatch } = useMatch();

  const isCardEffectPresent =
    teamModifiers &&
    teamModifiers.some((modifier) => modifier.effect.type === card.effect.type);

  const activeModifier = teamModifiers.find(
    (modifier) => modifier.effect.type === card.effect.type
  );

  const [{ isDragging }, dragRef] = useDrag({
    type: "card",
    item: {
      id: card.id,
      effect: card.effect,
      cooldown: card.cooldown,
      image: card.image,
    },
    canDrag: !isCardEffectPresent,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const cardStyle = {
    opacity: isDragging ? 0.25 : 1,
    boxShadow: isDragging ? "0px 10px 15px rgba(0,0,0,1)" : "",
    backgroundImage: `url(${image[card.image]})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100px",
  };

  // Used to pause the game when a card is dragged.
  useEffect(() => {
    if (isDragging) {
      pauseMatch();
    }
  }, [isDragging, isGamePaused, pauseMatch]);

  return (
    <div
      ref={dragRef}
      style={cardStyle}
      className={`draggable-card overflow-hidden h-100 ${
        !isCardEffectPresent && "active"
      } ${card.className} ${
        activeModifier && activeModifier.cooldown <= 1 ? "" : "cooldown-expired"
      }`}
      title={card.subtext}
    >
      <div className="gradient-overlay d-flex align-items-end justify-content-center">
        <div className="text">{card.text}</div>
      </div>
      {card.cooldown > 0 && (
        <div
          className="card-overlay"
          style={{
            width: `${(activeModifier?.cooldown / card.maxCooldown) * 100}%`,
          }}
        ></div>
      )}
      {card.effect.duration && card.effect.duration > 0 && (
        <div className="duration">{card.effect.duration}</div>
      )}
    </div>
  );
};

export default DraggableCard;
