import australia_1 from "../Images/profileImages/australia_1.webp";
import australia_2 from "../Images/profileImages/australia_2.webp";
import australia_3 from "../Images/profileImages/australia_3.webp";
import english_1 from "../Images/profileImages/english_1.webp";
import english_2 from "../Images/profileImages/english_2.webp";
import english_3 from "../Images/profileImages/english_3.webp";
import english_4 from "../Images/profileImages/english_4.webp";
import indian_1 from "../Images/profileImages/indian_1.webp";
import indian_2 from "../Images/profileImages/indian_2.webp";
import indian_3 from "../Images/profileImages/indian_3.webp";
import indian_4 from "../Images/profileImages/indian_4.webp";
import indian_5 from "../Images/profileImages/indian_5.webp";
import newZealand_1 from "../Images/profileImages/newZealand_1.webp";
import newZealand_2 from "../Images/profileImages/newZealand_2.webp";
import newZealand_3 from "../Images/profileImages/newZealand_3.webp";
import pakistani_1 from "../Images/profileImages/pakistani_1.webp";
import pakistani_2 from "../Images/profileImages/pakistani_2.webp";
import sriLankan_1 from "../Images/profileImages/sriLankan_1.webp";
import sriLankan_2 from "../Images/profileImages/sriLankan_2.webp";
import westIndian_1 from "../Images/profileImages/westIndian_1.webp";
import westIndian_2 from "../Images/profileImages/westIndian_2.webp";
import westIndian_3 from "../Images/profileImages/westIndian_3.webp";
import batsmanImage from "../Images/profileImages/cricket_bat.webp";
import wicketKeepingGloves from "../Images/profileImages/wicket_keeping_gloves.webp";
import bowlerImage from "../Images/profileImages/cricket_ball.webp";
import allRounderImage from "../Images/profileImages/cricket_bat_and_ball.webp";
import card_sandpaper from "../Images/CardImages/card_sandpaper.webp";
import card_pick_the_seam from "../Images/CardImages/card_pick_the_seam.webp";
import card_gavin_larsen from "../Images/CardImages/card_gavin_larsen.webp";
import card_chapelli from "../Images/CardImages/card_chappelli.webp";
import card_rawalpindi from "../Images/CardImages/card_rawalpindi.webp";
import card_bodyline from "../Images/CardImages/card_bodyline.webp";
import card_ball_of_the_century from "../Images/CardImages/card_ball_of_the_century.webp";
import card_spot_fixing from "../Images/CardImages/card_spot_fixing.webp";
import card_slippery_balls from "../Images/CardImages/card_slippery_balls.webp";
import card_spank_it from "../Images/CardImages/card_spank_it.webp";
import card_quick_singles from "../Images/CardImages/card_quick_singles.png";
import card_call_in_a_runner from "../Images/CardImages/card_call_in_a_runner.webp";
import card_not_out from "../Images/CardImages/card_not_out.webp";
import card_im_don_bradman from "../Images/CardImages/card_im_don_bradman.webp";
import card_boundry_time from "../Images/CardImages/card_boundry_time.webp";
import card_pressure_off from "../Images/CardImages/card_pressure_off.webp";
import stadium_image from "../Images/GeneralImages/stadium_image.webp";
import stadium_background from "../Images/GeneralImages/stadium_background.png";
import opposition_indian_1 from "../Images/profileImages/opposition_indian_1.webp";
import gear_fat_bat from "../Images/Gear/gear_fat_bat.webp";
import gear_big_spikes from "../Images/Gear/gear_big_spikes.webp";
import gear_dripless from "../Images/Gear/gear_dripless.webp";
import gear_iron_blade from "../Images/Gear/gear_iron_blade.webp";
import gear_smooth_arms from "../Images/Gear/gear_smooth_arms.webp";
import gear_quick_feet from "../Images/Gear/gear_quick_feet.webp";
import gear_gorilla_grip from "../Images/Gear/gear_gorilla_grip.webp";
import gear_magic_mitts from "../Images/Gear/gear_magic_mitts.webp";
import gear_energy_band from "../Images/Gear/gear_energy_band.webp";
import gear_vision_plus from "../Images/Gear/gear_vision_plus.webp";
import gear_thunder_belt from "../Images/Gear/gear_thunder_belt.webp";
import gear_comfort_cap from "../Images/Gear/gear_comfort_cap.webp";
import australia_captain from "../Images/OppositionImages/australia_captain.webp";
import india_captain from "../Images/OppositionImages/india_captain.webp";
import england_captain from "../Images/OppositionImages/england_captain.webp";
import pakistan_captain from "../Images/OppositionImages/pakistan_captain.webp";
import sri_lanka_captain from "../Images/OppositionImages/sri_lanka_captain.webp";
import new_zealand_captain from "../Images/OppositionImages/new_zealand_captain.webp";
import zimbabwe_captain from "../Images/OppositionImages/zimbabwe_captain.webp";
import afganistan_captain from "../Images/OppositionImages/afganistan_captain.webp";
import south_africa_captain from "../Images/OppositionImages/south_africa_captain.webp";

const useGameImages = () => {
  const images = {
    australia_1,
    australia_2,
    australia_3,
    english_1,
    english_2,
    english_3,
    english_4,
    indian_1,
    indian_2,
    indian_3,
    indian_4,
    indian_5,
    newZealand_1,
    newZealand_2,
    newZealand_3,
    pakistani_1,
    pakistani_2,
    sriLankan_1,
    sriLankan_2,
    westIndian_1,
    westIndian_2,
    westIndian_3,
    batsmanImage,
    wicketKeepingGloves,
    bowlerImage,
    allRounderImage,
    card_sandpaper,
    card_pick_the_seam,
    card_gavin_larsen,
    card_chapelli,
    card_rawalpindi,
    card_bodyline,
    card_ball_of_the_century,
    card_spot_fixing,
    card_slippery_balls,
    card_spank_it,
    card_quick_singles,
    card_call_in_a_runner,
    card_not_out,
    card_im_don_bradman,
    card_boundry_time,
    card_pressure_off,
    stadium_image,
    stadium_background,
    opposition_indian_1,
    gear_fat_bat,
    gear_big_spikes,
    gear_dripless,
    gear_iron_blade,
    gear_smooth_arms,
    gear_quick_feet,
    gear_gorilla_grip,
    gear_magic_mitts,
    gear_energy_band,
    gear_vision_plus,
    gear_thunder_belt,
    gear_comfort_cap,
    australia_captain,
    india_captain,
    england_captain,
    pakistan_captain,
    sri_lanka_captain,
    new_zealand_captain,
    zimbabwe_captain,
    afganistan_captain,
    south_africa_captain,
  };

  return images;
};

export default useGameImages;
