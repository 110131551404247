import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  Australia,
  India,
  England,
  Pakistan,
  Sri_Lanka,
  New_Zealand,
  Zimbabwe,
  Afganistan,
  South_Africa,
} from "../DataLists/Players";
import { clamp } from "../Utils/Utils";
import getEffectHandler from "../DataLists/CardEffectHandler.js";
import { MatchStates } from "../Constants/Constants.js";
import { getHowOut, getNextBowler } from "../Utils/ScoreUtilities.js";
import { useGame } from "./GameContext.js";
import {
  fetchOppositionPlayers,
  fetchRandomOppositionTeam,
} from "../Api/Firebase/firebaseAPI.js";
import { useAuth } from "./AuthContext.js";

// Define the context
const MatchContext = createContext();

const teams = {
  Australia,
  India,
  England,
  Pakistan,
  Sri_Lanka,
  New_Zealand,
  Zimbabwe,
  Afganistan,
  South_Africa,
};

const initialState = {
  matchInfo: {
    teams: {
      teamA: {
        name: "",
        players: [],
        innings: [
          { score: 0, wickets: 0, legalDeliveries: 0, recentDeliveries: [] },
          { score: 0, wickets: 0, legalDeliveries: 0, recentDeliveries: [] },
        ],
      },
      teamB: {
        name: "",
        players: [],
        innings: [
          { score: 0, wickets: 0, legalDeliveries: 0, recentDeliveries: [] },
          { score: 0, wickets: 0, legalDeliveries: 0, recentDeliveries: [] },
        ],
      },
    },
    maximumLegalDeliveries: 300,
    initialBattingTeam: "",
    battingTeam: "",
    bowlingTeam: "",
    firstBatsmanIndex: 0,
    secondBatsmanIndex: 1,
    firstBowlerIndex: 10,
    secondBowlerIndex: 9,
    onStrikeBatsmanIndex: 0,
    offStrikeBatsmanIndex: 1,
    currentBowlerIndex: 10,
    otherBowlerIndex: 9,
    fallOfWicket: false, // Indicates whether the fall of wicket modal should appear
    fallOfWicketDetails: {
      batsman: "",
      howOut: "",
      runs: 0,
      ballsFaced: 0,
      fours: 0,
      sixes: 0,
    },
    currentInnings: 0, // Indicates which innings we are in, either 0, 1, 2, or 3
    inningsInMatch: 2, // The total number of innings in the match
    teamInningsIndex: 0, // Determine which innings we are in for the batting team, first innings for each team is 0
    matchState: "AWAITING_NEXT_MATCH",
    canProcessDelivery: false,
    teamModifiers: [],
    lastDeliveryOutcome: null,
  },
  matchSettings: {
    pauseAfterWicket: { label: "Pause after each wicket", isChecked: false },
    pauseAfterBall: { label: "Pause after each ball", isChecked: false },
    pauseAfterOver: { label: "Pause after each over", isChecked: false },
    showFallOfWicketDialog: {
      label: "Show fall of wicket dialog after each wicket",
      isChecked: true,
    },
  },
  deliveryState: {},
};

const initialPlayerState = [
  {
    runs: 0,
    howOut: "",
    ballsFaced: 0,
    fours: 0,
    sixes: 0,
    overs: 0,
    maidens: 0,
    runsAgainst: 0,
    wickets: 0,
  }, // First Innings
  {
    runs: 0,
    howOut: "",
    ballsFaced: 0,
    fours: 0,
    sixes: 0,
    overs: 0,
    maidens: 0,
    runsAgainst: 0,
    wickets: 0,
  }, // Second Innings
];

// Reducer to handle actions
function matchReducer(state, action) {
  let firstBatsmanIndex = state.matchInfo.firstBatsmanIndex,
    secondBatsmanIndex = state.matchInfo.secondBatsmanIndex,
    firstBowlerIndex = state.matchInfo.firstBowlerIndex,
    secondBowlerIndex = state.matchInfo.secondBowlerIndex,
    onStrikeBatsmanIndex = state.matchInfo.onStrikeBatsmanIndex,
    offStrikeBatsmanIndex = state.matchInfo.offStrikeBatsmanIndex,
    currentBowlerIndex = state.matchInfo.currentBowlerIndex,
    otherBowlerIndex = state.matchInfo.otherBowlerIndex,
    currentInnings = state.matchInfo.currentInnings,
    currentWickets = state.matchInfo.currentWickets,
    matchState = state.matchInfo.matchState,
    battingTeam = state.matchInfo.battingTeam,
    bowlingTeam = state.matchInfo.bowlingTeam,
    fallOfWicket = state.matchInfo.fallOfWicket,
    fallOfWicketDetails = state.matchInfo.fallOfWicketDetails,
    teamModifiers = [...state.matchInfo.teamModifiers],
    lastDeliveryOutcome = state.matchInfo.lastDeliveryOutcome;

  switch (action.type) {
    case "SET_FIRST_INNINGS_BATTING_TEAM":
      console.log("User is batting: ", action.userIsBatting);
      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          initialBattingTeam: action.userIsBatting ? "teamA" : "teamB",
          battingTeam: action.userIsBatting ? "teamA" : "teamB",
          bowlingTeam: action.userIsBatting ? "teamB" : "teamA",
        },
      };
    case "SET_CURRENT_SQUAD":
      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          teams: {
            ...state.matchInfo.teams,
            teamA: {
              ...state.matchInfo.teams.teamA,
              name: action.gameState.teamName,
              players: action.players,
            },
            // when I need to do team B I can do it here.
          },
        },
      };
    case "UPDATE_PLAYER_AGGRESSION":
      // UPDATE THE PLAYERS AGGRESSION STAT
      const updatedTeamAPlayers = state.matchInfo.teams.teamA.players.map(
        (player) => {
          if (player.id === action.currentPlayer.id) {
            if (action.playerIsBatting) {
              return {
                ...player,
                battingAggression: action.aggression,
              };
            } else {
              console.log("Setting bowler aggression to", action.aggression);
              return {
                ...player,
                bowlingAggression: action.aggression,
              };
            }
          }
          return player; // Return unchanged if not the currentPlayer
        }
      );

      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          teams: {
            ...state.matchInfo.teams,
            teamA: {
              ...state.matchInfo.teams.teamA,
              players: updatedTeamAPlayers,
            },
            // Continue with team B as needed
          },
        },
      };
    case "UPDATE_MATCH_SETTINGS":
      return {
        ...state,
        matchSettings: action.matchSettings,
      };
    case "UPDATE_MATCH_FORM":
      // Determine the team of the player whose pressure needs updating
      const matchFormTeamKey = state.matchInfo.teams[
        state.matchInfo.battingTeam
      ].players.some((player) => player.id === action.playerId)
        ? "teamA"
        : "teamB";

      // Update the player's pressure in the correct team
      const updatedMatchFormPlayers = state.matchInfo.teams[
        matchFormTeamKey
      ].players.map((player) =>
        player.id === action.playerId
          ? {
              ...player,
              pressure: clamp(
                player.pressure + action.pressureAdjustment,
                0,
                100
              ),
              form: clamp(player.form + action.formAdjustment, 0, 100),
              fitnessLevel: clamp(
                player.fitnessLevel + action.fitnessLevelAdjustment,
                0,
                100
              ),
            }
          : player
      );

      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          teams: {
            ...state.matchInfo.teams,
            [matchFormTeamKey]: {
              ...state.matchInfo.teams[matchFormTeamKey],
              players: updatedMatchFormPlayers,
            },
          },
        },
      };
    case "APPLY_DROPPED_CARD":
      const teamKey = state.matchInfo.teams.teamA.players.some(
        (player) => player.id === action.playerId
      )
        ? "teamA"
        : "teamB";

      // Creating a new array with the added effect, avoiding direct mutation of the state
      teamModifiers.push(action);

      const updatedPlayers = state.matchInfo.teams[teamKey].players.map(
        (player) => {
          if (player.id !== action.playerId) return player; // Skip players not targeted by the action

          // Fetch the appropriate effect handler dynamically
          const handler = getEffectHandler(action.effect.type);

          return handler(player, action);
        }
      );

      console.log(
        "Updated players should have pressure adjusted in there: ",
        updatedPlayers
      );

      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          teamModifiers, // Update the teamModifiers in the state
          teams: {
            ...state.matchInfo.teams,
            [teamKey]: {
              ...state.matchInfo.teams[teamKey],
              players: updatedPlayers,
            },
          },
        },
      };
    case "PROCESS_DELIVERY":
      let deliveryOutcome = action.runs.toString();
      let batsmanRunsScored = action.runs;
      let teamRunsScored = action.runs;
      let addLegalDelivery = true;
      let shouldSwapEnds = false;
      let modifiers = action.modifiers;

      teamModifiers = [...action.updatedTeamModifiers];
      deliveryOutcome = action.runs.toString();
      lastDeliveryOutcome = deliveryOutcome;

      // Adjust scores and delivery outcome based on the type of delivery
      switch (deliveryOutcome) {
        case "wicket":
          deliveryOutcome = "x";
          batsmanRunsScored = 0;
          teamRunsScored = 0;
          break;
        case "wide":
          deliveryOutcome = "w";
          batsmanRunsScored = 0;
          teamRunsScored = 1; // Run scored from a wide does not count towards batsman's personal score
          addLegalDelivery = false;
          break;
        case "bye":
          deliveryOutcome = "b";
          batsmanRunsScored = 0;
          teamRunsScored = 1;
          shouldSwapEnds = true;
          break;
        case "leg bye":
          deliveryOutcome = "lb";
          batsmanRunsScored = 0;
          teamRunsScored = 1;
          shouldSwapEnds = true;
          break;
        case "no ball":
          deliveryOutcome = "nb";
          batsmanRunsScored = 0;
          teamRunsScored = 1;
          addLegalDelivery = false;
          break;
        case "0":
          deliveryOutcome = ".";
          batsmanRunsScored = 0;
          teamRunsScored = 0;
          break;
        default:
          // The batsman scored some runs so set the values as per the runs scored
          batsmanRunsScored = parseInt(deliveryOutcome);
          teamRunsScored = parseInt(deliveryOutcome);
          shouldSwapEnds = batsmanRunsScored === 1 || batsmanRunsScored === 3;
          break;
      }

      const wicketWasTaken = deliveryOutcome === "x";
      let howOut;

      if (wicketWasTaken) {
        howOut = getHowOut(
          action.bowlerId,
          state.matchInfo.teams[state.matchInfo.bowlingTeam].players
        );
      }

      // Updates batsman statistics
      let updatedBatsmen = state.matchInfo.teams[
        state.matchInfo.battingTeam
      ].players.map((player) =>
        player.id === action.batsmanId
          ? {
              ...player,
              modifiers,
              currentMatch: [
                {
                  ...player.currentMatch[state.matchInfo.teamInningsIndex],
                  howOut: wicketWasTaken
                    ? howOut
                    : player.currentMatch[state.matchInfo.teamInningsIndex]
                        .howOut,
                  runs:
                    player.currentMatch[state.matchInfo.teamInningsIndex].runs +
                    batsmanRunsScored,
                  ballsFaced:
                    player.currentMatch[state.matchInfo.teamInningsIndex]
                      .ballsFaced + 1,
                  fours:
                    batsmanRunsScored === 4
                      ? player.currentMatch[state.matchInfo.teamInningsIndex]
                          .fours + 1
                      : player.currentMatch[state.matchInfo.teamInningsIndex]
                          .fours,
                  sixes:
                    batsmanRunsScored === 6
                      ? player.currentMatch[state.matchInfo.teamInningsIndex]
                          .sixes + 1
                      : player.currentMatch[state.matchInfo.teamInningsIndex]
                          .sixes,
                },
              ],
            }
          : player
      );

      // Updated bowler statistics
      let updatedBowlers = state.matchInfo.teams[
        state.matchInfo.bowlingTeam
      ].players.map((player) => {
        if (player.id === action.bowlerId) {
          const updatedPlayer = {
            ...player,
            modifiers,
            currentMatch: player.currentMatch.map((innings, index) => {
              if (index === state.matchInfo.teamInningsIndex) {
                const updatedInnings = {
                  ...innings,
                  overs: addLegalDelivery ? innings.overs + 1 : innings.overs,
                  runsAgainst: innings.runsAgainst + teamRunsScored,
                  wickets: wicketWasTaken
                    ? innings.wickets + 1
                    : innings.wickets,
                };

                return updatedInnings;
              } else {
                return innings;
              }
            }),
          };
          return updatedPlayer;
        } else {
          return player;
        }
      });

      // Handle wickets a swapping ends
      const tempDeliveryCount = addLegalDelivery
        ? state.matchInfo.teams[state.matchInfo.battingTeam].innings[
            state.matchInfo.teamInningsIndex
          ].legalDeliveries + 1
        : state.matchInfo.teams[state.matchInfo.battingTeam].innings[
            state.matchInfo.teamInningsIndex
          ].legalDeliveries;

      currentWickets = wicketWasTaken
        ? state.matchInfo.teams[state.matchInfo.battingTeam].innings[
            state.matchInfo.teamInningsIndex
          ].wickets + 1
        : state.matchInfo.teams[state.matchInfo.battingTeam].innings[
            state.matchInfo.teamInningsIndex
          ].wickets;

      onStrikeBatsmanIndex = state.matchInfo.onStrikeBatsmanIndex;
      offStrikeBatsmanIndex = state.matchInfo.offStrikeBatsmanIndex;
      currentBowlerIndex = state.matchInfo.currentBowlerIndex;
      otherBowlerIndex = state.matchInfo.otherBowlerIndex;
      firstBatsmanIndex = state.matchInfo.firstBatsmanIndex;
      secondBatsmanIndex = state.matchInfo.secondBatsmanIndex;
      firstBowlerIndex = state.matchInfo.firstBowlerIndex;
      secondBowlerIndex = state.matchInfo.secondBowlerIndex;
      matchState = state.matchInfo.matchState;
      currentInnings = state.matchInfo.currentInnings;

      // If team 2 has won the match
      if (
        state.matchInfo.currentInnings === 1 &&
        state.matchInfo.teams[state.matchInfo.battingTeam].innings[
          state.matchInfo.teamInningsIndex
        ].score +
          teamRunsScored >
          state.matchInfo.teams[state.matchInfo.bowlingTeam].innings[
            state.matchInfo.teamInningsIndex
          ].score
      ) {
        matchState = MatchStates.END_OF_MATCH;
        // Innings is completed
      } else if (
        currentWickets === 10 ||
        state.matchInfo.maximumLegalDeliveries === tempDeliveryCount
      ) {
        // Current wickets = 10 or all overs completed so the simulation should end because team 1 won the match
        if (state.matchInfo.currentInnings === 0) {
          matchState = MatchStates.END_OF_INNINGS;
        } else if (state.matchInfo.currentInnings === 1) {
          matchState = MatchStates.END_OF_MATCH;

          // TODO: This never runs, review.
        }

        // Handle the end of the delivery
      } else {
        // If a wicket was taken, then we need to set the firstBatsman or secondBatsman indexes used in the CurrentBatsmanComponent
        if (wicketWasTaken) {
          // Set the fall of wicket details for the FoW dialog
          const outBatsman =
            state.matchInfo.teams[state.matchInfo.battingTeam].players[
              onStrikeBatsmanIndex
            ];
          const outBatsmanStats =
            outBatsman.currentMatch[state.matchInfo.teamInningsIndex];

          fallOfWicketDetails = {
            batsman: `${outBatsman.playerInitial}. 
              ${outBatsman.lastName}`,
            howOut: howOut,
            runs: outBatsmanStats.runs,
            balls: outBatsmanStats.ballsFaced,
            fours: outBatsmanStats.fours,
            sixes: outBatsmanStats.sixes,
          };

          // If pause on wicket then dispatch pause
          if (state.matchSettings.pauseAfterWicket.isChecked) {
            matchState = MatchStates.PAUSED;
          }

          fallOfWicket = true;
        }
      }

      // Used for processing the end of the delivery
      const newDeliveryState = {
        onStrikeBatsmanIndex: onStrikeBatsmanIndex,
        offStrikeBatsmanIndex: offStrikeBatsmanIndex,
        wicketWasTaken: wicketWasTaken,
        currentWickets: currentWickets,
        deliveries: tempDeliveryCount,
        shouldSwapEnds: shouldSwapEnds,
        currentBowlerIndex: currentBowlerIndex,
        otherBowlerIndex: otherBowlerIndex,
      };

      return {
        ...state,
        deliveryState: newDeliveryState,
        ...state.matchSettings,
        matchInfo: {
          ...state.matchInfo,
          matchState,
          teamModifiers,
          currentInnings,
          firstBatsmanIndex,
          secondBatsmanIndex,
          firstBowlerIndex,
          secondBowlerIndex,
          onStrikeBatsmanIndex,
          offStrikeBatsmanIndex,
          currentBowlerIndex,
          otherBowlerIndex,
          lastDeliveryOutcome,
          fallOfWicket,
          fallOfWicketDetails,
          teams: {
            ...state.matchInfo.teams,
            [state.matchInfo.battingTeam]: {
              ...state.matchInfo.teams[state.matchInfo.battingTeam],
              players: updatedBatsmen,
              innings: state.matchInfo.teams[
                state.matchInfo.battingTeam
              ].innings.map((inn, idx) =>
                idx === state.matchInfo.teamInningsIndex
                  ? {
                      ...inn,
                      score: inn.score + teamRunsScored,
                      wickets: inn.wickets + (wicketWasTaken ? 1 : 0),
                      legalDeliveries: addLegalDelivery
                        ? inn.legalDeliveries + 1
                        : inn.legalDeliveries,
                      recentDeliveries: [
                        deliveryOutcome,
                        ...inn.recentDeliveries.slice(0, 23), // Ensuring only the last 24 deliveries are kept
                      ],
                    }
                  : inn
              ),
            },
            [state.matchInfo.bowlingTeam]: {
              ...state.matchInfo.teams[state.matchInfo.bowlingTeam],
              players: updatedBowlers,
              // Assuming the innings structure for the bowling team does not need to be updated here
              innings:
                state.matchInfo.teams[state.matchInfo.bowlingTeam].innings,
            },
          },
        },
      };
    case "PROCESS_END_OF_DELIVERY":
      /**
       * Handles setting the new batsman when a wicket was taken, we only want to do this when the players are not all out
       */
      if (state.deliveryState.currentWickets < 10) {
        onStrikeBatsmanIndex = state.deliveryState.wicketWasTaken
          ? (currentWickets = state.deliveryState.currentWickets + 1)
          : state.deliveryState.onStrikeBatsmanIndex;
      }

      /**
       * Handles setting the first and second batsman in the current batsman components
       */
      if (firstBatsmanIndex === onStrikeBatsmanIndex) {
        firstBatsmanIndex = onStrikeBatsmanIndex;
        secondBatsmanIndex = offStrikeBatsmanIndex;
      } else {
        firstBatsmanIndex = offStrikeBatsmanIndex;
        secondBatsmanIndex = onStrikeBatsmanIndex;
      }

      /**
       * Swap ends
       */

      onStrikeBatsmanIndex = state.deliveryState.shouldSwapEnds
        ? state.deliveryState.offStrikeBatsmanIndex
        : onStrikeBatsmanIndex;

      offStrikeBatsmanIndex = state.deliveryState.shouldSwapEnds
        ? state.deliveryState.onStrikeBatsmanIndex
        : offStrikeBatsmanIndex;

      // Now handle swapping ends and change of bowler, if required, when it's the end of an over
      if (
        state.deliveryState.deliveries % 6 === 0 &&
        state.deliveryState.deliveries > 0
      ) {
        const previousOnStrikeBatsmanIndex = onStrikeBatsmanIndex; // hold the existing index for the on strike batsman
        const previousBowlerIndex = state.deliveryState.currentBowlerIndex;

        onStrikeBatsmanIndex = offStrikeBatsmanIndex;
        offStrikeBatsmanIndex = previousOnStrikeBatsmanIndex;

        const nextBowlerIndex = getNextBowler(
          state.matchInfo.teams[state.matchInfo.bowlingTeam].players,
          state.deliveryState.currentBowlerIndex,
          state.deliveryState.otherBowlerIndex,
          state
        );

        currentBowlerIndex = nextBowlerIndex;
        otherBowlerIndex = previousBowlerIndex;

        // Update the first and second bowlers on the match options card
        /**
         * When a bowler changes, we need to replace the first or second bowler index based on the new bowler.
         * By now, the change has already taken place, so we have a mismatch between the first and second bowler index and the current and other bowler index
         * This means, we need to find the mismatched index and replace it with the currentBowlerIndex which is the new bowler.
         */
        if (
          firstBowlerIndex !== currentBowlerIndex &&
          firstBowlerIndex !== otherBowlerIndex
        ) {
          firstBowlerIndex = currentBowlerIndex;
          secondBowlerIndex = otherBowlerIndex;
        } else if (
          secondBowlerIndex !== currentBowlerIndex &&
          secondBowlerIndex !== otherBowlerIndex
        ) {
          firstBowlerIndex = otherBowlerIndex;
          secondBowlerIndex = currentBowlerIndex;
        }
      }

      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          currentWickets,
          onStrikeBatsmanIndex,
          offStrikeBatsmanIndex,
          firstBatsmanIndex,
          secondBatsmanIndex,
          firstBowlerIndex,
          secondBowlerIndex,
          currentBowlerIndex,
          otherBowlerIndex,
        },
        deliveryState: {},
      };
    case "RESET_WICKET_ALERT":
      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          fallOfWicket: false,
        },
      };
    case "CHECK_MATCH_STATUS":
      battingTeam = state.matchInfo.battingTeam;
      bowlingTeam = state.matchInfo.bowlingTeam;
      firstBatsmanIndex = state.matchInfo.firstBatsmanIndex;
      secondBatsmanIndex = state.matchInfo.secondBatsmanIndex;
      firstBowlerIndex = state.matchInfo.firstBowlerIndex;
      secondBowlerIndex = state.matchInfo.secondBowlerIndex;
      onStrikeBatsmanIndex = state.matchInfo.onStrikeBatsmanIndex;
      offStrikeBatsmanIndex = state.matchInfo.offStrikeBatsmanIndex;
      currentBowlerIndex = state.matchInfo.currentBowlerIndex;
      otherBowlerIndex = state.matchInfo.otherBowlerIndex;
      currentInnings = state.matchInfo.currentInnings;
      currentWickets =
        state.matchInfo.teams[state.matchInfo.battingTeam].innings[
          state.matchInfo.teamInningsIndex
        ].wickets;
      matchState = state.matchInfo.matchState;
      teamModifiers = state.matchInfo.teamModifiers;
      let newMatchState = state.matchInfo.matchState;
      let canProcessDelivery = state.matchInfo.canProcessDelivery;

      if (state.matchInfo.matchState === MatchStates.END_OF_INNINGS) {
        const currentInnings = state.matchInfo.currentInnings + 1;
        const battingTeam = state.matchInfo.bowlingTeam;
        const bowlingTeam = state.matchInfo.battingTeam;

        const firstBatsmanIndex = 0;
        const secondBatsmanIndex = 1;
        const firstBowlerIndex = 10;
        const secondBowlerIndex = 9;
        const onStrikeBatsmanIndex = 0;
        const offStrikeBatsmanIndex = 1;
        const currentBowlerIndex = 10;
        const otherBowlerIndex = 9;
        const currentWickets = 0;
        const teamModifiers = [];

        // Clear all the players' modifiers
        for (let teamKey in state.matchInfo.teams) {
          const team = state.matchInfo.teams[teamKey];
          for (let player of team.players) {
            player.modifiers = []; // Resetting modifiers to an empty array
          }
        }

        const matchState = MatchStates.READY_TO_START;
        const canProcessDelivery = true;

        return {
          ...state,
          matchInfo: {
            ...state.matchInfo,
            currentInnings,
            canProcessDelivery,
            battingTeam,
            bowlingTeam,
            firstBatsmanIndex,
            secondBatsmanIndex,
            firstBowlerIndex,
            secondBowlerIndex,
            onStrikeBatsmanIndex,
            offStrikeBatsmanIndex,
            currentBowlerIndex,
            otherBowlerIndex,
            currentWickets,
            teamModifiers,
            matchState,
          },
          // Optionally reset other parts of the match info as needed
        };
      } else {
        // Handle pausing on end of ball / over as required.
        if (
          state.matchInfo.matchState !== MatchStates.END_OF_INNINGS &&
          state.matchInfo.matchState !== MatchStates.END_OF_MATCH &&
          state.matchInfo.matchState !== MatchStates.READY_TO_START
        ) {
          if (
            state.matchSettings.pauseAfterBall.isChecked &&
            matchState === MatchStates.INNINGS_IN_PROGRESS
          ) {
            newMatchState = MatchStates.PAUSED;
            canProcessDelivery = false;
          }

          // If it is over, and pause after over is enabled.
          if (
            state.matchSettings.pauseAfterOver.isChecked &&
            state.matchInfo.teams[state.matchInfo.battingTeam].innings[
              state.matchInfo.teamInningsIndex
            ].legalDeliveries %
              6 ===
              0 &&
            matchState === MatchStates.INNINGS_IN_PROGRESS
          ) {
            newMatchState = MatchStates.PAUSED;
            canProcessDelivery = false;
          }
        }
      }

      return {
        ...state,
        matchsettings: {
          ...state.matchSettings,
        },
        matchInfo: {
          ...state.matchInfo,
          currentInnings,
          battingTeam,
          bowlingTeam,
          firstBatsmanIndex,
          secondBatsmanIndex,
          firstBowlerIndex,
          secondBowlerIndex,
          onStrikeBatsmanIndex,
          offStrikeBatsmanIndex,
          currentBowlerIndex,
          otherBowlerIndex,
          currentWickets,
          teamModifiers,
          matchState: newMatchState,
          canProcessDelivery,
        },
        // Optionally reset other parts of the match info as needed
      };
    case "UPDATE_MATCH_STATE":
      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          matchState: action.newMatchState,
          canProcessDelivery: action.canProcessDelivery,
        },
      };
    case "START_NEW_MATCH":
      console.log("SET THE INITIAL BOWLERS HERE");

      return {
        ...initialState,
        matchInfo: {
          ...initialState.matchInfo,
          matchState: "AWAITING_NEXT_MATCH",
          teams: {
            ...initialState.matchInfo.teams,
            teamA: {
              ...initialState.matchInfo.teams.teamA,
              players: action.players,
            },
          },
        },
        matchSettings: {
          ...state.matchSettings, // Preserving current match settings into the new state
        },
      };
    case "SET_NEXT_OPPOSITION":
      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          firstBowlerIndex: action.payload.initialBowlerIndex,
          secondBowlerIndex: action.payload.nextBowlerIndex,
          currentBowlerIndex: action.payload.initialBowlerIndex,
          otherBowlerIndex: action.payload.nextBowlerIndex,
          teams: {
            ...state.matchInfo.teams,
            teamB: {
              ...state.matchInfo.teams.teamB,
              players: action.payload.players,
              name: action.payload.name,
            },
          },
        },
      };
    case "SET_NEXT_BOWLER":
      const newBowlerIndex = state.matchInfo.teams[
        state.matchInfo.bowlingTeam
      ].players.findIndex((player) => player.id === action.newBowlerId);

      otherBowlerIndex = newBowlerIndex;

      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          otherBowlerIndex,
          userHasSetCustomBowler: true,
        },
      };
    case "PROMOTE_BATSMAN":
      const playerArray =
        state.matchInfo.teams[state.matchInfo.battingTeam].players;

      const fromIndex = state.matchInfo.teams[
        state.matchInfo.battingTeam
      ].players.findIndex((player) => player.id === action.newBatsmanId);

      const toIndex =
        state.matchInfo.teams[state.matchInfo.battingTeam].innings[
          state.matchInfo.teamInningsIndex
        ].wickets + 1;

      let players;

      const promoteBatsman = (playerArray, fromIndex, toIndex) => {
        if (
          fromIndex !== toIndex &&
          fromIndex >= 0 &&
          fromIndex < playerArray.length &&
          toIndex >= 0 &&
          toIndex <= playerArray.length
        ) {
          const [item] = playerArray.splice(fromIndex, 1); // Remove the item from the current position
          playerArray.splice(toIndex, 0, item); // Insert the item at the new position
        }

        players = playerArray;
      };

      promoteBatsman(playerArray, fromIndex, toIndex);

      return {
        ...state,
        ...state.deliveryState,
        matchInfo: {
          ...state.matchInfo,
          teams: {
            ...state.matchInfo.teams,
            [battingTeam]: {
              ...state.matchInfo.teams[battingTeam],
              players,
            },
          },
        },
      };
    default:
      console.log("DEBUG DEFAULT");

      return state;
  }
}

// Provider component
export const MatchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(matchReducer, initialState);
  const { gameState } = useGame();
  const [isGamePaused, setIsGamePaused] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    // Check if the matchState indicates that the match is ready to start

    if (state.matchInfo.matchState === "AWAITING_NEXT_MATCH") {
      // Sets the next opposition team
      console.log("About to set next opposition");
      setNextOpposition();
      console.log("Finished setting next opposition");

      const selectedPlayers = gameState.squad
        .filter((player) => player.selected)
        .sort((a, b) => {
          const order = [
            "opener",
            "top order",
            "middle order",
            "lower order",
            "tailender",
          ];
          return order.indexOf(a.batPosition) - order.indexOf(b.batPosition);
        });

      const modifiedSelectedPlayers = selectedPlayers.map((player) => ({
        ...player,
        currentMatch: [...initialPlayerState], // Inserting initialMatchState into currentMatch
      }));

      if (selectedPlayers.length > 0) {
        dispatch({
          type: "SET_CURRENT_SQUAD",
          players: modifiedSelectedPlayers,
          gameState: gameState,
        });
      }
    }
  }, [gameState, state.matchInfo.matchState, isGamePaused]); // Dependency on gameState.matchState

  const setNextOpposition = async () => {
    // TODO: Set the next opposition
    // console.log("About to try get players");
    const players = await getOppositionPlayers();

    //const players = teams[gameState.nextOpposition.playerListName];
    const name = gameState.nextOpposition.teamName;

    console.log("Got Players: ", players);

    let initialBowlerIndex = 10;
    let nextBowlerIndex = 9;

    if (players) {
      //Map the players to include their original indices
      const playersWithIndex = players.map((player, index) => ({
        index,
        ...player,
      }));

      // const playersWithIndex = teams[
      //   gameState.nextOpposition.playerListName
      // ].map((player, index) => ({ index, ...player }));

      // Sort the array based on bowlingSkill
      const sortedPlayersWithIndex = playersWithIndex.sort((a, b) => {
        return b.bowlingSkill - a.bowlingSkill;
      });

      // Extract the original indices of the top two bowlers
      initialBowlerIndex = sortedPlayersWithIndex[0].index; // Index of the player with the highest bowling skill
      nextBowlerIndex = sortedPlayersWithIndex[1].index; // Index of the player with the second highest bowling skill
    }

    dispatch({
      type: "SET_NEXT_OPPOSITION",
      payload: { players, name, initialBowlerIndex, nextBowlerIndex },
    });
  };

  const pauseMatch = useCallback(() => {
    if (state.matchInfo.matchState === MatchStates.INNINGS_IN_PROGRESS) {
      dispatch({
        type: "UPDATE_MATCH_STATE",
        newMatchState: MatchStates.PAUSED,
        canProcessDelivery: false,
      });

      setIsGamePaused(true);
    }
  }, [state.matchInfo.matchState, dispatch]);

  const resumeMatch = useCallback(() => {
    if (state.matchInfo.matchState === MatchStates.PAUSED) {
      dispatch({
        type: "UPDATE_MATCH_STATE",
        newMatchState: MatchStates.INNINGS_IN_PROGRESS,
        canProcessDelivery: true,
      });

      setIsGamePaused(false);
    }
  }, [state.matchInfo.matchState, dispatch]);

  const getOppositionPlayers = async () => {
    const randomTeam = await fetchOppositionPlayers(currentUser.uid, "Club");

    return randomTeam.slice(0, 11);
  };

  useEffect(() => {}, [
    state.matchInfo.lastDeliveryOutcome,
    state.matchInfo.canProcessDelivery,
    state.matchInfo.matchState,
    state.matchInfo.offStrikeBatsmanIndex,
    state.matchInfo.onStrikeBatsmanIndex,
    state.matchSettings,
  ]);

  return (
    <MatchContext.Provider
      value={{ state, dispatch, pauseMatch, resumeMatch, isGamePaused }}
    >
      {children}
    </MatchContext.Provider>
  );
};

// Custom hook to use the context
export const useMatch = () => useContext(MatchContext);
