import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { useGame } from "../../../Contexts/GameContext";
import { convertDeliveriesToOversBowled } from "../../../Utils/ScoreUtilities";
import useGameImages from "../../../Hooks/useGameImages";
import ContextMenu from "./ContextMenu";

const FullSquadComponent = ({ selectedPlayer, setSelectedPlayer }) => {
  const { gameState, gameDispatch, togglePlayerSelection } = useGame();
  const image = useGameImages();
  const [selectedView, setSelectedView] = useState("overview");

  // Used to calculate the available space for the tbody scrollable table
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState("auto");

  // Used to calculate available space for the full squad table
  useEffect(() => {
    // TODO: Fix up the height of the table so it's extends to the correct size on both small and larger screens.
    const updateContainerHeight = () => {
      if (containerRef.current && containerRef.current.parentElement) {
        const parentHeight = containerRef.current.parentElement.clientHeight;
        const topOffset = containerRef.current.getBoundingClientRect().top;
        const bottomPadding = 10; // Add a bottom padding to ensure it doesn't get cut off, adjust as needed

        // Calculate the actual usable height by subtracting the top offset and adding any necessary padding
        const calculatedHeight = parentHeight;

        // Set the height to the calculated value or a minimum height, whichever is greater
        const minHeight = 550; // Define a minimum height to avoid too small containers
        const finalHeight = Math.max(calculatedHeight, minHeight);

        setContainerHeight(`${calculatedHeight}px`);
      }
    };

    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);

  useEffect(() => {
    const updateScrollbarWidth = () => {
      if (containerRef.current) {
        const width =
          containerRef.current.offsetWidth - containerRef.current.clientWidth;
        //setScrollbarWidth(17);
        // setScrollbarWidth(width);
      }
    };

    updateScrollbarWidth();
    window.addEventListener("resize", updateScrollbarWidth);

    return () => {
      window.removeEventListener("resize", updateScrollbarWidth);
    };
  }, []);

  const getTidyRoleDescription = (role) => {
    switch (role) {
      case "batsman":
        return "batsmanImage";
      case "bowler":
        return "bowlerImage";
      case "allRounder":
        return "allRounderImage";
      case "wicketKeeper":
        return "wicketKeepingGloves";
      default:
        break;
    }
  };

  useEffect(() => {}, [selectedPlayer]);

  return (
    <Card className="h-100 bg-dark w-100">
      <Card.Body className="h-100 p-0 overflow-auto">
        <Row className="d-flex flex-column h-100 m-0">
          <Col className="d-flex justify-content-between flex-grow-0 my-3">
            <div className="fw-bold text-light d-flex align-items-center">
              Squad
            </div>
            <ButtonGroup>
              <Button
                onClick={() => setSelectedView("overview")}
                active={selectedView === "overview"}
                variant="primary"
                size="sm"
              >
                Overview
              </Button>
              <Button
                onClick={() => setSelectedView("batting")}
                active={selectedView === "batting"}
                variant="primary"
                size="sm"
              >
                Batting
              </Button>
              <Button
                onClick={() => setSelectedView("bowling")}
                active={selectedView === "bowling"}
                variant="primary"
                size="sm"
              >
                Bowling
              </Button>
            </ButtonGroup>
          </Col>

          <Col
            ref={containerRef}
            style={{ maxHeight: containerHeight, overflowY: "auto" }}
            className="flex-grow-1 m-0 p-0 h-100"
          >
            <Table
              hover
              responsive
              variant="dark"
              className="squad-table h-100"
            >
              <thead>
                <tr>
                  <th className="col-1  text-center">#</th>
                  <th className="col-2 ">Name</th>
                  {selectedView === "overview" ? (
                    <>
                      <th className="col-1 text-center">Role</th>
                      <th className="col-1 text-center ">Age</th>
                      <th className="col-1 text-center ">Bat</th>
                      <th className="col-1 text-center ">Skill</th>
                      <th className="col-1 text-center ">Avg.</th>
                      <th className="col-1 text-center ">Bowl</th>
                      <th className="col-1 text-center ">Skill</th>
                      <th className="col-1 text-center ">Avg.</th>
                    </>
                  ) : selectedView === "batting" ? (
                    <>
                      <th className="col-1 text-center ">M</th>
                      <th className="col-1 text-center ">I</th>
                      <th className="col-1 text-center ">NO</th>
                      <th className="col-1 text-center ">R</th>
                      <th className="col-1 text-center ">B</th>
                      <th className="col-1 text-center ">HS</th>
                      <th className="col-1 text-center ">Avg.</th>
                      <th className="col-1 text-center ">50s/100s</th>
                      <th className="col-1 text-center ">S/R</th>
                    </>
                  ) : (
                    <>
                      <th className="text-center col-1">M</th>
                      <th className="text-center col-1">I</th>
                      <th className="text-center col-1">O</th>
                      <th className="text-center col-1">M</th>
                      <th className="text-center col-1">R</th>
                      <th className="text-center col-1">W</th>
                      <th className="text-center col-1">Avg.</th>
                      <th className="text-center col-1">5w/i</th>
                      <th className="text-center col-1">E/R</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {gameState.squad &&
                  gameState.squad
                    .filter((player) => player.enabled)
                    .map((player) => (
                      <tr
                        key={player.id}
                        className={`${
                          selectedPlayer && selectedPlayer.id === player.id
                            ? "fw-bold bg-success"
                            : ""
                        }`}
                        onClick={() => setSelectedPlayer(player)}
                      >
                        <td className="col-1 text-center">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            className=""
                            checked={player.selected}
                            onChange={(event) =>
                              togglePlayerSelection(player, event)
                            }
                          />
                        </td>
                        <td className="col-2">{`${player.playerInitial}. ${player.lastName}`}</td>
                        {selectedView === "overview" ? (
                          <>
                            <td className="col-1">
                              <div className="player-role-icon text-center">
                                <img
                                  src={
                                    image[getTidyRoleDescription(player.role)]
                                  }
                                  alt="Player role"
                                />
                              </div>
                            </td>
                            <td className="text-center col-1">{player.age}</td>
                            <td className="text-center col-1">{`${player.bat.toUpperCase()}`}</td>
                            <td className="text-center col-1">{`${player.battingSkill}`}</td>
                            <td className="col-1 text-center ">{`${
                              player.career.runs === 0 ||
                              player.career.innings - player.career.notOuts ===
                                0
                                ? "-" // Default value when overs is zero or runsAgainst is zero
                                : (
                                    player.career.runs /
                                    (player.career.innings -
                                      player.career.notOuts)
                                  ).toFixed(2)
                            }`}</td>
                            <td className="text-center col-1">{`${player.bowl.toUpperCase()}`}</td>
                            <td className="text-center col-1">{`${player.bowlingSkill}`}</td>
                            <td className="text-center col-1">{`${
                              player.career.wickets === 0 ||
                              player.career.runsAgainst === 0
                                ? "-" // Default value when overs is zero or runsAgainst is zero
                                : (
                                    player.career.runsAgainst /
                                    player.career.wickets
                                  ).toFixed(2)
                            }`}</td>
                          </>
                        ) : selectedView === "batting" ? (
                          <>
                            <td className="col-1 text-center ">
                              {player.career.matches}
                            </td>
                            <td className="col-1 text-center ">{`${player.career.innings}`}</td>
                            <td className="col-1 text-center ">{`${player.career.notOuts}`}</td>
                            <td className="col-1 text-center ">{`${player.career.runs}`}</td>
                            <td className="col-1 text-center ">{`${player.career.ballsFaced}`}</td>
                            <td className="col-1 text-center ">{`${player.career.highScore}`}</td>
                            <td className="col-1 text-center ">{`${
                              player.career.runs === 0 ||
                              player.career.innings - player.career.notOuts ===
                                0
                                ? "-" // Default value when overs is zero or runsAgainst is zero
                                : (
                                    player.career.runs /
                                    (player.career.innings -
                                      player.career.notOuts)
                                  ).toFixed(2)
                            }`}</td>
                            <td className="col-1 text-center ">{`${player.career.fifties}/${player.career.hundreds}`}</td>
                            <td className="col-1 text-center ">{`${
                              player.career.runs === 0
                                ? "-" // Default value when overs is zero or runsAgainst is zero
                                : (
                                    (player.career.runs /
                                      player.career.ballsFaced) *
                                    100
                                  ).toFixed(2)
                            }`}</td>
                          </>
                        ) : (
                          <>
                            <td className="col-1 text-center">
                              {player.career.matches}
                            </td>
                            <td className="col-1 text-center">{`${player.career.innings}`}</td>
                            <td className="col-1 text-center">{`${convertDeliveriesToOversBowled(
                              player.career.overs
                            )}`}</td>
                            <td className="col-1 text-center">{`${player.career.maidens}`}</td>
                            <td className="col-1 text-center">{`${player.career.runsAgainst}`}</td>
                            <td className="col-1 text-center">{`${player.career.wickets}`}</td>
                            <td className="col-1 text-center">{`${
                              player.career.wickets === 0 ||
                              player.career.runsAgainst === 0
                                ? "-" // Default value when overs is zero or runsAgainst is zero
                                : (
                                    player.career.runsAgainst /
                                    player.career.wickets
                                  ).toFixed(2)
                            }`}</td>
                            <td className="col-1 text-center">{`${player.career.fiveWicketBags}`}</td>

                            <td className="col-1 text-center">{`${
                              player.career.runsAgainst === 0 ||
                              player.career.overs === 0
                                ? "-" // Default value when overs is zero or runsAgainst is zero
                                : (
                                    player.career.runsAgainst /
                                    (player.career.overs / 6)
                                  ).toFixed(2)
                            }`}</td>
                          </>
                        )}
                      </tr>
                    ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default FullSquadComponent;
