import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import SquadMembersComponent from "../Shared/SquadMembersComponent";
import SelectedSquadMemberComponent from "../Shared/SelectedSquadMemberComponent";
import PlayerDescription from "../Squad/PlayerDescription";
import { useGame } from "../../../Contexts/GameContext";
import {
  getBattingAccumulation,
  getBattingAggression,
  getBattingDefence,
  getBowlingAccuracy,
  getBowlingAggression,
  getBowlingDefence,
  getBowlingWicketRating,
} from "../../../Utils/ScoreUtilities";
import StackedProgressBar from "../../StackedProgressBar";

const PlayersComponent = () => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { gameState } = useGame();

  useEffect(() => {
    if (!selectedPlayer) {
      setSelectedPlayer(gameState.squad[0]);
    }
  }, [selectedPlayer]);

  return (
    <Row className="d-flex h-100 pe-3 overflow-hidden">
      <Col className="col-3 d-flex flex-grow-1 bg-dark p-3">
        <SquadMembersComponent setSelectedPlayer={setSelectedPlayer} />
      </Col>
      <Col className="col-8 d-flex p-3">
        <div className="bg-dark rounded w-100 p-3">
          {selectedPlayer && (
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="fs-3 fw-bold">
                {selectedPlayer.firstName} {selectedPlayer.lastName} -{" "}
                {selectedPlayer.id}
              </div>

              <div className="my-5 minHeight-72 flex-grow-0 fs-5 text-center">
                {selectedPlayer.description}
              </div>

              {/* Batting */}
              <div className="flex-grow-1">
                <div className="fs-4 fw-bold d-flex align-items-center">
                  <div className="me-3">Batting</div>
                  <div
                    className={`p-1 rounded fs-6 me-3 ${
                      selectedPlayer.battingSkill >= 80
                        ? "bg-success"
                        : selectedPlayer.battingSkill >= 60
                        ? "bg-warning"
                        : "bg-danger"
                    }`}
                  >
                    <div>{selectedPlayer.battingSkill}</div>
                  </div>
                </div>

                <div className="fs-7">
                  {selectedPlayer.bat.toUpperCase()} -{" "}
                  {selectedPlayer.batPosition.toUpperCase()}
                </div>

                <div className="my-3 d-flex">
                  <div className="col-4 px-3">
                    <div>Attack</div>
                    <StackedProgressBar
                      currentValue={getBattingAggression(
                        selectedPlayer.battingThresholds
                      )}
                      maxValue={0.15}
                      dangerFirst
                    />
                  </div>

                  <div className="col-4 px-3">
                    <div className="">
                      <div>Accumulation</div>
                      <StackedProgressBar
                        currentValue={getBattingAccumulation(
                          selectedPlayer.battingThresholds
                        )}
                        maxValue={0.75}
                        dangerFirst
                      />
                    </div>
                  </div>

                  <div className="col-4 px-3">
                    <div>
                      <div className="">Defence</div>
                      <StackedProgressBar
                        currentValue={getBattingDefence(
                          selectedPlayer.battingThresholds
                        )}
                        maxValue={0.1}
                        dangerFirst
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Bowling */}
              <div className="flex-grow-1">
                <div className="fs-4 fw-bold d-flex">
                  <div className="me-3">Bowling</div>
                  <div
                    className={`p-1 rounded fs-6 me-3 ${
                      selectedPlayer.bowlingSkill >= 80
                        ? "bg-success"
                        : selectedPlayer.bowlingSkill >= 60
                        ? "bg-warning"
                        : "bg-danger"
                    }`}
                  >
                    <div>{selectedPlayer.bowlingSkill}</div>
                  </div>
                </div>

                <div className="fs-7">
                  {selectedPlayer.bowlPosition !== "DNB" &&
                    `${selectedPlayer.bowl.toUpperCase()} - `}
                  {selectedPlayer.bowlPosition.toUpperCase()}
                </div>

                <div className="my-3 d-flex">
                  <div className="d-flex flex-column col-4 px-3">
                    <div>
                      <div className="mb-1">Attack</div>
                      <StackedProgressBar
                        currentValue={getBowlingWicketRating(
                          selectedPlayer.bowlingThresholds
                        )}
                        maxValue={0.1}
                        dangerFirst
                      />
                    </div>
                  </div>

                  <div className="col-4 px-3">
                    <div className="">
                      <div>Accuracy</div>
                      <StackedProgressBar
                        currentValue={getBowlingAccuracy(
                          selectedPlayer.bowlingThresholds
                        )}
                        maxValue={0.5}
                        dangerFirst
                      />
                    </div>
                  </div>
                  <div className="col-4 px-3">
                    <div>
                      <div>Defence</div>
                      <StackedProgressBar
                        currentValue={getBowlingDefence(
                          selectedPlayer.bowlingThresholds
                        )}
                        maxValue={1}
                        dangerFirst
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Fielding */}
              <div className="flex-grow-1">
                <div className="fs-4 fw-bold d-flex align-items-center">
                  <div className="me-3">Fielding</div>
                  <div
                    className={`p-1 rounded fs-6 me-3  ${
                      selectedPlayer.fieldingSkill >= 80
                        ? "bg-success"
                        : selectedPlayer.fieldingSkill >= 60
                        ? "bg-warning"
                        : "bg-danger"
                    }`}
                  >
                    <div>{selectedPlayer.fieldingSkill}</div>
                  </div>
                </div>

                <div className="fs-7">
                  {selectedPlayer.wicketKeeper ? "WICKET KEEPER" : "FIELDER"}
                </div>

                <div className="my-3 d-flex">
                  <div className="d-flex flex-column col-4 px-3">
                    <div>
                      <div>Catching</div>
                      <StackedProgressBar
                        currentValue={selectedPlayer.fieldingSkill}
                        maxValue={100}
                        dangerFirst
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Player options */}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default PlayersComponent;
