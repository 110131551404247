export const GearConfig = [
  {
    id: "fatBat",
    name: "Fat Bat",
    description: "Fatter bats are more hitty",
    duration: 600,
    price: 100000,
    image: "gear_fat_bat",
    enabled: true,
    class: "legendary",
  },
  {
    id: "bigSpikes",
    name: "Big Spikes",
    description: "More stability at the crease and when bowling",
    duration: 60,
    price: 15000,
    image: "gear_big_spikes",
    enabled: true,
    class: "elite",
  },
  {
    id: "dripless",
    name: "Dripless",
    description: "Wipe that sweat away",
    duration: 60,
    price: 25000,
    image: "gear_dripless",
    enabled: true,
    class: "professional",
  },
  {
    id: "ironBlade",
    name: "Iron Blade",
    description: "Tap it over the rope",
    duration: 60,
    price: 125000,
    image: "gear_iron_blade",
    enabled: true,
    class: "professional",
  },
  {
    id: "smoothArms",
    name: "Smooth Arms",
    description: "Arm compression helps keep you fit",
    duration: 60,
    price: 17500,
    image: "gear_smooth_arms",
    enabled: true,
    class: "professional",
  },
  {
    id: "quickFeet",
    name: "Quick Feet",
    description: "Lightweight shoes for faster running between the wickets",
    duration: 60,
    price: 20000,
    image: "gear_quick_feet",
    enabled: true,
    class: "professional",
  },
  {
    id: "gorillaGrip",
    name: "Gorilla Grip",
    description: "Gloves with enhanced grip for better bat handling",
    duration: 60,
    price: 350000,
    image: "gear_gorilla_grip",
    enabled: true,
    class: "professional",
  },
  {
    id: "magicMitts",
    name: "Magic Mitts",
    description: "Improves your catching ability significantly",
    duration: 60,
    price: 195000,
    image: "gear_magic_mitts",
    enabled: true,
    class: "professional",
  },
  {
    id: "energyBand",
    name: "Energy Band",
    description: "Reduces fatigue, keeping you energized longer",
    duration: 60,
    price: 10000,
    image: "gear_energy_band",
    enabled: true,
    class: "elite",
  },
  {
    id: "visionPlus",
    name: "Vision Plus",
    description: "Enhanced sunglasses to improve visibility and focus",
    duration: 60,
    price: 175000,
    image: "gear_vision_plus",
    enabled: true,
    class: "elite",
  },
  {
    id: "thunderBelt",
    name: "Thunder Belt",
    description: "Boosts your power for hitting long sixes",
    duration: 60,
    price: 9999,
    image: "gear_thunder_belt",
    enabled: true,
    class: "legendary",
  },
  {
    id: "comfortCap",
    name: "Comfort Cap",
    description: "A cap that keeps you cool under pressure",
    duration: 60,
    price: 45000,
    image: "gear_comfort_cap",
    enabled: true,
    class: "professional",
  },
  {
    id: "nightHawk",
    name: "Night Hawk",
    description: "Special gear for better performance during night matches",
    duration: 60,
    price: 1000,
    image: "gear_big_spikes",
    enabled: false,
    class: "professional",
  },
  {
    id: "powerPads",
    name: "Power Pads",
    description: "Power pads providing additional stability",
    duration: 60,
    price: 1000,
    image: "gear_big_spikes",
    enabled: false,
    class: "professional",
  },
  {
    id: "stealthSoles",
    name: "Stealth Soles",
    description: "Shoe soles designed for silent and quick movement",
    duration: 60,
    price: 1000,
    image: "gear_big_spikes",
    enabled: false,
    class: "professional",
  },
  {
    id: "lightGuard",
    name: "Light Guard",
    description: "Lightweight body armor for protection without restriction",
    duration: 60,
    price: 1000,
    image: "gear_big_spikes",
    enabled: false,
    class: "professional",
  },
  {
    id: "wristWizard",
    name: "Wrist Wizard",
    description:
      "Wristbands that enhance your bowling and batting wrist actions",
    duration: 60,
    price: 1000,
    image: "gear_big_spikes",
    enabled: false,
    class: "professional",
  },
  {
    id: "windRunner",
    name: "Wind Runner",
    description: "Special attire that reduces wind resistance when running",
    duration: 60,
    price: 1000,
    image: "gear_big_spikes",
    enabled: false,
    class: "professional",
  },
  {
    id: "eagleEye",
    name: "Eagle Eye",
    description: "Improves your accuracy and judgement on the field",
    duration: 60,
    price: 1000,
    image: "gear_big_spikes",
    enabled: false,
    class: "professional",
  },
  {
    id: "shadowVeil",
    name: "Shadow Veil",
    description:
      "Camouflage gear that makes it harder for opponents to read your moves",
    duration: 60,
    price: 1000,
    image: "gear_big_spikes",
    enabled: false,
    class: "professional",
  },
];
