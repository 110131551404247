import React from "react";
import { useGame } from "../../../Contexts/GameContext.js";
import GameStatsHeader from "../Shared/GameStatsHeader.js";
import { Card } from "react-bootstrap";

const GroundComponent = () => {
  const { gameState } = useGame();

  return (
    <div className="d-flex flex-column w-100 h-100 bg-main-image">
      <GameStatsHeader />
      <div className="flex-grow-1 d-flex justify-content-center align-items-center text-light position-relative m-3">
        <div className="light-overlay"></div>
        <Card className="w-50 h-50 bg-dark text-light mb-5 shadow">
          <Card.Header className="text-center fs-3 bg-dark text-light fw-bold">
            GROUND
          </Card.Header>
          <Card.Body className="d-flex flex-column justify-content-center align-items-center">
            <div>
              Better training facilities, more seating, all round better
              cricket.
            </div>
            <div className="fs-2 fw-bold">COMING SOON</div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default GroundComponent;
