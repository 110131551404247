import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ScoreboardComponent from "../Components/ScoreboardComponent";
import { Col, Row } from "react-bootstrap";
import MatchOptionsComponent from "../Components/MatchOptionsComponent";

const MatchPage = () => {
 
  return (
    <DndProvider backend={HTML5Backend}>
      <Row className="m-0 p-0 bg-dark h-100 pt-3">
        <Col className="col-md-7 d-none d-lg-block">
          <ScoreboardComponent />
        </Col>

        <Col className="col-md-5 col-12 flex-grow-1">
          <MatchOptionsComponent />
        </Col>
      </Row>
    </DndProvider>
  );
};

export default MatchPage;
