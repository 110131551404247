import React from "react";
import { Card } from "react-bootstrap";
import "./Styles/App.scss";
import { MatchProvider } from "./Contexts/MatchContext";
import MatchPage from "./Pages/MatchPage";
import GamePage from "./Pages/GamePage";
import { useGame } from "./Contexts/GameContext";
import { GameStates } from "./Constants/Constants";
import { useAuth } from "./Contexts/AuthContext";
import LoginPage from "./Pages/LoginPage";

const App = () => {
  const { currentUser } = useAuth();
  const { gameState, gameDispatch } = useGame();

  return (
    <>
      <div className="app-content h-100">
        {!currentUser ? (
          <LoginPage />
        ) : (
          <>
            <MatchProvider>
              {gameState.gameState === GameStates.IN_MATCH && <MatchPage />}

              {gameState.gameState === GameStates.IN_MENU && <GamePage />}
            </MatchProvider>
          </>
        )}
      </div>
      <div className="development-message text-light bg-main-image">
        <Card className="h-50 d-flex flex-column justify-content-center align-items-center p-3 bg-dark text-light">
          <div className="fs-4 py-3">
            Mobile and tablet version is in development.
          </div>
          <div className="fs-6">
            This application is setup to work on screens larger than 1024 x 768.
          </div>
        </Card>
      </div>
    </>
  );
};

export default App;
