import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useMatch } from "../Contexts/MatchContext";
import {
  convertDeliveriesToOversBowled,
  getBattingTeamRunRate,
  getRecentDeliveryStyling,
} from "../Utils/ScoreUtilities";

const InningsComponent = ({
  inningsNumber,
  battingTeam,
  bowlingTeam,
  inningsData,
}) => {
  const { state } = useMatch();

  const onStrikeStyle = { backgroundColor: "#004225" };

  const BatsmanRow = ({ player, howOut, runs, ballsFaced, fours, sixes }) => {
    return (
      <tr
        key={player.id}
        className="innings-component-scorecard"
        style={
          inningsNumber === state.matchInfo.currentInnings &&
          battingTeam[state.matchInfo.onStrikeBatsmanIndex].id === player.id
            ? onStrikeStyle
            : null
        }
      >
        <td>
          {player.playerInitial}. {player.lastName}{" "}
          {player.captain && <sup className="fs-7">(C)</sup>}{" "}
          {player.wicketKeeper && <sup className="fs-7">(W)</sup>}
        </td>
        <td>{howOut}</td>
        <td className="text-center">{runs}</td>
        <td className="text-center">{ballsFaced}</td>
        <td className="text-center">{fours}</td>
        <td className="text-center">{sixes}</td>
      </tr>
    );
  };

  const BowlerRow = ({ player, overs, maidens, runsAgainst, wickets }) => {
    return (
      <tr
        key={player.id}
        className="innings-component-scorecard"
        style={
          inningsNumber === state.matchInfo.currentInnings &&
          bowlingTeam[state.matchInfo.currentBowlerIndex].id === player.id
            ? onStrikeStyle
            : null
        }
      >
        <td>
          {player.playerInitial}. {player.lastName}
        </td>
        <td className="text-center">{convertDeliveriesToOversBowled(overs)}</td>
        <td className="text-center">{maidens}</td>
        <td className="text-center">{runsAgainst}</td>
        <td className="text-center">{wickets}</td>
      </tr>
    );
  };

  useEffect(() => {}, [
    state,
    battingTeam,
    bowlingTeam,
    inningsData,
    state.matchInfo.battingTeam,
    state.matchInfo.bowlingTeam,
  ]);

  return (
    <div>
      <div className="h-75 user-select-none ">
        <Table hover borderless size="sm">
          <thead>
            <tr className="innings-component-scorecard">
              <th className="col-3">Player</th>
              <th>How Out</th>
              <th className="col-1 text-center">Runs</th>
              <th className="col-1 text-center">Balls</th>
              <th className="col-1 text-center">4s</th>
              <th className="col-1 text-center">6s</th>
            </tr>
          </thead>
          <tbody>
            {battingTeam.map((player, index) => {
              return (
                <BatsmanRow
                  key={`${player.id}-${index}`}
                  player={player}
                  howOut={
                    player.currentMatch[state.matchInfo.teamInningsIndex].howOut
                  }
                  runs={
                    player.currentMatch[state.matchInfo.teamInningsIndex].runs
                  }
                  ballsFaced={
                    player.currentMatch[state.matchInfo.teamInningsIndex]
                      .ballsFaced
                  }
                  fours={
                    player.currentMatch[state.matchInfo.teamInningsIndex].fours
                  }
                  sixes={
                    player.currentMatch[state.matchInfo.teamInningsIndex].sixes
                  }
                />
              );
            })}

            {/* Add team score row */}
            <tr>
              <td
                colSpan="2"
                className="text-end align-middle bg-transparent text-light fs-7 fw-bold"
              >
                Total
              </td>
              <td className="text-center bg-transparent text-light align-middle">
                {inningsData.score}/{inningsData.wickets}
              </td>
              <td className="text-center bg-transparent text-light align-middle fs-7 fw-bold">
                R/R
              </td>
              <td className="text-center bg-transparent text-light">
                {" "}
                {getBattingTeamRunRate(
                  inningsData.score,
                  inningsData.legalDeliveries
                )}
              </td>
            </tr>

            <tr>
              <td
                colSpan="2"
                className="text-end bg-transparent text-light fs-7 align-middle fw-bold"
              >
                Overs{" "}
              </td>
              <td className="text-center bg-transparent text-light">
                {convertDeliveriesToOversBowled(inningsData.legalDeliveries)}
              </td>
              <td className="bg-transparent"></td>
            </tr>
          </tbody>
        </Table>

        {/* recent deliveries */}
        <div className="last-30-deliveries">
          {inningsData.recentDeliveries?.map((delivery, index) => {
            return (
              <div
                key={`${delivery}-${index}`}
                className="delivery"
                style={getRecentDeliveryStyling(delivery)}
              >
                {delivery}
              </div>
            );
          })}
        </div>
      </div>

      <div className="h-25 user-select-none ">
        {/* Bowling card remains unchanged */}
        <Table hover borderless size="sm">
          <thead>
            <tr className="innings-component-scorecard">
              <th>Player</th>
              <th className="col-1 text-center">Overs</th>
              <th className="col-1 text-center">Maidens</th>
              <th className="col-1 text-center">Runs</th>
              <th className="col-1 text-center">Wickets</th>
            </tr>
          </thead>
          <tbody>
            {bowlingTeam
              .filter((player, index) => {
                return (
                  player.bowlPosition === "opener" ||
                  player.bowlPosition === "change" ||
                  player.currentMatch[state.matchInfo.teamInningsIndex].overs >
                    0
                );
              })
              .sort((a, b) => {
                const order = ["opener", "change", "emergency"];
                const positionComparison = order.indexOf(a.bowlPosition) - order.indexOf(b.bowlPosition);
            
                if (positionComparison !== 0) {
                  return positionComparison;
                }
            
                // If bowlPosition is the same, sort by bowlingSkill in descending order
                return b.bowlingSkill - a.bowlingSkill;
              })
              .map((player, index) => {
                return (
                  <BowlerRow
                    key={`${player.id}-${index}`}
                    player={player}
                    overs={
                      player.currentMatch[state.matchInfo.teamInningsIndex]
                        .overs
                    }
                    maidens={
                      player.currentMatch[state.matchInfo.teamInningsIndex]
                        .maidens
                    }
                    runsAgainst={
                      player.currentMatch[state.matchInfo.teamInningsIndex]
                        .runsAgainst
                    }
                    wickets={
                      player.currentMatch[state.matchInfo.teamInningsIndex]
                        .wickets
                    }
                  />
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default InningsComponent;
