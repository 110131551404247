import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useMatch } from "../Contexts/MatchContext";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";

const ChangeBowlerModal = ({ show, handleClose }) => {
  const { state, dispatch } = useMatch();
  const [index, setIndex] = useState(0);
  const [availableBowlers, setAvailableBowlers] = useState([]);
  const [userIsBowling, setUserIsBowling] = useState(false);

  const handleNext = () => {
    setIndex((prev) => (prev + 1) % availableBowlers.length);
  };

  const handlePrev = () => {
    setIndex(
      (prev) => (prev - 1 + availableBowlers.length) % availableBowlers.length
    );
  };

  const resetModal = () => {
    dispatch({
      type: "SET_NEXT_BOWLER",
      newBowlerId: availableBowlers[index].id,
    });

    setIndex(0);
  };

  const getAvailableBowlers = () => {
    const bowlingTeamPlayers =
      state.matchInfo.teams[state.matchInfo.bowlingTeam].players;
    const { currentBowlerIndex, otherBowlerIndex, maximumLegalDeliveries } =
      state.matchInfo;

    const availablePlayers = bowlingTeamPlayers.filter((player, index) => {
      const overs =
        bowlingTeamPlayers[index].currentMatch[state.matchInfo.teamInningsIndex]
          .overs;
      return (
        bowlingTeamPlayers[currentBowlerIndex].id !== player.id &&
        overs < maximumLegalDeliveries / 5 && bowlingTeamPlayers[currentBowlerIndex].wicketKeeper !== true
      );
    });

    setAvailableBowlers(availablePlayers.reverse());

    availablePlayers.forEach((player, index) => {
      if (
        player.id ===
        state.matchInfo.teams[state.matchInfo.bowlingTeam].players[
          otherBowlerIndex
        ].id
      ) {
        setIndex(index);
      }
    });

    setUserIsBowling(state.matchInfo.bowlingTeam === "teamA");
  };

  useEffect(() => {}, [index]);

  return (
    <Modal
      show={show}
      onShow={getAvailableBowlers}
      onHide={handleClose}
      onExited={resetModal}
      centered
      backdrop="static"
      keyboard={false}
      className="user-select-none"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark text-light"
      >
        <Modal.Title>Change next bowler</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-light">
        {/**
         *
         * The FoW modal shows the batsman, how out, and a selector to choose the next batsman if you are batting.
         *
         */}

        <div className="mb-3 fw-bold">Next Bowler</div>
        <InputGroup className="d-flex my-5">
          <Button className="bg-primary" onClick={handlePrev}>
            <GrPrevious />
          </Button>
          <div className="batsman-container flex-grow-1">
            <span
              className={`batsman-display form-control bg-black border-black text-light`}
            >
              {availableBowlers &&
                index >= 0 &&
                availableBowlers.length > 0 &&
                `${availableBowlers[index].firstName} ${availableBowlers[index].lastName}`}
            </span>
          </div>
          <Button className="bg-primary" onClick={handleNext}>
            <GrNext />
          </Button>
        </InputGroup>
        <Row className="mt-3">
          <Col className="col-3 text-center fw-bold">Overs</Col>
          <Col className="col-3 text-center fw-bold">Maidens</Col>
          <Col className="col-3 text-center fw-bold">Runs</Col>
          <Col className="col-3 text-center fw-bold">Wickets</Col>
        </Row>
        <Row>
          <Col className="col-3 text-center">
            {availableBowlers &&
              index >= 0 &&
              availableBowlers.length > 0 &&
              availableBowlers[index].currentMatch[
                state.matchInfo.teamInningsIndex
              ].overs / 6}
          </Col>
          <Col className="col-3 text-center">
            {availableBowlers &&
              index >= 0 &&
              availableBowlers.length > 0 &&
              availableBowlers[index].currentMatch[
                state.matchInfo.teamInningsIndex
              ].maidens}
          </Col>
          <Col className="col-3 text-center">
            {availableBowlers &&
              index >= 0 &&
              availableBowlers.length > 0 &&
              availableBowlers[index].currentMatch[
                state.matchInfo.teamInningsIndex
              ].runsAgainst}
          </Col>
          <Col className="col-3 text-center">
            {availableBowlers &&
              index >= 0 &&
              availableBowlers.length > 0 &&
              availableBowlers[index].currentMatch[
                state.matchInfo.teamInningsIndex
              ].wickets}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="outline-light" onClick={handleClose}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeBowlerModal;
