import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

// Utility function to normalize values based on a given max value
const normalizeValue = (value, maxValue) => {
  return (value / maxValue) * 100; // Normalize to a percentage
};

function StackedProgressBar({
  currentValue,
  dangerFirst = false,
  maxValue = 100,
}) {
  const [dangerValue, setDangerValue] = useState(0);
  const [warningValue, setWarningValue] = useState(0);
  const [successValue, setSuccessValue] = useState(0);

  useEffect(() => {
    // Normalize currentValue based on maxValue provided
    const normalizedCurrentValue = normalizeValue(currentValue, maxValue);

    // Debug logs
    console.log("Normalized Current Value:", normalizedCurrentValue);
    console.log("Max Value:", maxValue);

    // Define dynamic setters based on the dangerFirst flag
    const setPrimaryValue = dangerFirst ? setDangerValue : setSuccessValue;
    const setSecondaryValue = dangerFirst ? setSuccessValue : setDangerValue;

    const firstThreshold = 25;
    const secondThreshold = 60;

    console.log("First Threshold:", firstThreshold);
    console.log("Second Threshold:", secondThreshold);

    if (normalizedCurrentValue <= firstThreshold) {
      setPrimaryValue(normalizedCurrentValue);
      setWarningValue(0);
      setSecondaryValue(0);
      return;
    }

    if (normalizedCurrentValue <= secondThreshold) {
      setPrimaryValue(firstThreshold);
      setWarningValue(normalizedCurrentValue - firstThreshold);
      setSecondaryValue(0);
      return;
    }

    if (normalizedCurrentValue > secondThreshold) {
      setPrimaryValue(firstThreshold);
      setWarningValue(secondThreshold - firstThreshold);
      setSecondaryValue(normalizedCurrentValue - secondThreshold);
      return;
    }
  }, [currentValue, dangerFirst, maxValue]);

  return (
    <>
      {dangerFirst ? (
        <ProgressBar>
          <ProgressBar variant="danger" now={dangerValue} key={1} />
          <ProgressBar variant="warning" now={warningValue} key={2} />
          <ProgressBar variant="success" now={successValue} key={3} />
        </ProgressBar>
      ) : (
        <ProgressBar>
          <ProgressBar variant="success" now={successValue} key={1} />
          <ProgressBar variant="warning" now={warningValue} key={2} />
          <ProgressBar variant="danger" now={dangerValue} key={3} />
        </ProgressBar>
      )}
    </>
  );
}

export default StackedProgressBar;
