import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useMatch } from "../Contexts/MatchContext";
import { MatchStates } from "../Constants/Constants";
import { getCurrentMatchState, getMatchOutcome } from "../Utils/ScoreUtilities";
import { useGame } from "../Contexts/GameContext";
import { useAuth } from "../Contexts/AuthContext";
import { updateGameResults } from "../Api/Firebase/firebaseAPI";

const EndOfMatchModal = ({ show, handleClose, finalMatchStats }) => {
  const { state, dispatch } = useMatch();
  const { currentUser } = useAuth();
  const {
    gameState,
    gameDispatch,
    saveEndGameData,
    saveNewMoneyTotal,
    saveGameResults,
  } = useGame();
  const players = state.matchInfo.teams.teamA.players;
  const matchInfo = state.matchInfo;
  const oppositionPlayers = state.matchInfo.teams.teamB.players;
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [playerOfTheMatch, setPlayerOfTheMatch] = useState("");

  const handleMatchCompleted = () => {
    // Pass gameState, matchInfo, and currentUser to update the results
    const matchOutcome = getMatchOutcome(gameState, matchInfo, currentUser);

    // Updates the wins, losses, draws, and ties
    saveGameResults(matchOutcome); // Saves to context
    updateGameResults(currentUser.uid, matchOutcome); // updates firebase

    dispatch({
      type: "UPDATE_MATCH_STATE",
      newMatchState: MatchStates.MATCH_COMPLETED,
      canProcessDelivery: false,
    });

    //  TODO: Handle updating game stats e.g. cash
    gameDispatch({
      type: "INCREASE_USER_MONEY",
      money: totalEarnings,
      finalMatchStats: finalMatchStats,
    });

    const newTotalMoney = gameState.money + totalEarnings;

    saveEndGameData(finalMatchStats);
    saveNewMoneyTotal(newTotalMoney);

    handleClose();
  };

  useEffect(() => {
    const getPlayerOfTheMatch = () => {
      let allPlayers = [];

      oppositionPlayers.forEach((player) => {
        let playerEarnings = player.currentMatch[0].runs * 10;
        playerEarnings += player.currentMatch[0].wickets * 270;
        allPlayers.push({
          id: player.id,
          name: `${player.firstName} ${player.lastName}`,
          earnings: playerEarnings,
        });
      });

      players.forEach((player) => {
        let playerEarnings = player.currentMatch[0].runs * 15;
        playerEarnings += player.currentMatch[0].wickets * 50;
        allPlayers.push({
          id: player.id,
          name: `${player.firstName} ${player.lastName}`,
          earnings: playerEarnings,
        });
      });

      allPlayers.sort((a, b) => b.earnings - a.earnings);
      return allPlayers[0];
    };

    const calculateMatchResults = () => {
      let potm = getPlayerOfTheMatch();
      setPlayerOfTheMatch(potm);

      players.forEach((player) => {
        let playerEarnings = player.currentMatch[0].runs * 15;
        playerEarnings += player.currentMatch[0].wickets * 50;
        if (potm.id === player.id) {
          playerEarnings += 1500;
        }

        setTotalEarnings((prevTotal) => prevTotal + playerEarnings);
      });
    };

    if (finalMatchStats) {
      calculateMatchResults();
    }
  }, [finalMatchStats, oppositionPlayers, players]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="user-select-none "
      size="md"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark text-light"
      >
        <Modal.Title>
          {getCurrentMatchState(currentUser, state.matchInfo).description}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-light">
        {/**
         * Result
         * Player of the match
         *
         * Money earned
         *
         * Players + money earned per player
         */}
        <Row className="mb-3">
          <Col>
            Match earnings:
            <div className="fw-bold fs-5 my-3">${totalEarnings.toLocaleString()}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            Player of the match:{" "}
            <div className="fw-bold my-3 fs-5">{playerOfTheMatch.name}</div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="outline-light" onClick={handleMatchCompleted}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EndOfMatchModal;
