import React from "react";

const MenuItem = ({ title, menuItemSelected, setMenuItemSelected }) => {
  return (
    <div
      className={`menu-item fs-1 ${menuItemSelected === title && "active"}`}
      onClick={() => setMenuItemSelected(title)}
    >
      {title}
    </div>
  );
};

export default MenuItem;
