import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import MenuItem from "./MenuItem";
import "../../Styles/GameMenu.scss";
import { useGame } from "../../Contexts/GameContext";
import TossModal from "../../Modals/TossModal";

const Menu = ({ menuItemSelected, setMenuItemSelected }) => {
  const { gameState } = useGame();
  const menuItems = ["Home", "Squad", "Players", "Shop", "Ground", "Training"];
  const [showTossModal, setShowTossModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const isButtonDisabled =
    gameState.squad.filter((player) => player.selected === true).length !== 11;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {`You have ${
        gameState.squad.filter((player) => player.selected === true).length
      } players in your squad.`}
    </Tooltip>
  );

  const initiateTheToss = () => {
    setShowTossModal(true);
  };

  useEffect(() => {
    setShowTooltip(isButtonDisabled);
  }, [isButtonDisabled]);

  return (
    <>
      <Row className="d-flex flex-column vh-100 justify-content-between user-select-none game-menu bg-dark">
        <Col className="mt-3">
          {menuItems &&
            menuItems.map((item) => {
              return (
                <Col key={item}>
                  <MenuItem
                    title={item}
                    menuItemSelected={menuItemSelected}
                    setMenuItemSelected={setMenuItemSelected}
                  />
                </Col>
              );
            })}
        </Col>
        <Col className="d-flex align-items-end">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
            show={showTooltip}
          >
            <Button
              className="play-match-button border-0 w-100 mb-3"
              onClick={initiateTheToss}
              variant="primary"
              disabled={isButtonDisabled}
            >
              Play Match
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>

      <TossModal
        show={showTossModal}
        handleClose={() => setShowTossModal(false)}
        key={"tossModal"}
      />
    </>
  );
};

export default Menu;
